import { createSlice } from "@reduxjs/toolkit";
import { nbbApi } from "./axios";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
import Swal from "sweetalert2";
//show the loader
var userDetails = localStorage.getItem("userDetails");
var data = JSON.parse(userDetails);
var user_id = data?.ID;
const intialHomeState = {
  BannerPages: [],
  Bannrpath: [],
  TopServicesCategory: [],
  TopservicesBannrpath: [],
  ServiceCategory: [],
  serviceCategoryBannrpath: [],
  fetchServiceCategory: [],
  FeatureCategory: [],
  FeatureCategorybanner: [],
  About: [],
  Header: [],
  Privacy: [],
  Terms: [],
  contact_submit: [],
  Contact: [],
  loaderStatus: false,
  StateList: [],
  District: [],
  homeServiceCategory: [],
  VgalleryStatus: [],
  findServiceByDistance: [],
  vendorProfileData: [],
  vendorService: [],
  vendorProfilefail: [],
  vendordata: [],
  VenProfileEditResponse: [],
  VenProfileUpdateFailStatus: [],
  fetchServiceCategoryList: [],
  districtdata: [],
  VendorRegister: [],
  VendorRegisterFail: [],
  userRegistrationData: [],
  userprofiledetails: [],
  footer: [],
  vendRegDetail: [], VendorGallery:[], VendorGalleryResult:[],
   VenGalleryEdit :[], VenGalleryPathEdit:[], GalleryDataa:[], VeGalleryPath:[],
   VenGalleryAdd:[], VenGalleryDelete:[], VendorProfileUpdate:[],
   userRegistrationData: [],
  userRegistrationDataMsg: [], statusmsg: null,
  paymentData: [],
  vendorServicePayment: [],
  service_price:[], VenBanner:[], VenBannerPath:[],ratingmsg: "", fetchrating:[], ProfileImage:[], ProfileImagePath:[],
  //  VenGalEdit:[], 
  ProfileImageInsert:[], ProfileImageDelete:[], 
};

const homeSlice = createSlice({
  name: "home",
  initialState: intialHomeState,
  reducers: {
    getHomeData(state, action) {
      state.data = action.payload;
      state.loaderStatus = action.payload.loaderStatus;
      state.loaderStatus = false;
    },
    BannerPagesSuccess(state, action) {
      state.BannerPages = action.payload;
    },

    bannerpathSuccess(state, action) {
      state.Bannrpath = action.payload;
    },

    TopServicesCategorySuccess(state, action) {
      state.TopServicesCategory = action.payload;
    },

    TopservicesbannerpathSuccess(state, action) {
      state.TopservicesBannrpath = action.payload;
    },
    ServiceCategorySuccess(state, action) {
      state.ServiceCategory = action.payload;
    },

    serviceCategorybannerpathSuccess(state, action) {
      state.serviceCategoryBannrpath = action.payload;
    },
    // fetchServiceCategorySuccess(state, action) {
    // const catId = 1;
    // state.fetchServiceCategory = action.payload.filter(service => service.cat_id === catId);
    // },
    fetchServiceCategorySuccess(state, action) {
      state.fetchServiceCategoryList = action.payload;
      console.log(state.fetchServiceCategoryList);
    },
    fetchServiceCategoryFailure(state) {
      state.fetchServiceCategoryList = [];
    },
    FeatureCategorySuccess(state, action) {
      state.FeatureCategory = action.payload;
    },
    FeatureCategoryImgSuccess(state, action) {
      state.FeatureCategorybanner = action.payload;
    },
    AboutSuccess(state, action) {
      state.About = action.payload;
    },
    PrivacySuccess(state, action) {
      state.Privacy = action.payload;
    },
    TermsSuccess(state, action) {
      state.Terms = action.payload;
    },
    HeaderSuccess(state, action) {
      state.Header = action.payload;
    },

    ContactSuccessSubmit(state, action) {
      state.contact_submit = action.payload;
      // state.loaderStatus = false;
    },
    // ContactSuccess(state, action) {
    // state.Contact = action.payload
    // state.loaderStatus = false;
    // },
    updateLoaderStatus(state, action) {
      state.loaderStatus = action.payload;
    },
    StateSuccess(state, action) {
      state.StateList = action.payload;
      console.log("ghjkl", state.StateList);
    },
    StateFailure(state) {
      state.StateList = [];
    },
    DistrictSuccess(state, action) {
      state.districtdata = action.payload;
      console.log("abcdef", state.districtdata);
    },
    DistrictFailure(state) {
      state.districtdata = [];
    },

    // LocationSuccess(state, action) {
    // state.Location = action.payload
    // },
    homeServiceCategorySuccess(state, action) {
      state.homeServiceCategory = action.payload;
    },
    //vendor_profile_api
    VendordataSuccess(state, action) {
      state.vendordata = action.payload;
      // console.log("vendorD",state.vendordata);
    },
    VendorServiceSuccess(state, action) {
      state.vendorService = action.payload;
      // console.log("vendorS",state.vendorService);
    },
    VendorServicePaymentStatus(state, action) {
      state.vendorServicePayment = action.payload;
      // console.log("vendorS",state.vendorService);
    },
    VendorProfileSuccess(state, action) {
      state.vendorProfileData = action.payload;
      // console.log("vendorP",state.vendorProfile);
    },
    VendorProfileFailure(state, action) {
      state.vendorProfilefail = action.payload;
    },
    updateServiceStatus: (state, action) => {
      state.serviceStatus = action.payload;
    },
    VendorGallerySuccess: (state, action) => {
      state.VgalleryStatus = action.payload;
    },
    VendorProfileEditSuccess: (state, action) => {
      state.VenProfileEditResponse = action.payload;
    },

    VendorProfileEditFailure: (state, action) => {
      state.VenProfileUpdateFailStatus = action.payload;
    },
    VendorRegisterSuccess(state, action) {
      state.VendorRegister = action.payload;
    },
    // VendorRegisterFailure (state, action) {
    // state.VendorRegisterFail = [];
    // },
    findServicebydistanceSuccess(state, action) {
      state.findServiceByDistance = action.payload;
    },
    findServicebydistanceFailure(state, action) {
      state.findServiceByDistance = [];
    },
    userRegistrationSuccess(state, action) {
      state.userRegistrationData = action.payload;
      state.statusmsg = "success";
    },
    userRegistrationFailureMsg(state, action) {
      state.userRegistrationData = action.payload;
      state.statusmsg = "fail";
    },
    userRegistrationSuccessMsg(state, action) {
      state.userRegistrationDataMsg = action.payload;
    },
    userProfileSuccess(state, action) {
      state.userprofiledetails = action.payload;
    },
    setStatusMsg(state, action) {
      state.statusmsg = action.payload;
    },
    FooterSuccess(state, action) {
      state.footer = action.payload
  },
    registration_pay_data(state, action) {
      state.vendRegDetail = action.payload;
    },
    VendorGallerydata(state, action) {
      state.GalleryDataa = action.payload;
    },
    VendorGalleryPath(state, action) {
      state.VeGalleryPath = action.payload;
    },
    GallerySuccess(state, action) {
      state.VendorGallery = action.payload;
    },
    GalleryResultSuccess(state, action) {
      state.VendorGalleryResult = action.payload;
    },
    
    GalleryFailure(state, action) {
      state.VendorGallery = [];
    },

    VendorGalleryAddSuccess(state, action) {
      state.VenGalleryAdd = action.payload;
    },
    VendorGalleryAddFailure(state, action) {
      state.VenGalleryAdd = [];
    },
    VendorGalleryDeleteSuccess(state, action) {
      state.VenGalleryDelete = action.payload;
    },
    VendorGalleryDeleteFailure(state, action) {
      state.VenGalleryDelete = action.payload;
    },
    // VendorGalleryEditSuccess(state, action) {
    //   state.VenGalEdit = action.payload;
    // },
    // VendorGalleryEditFailure(state, action) {
    //   state.VenGalEdit = [];
    // },

    GalleryEditSuccess(state, action) {
      state.VenGalleryEdit  = action.payload.result;
    },
    GalleryPathEditSuccess(state, action) {
      state.VenGalleryPathEdit  = action.payload;
    },
    GalleryEditFailure(state, action) {
      state.VenGalleryEdit  = [];
    },
    VendorUpdateSuccess(state, action) {
      state.VendorProfileUpdate  = action.payload;
    },
    VendorUpdateFailure(state, action) {
      state.VendorProfileUpdate  = action.payload;
    },
    PAYMENT_SUCCESS(state, action) {
      state.paymentData = action.payload;
      localStorage.setItem("isVendPayment", "true");
    },
    service_price(state, action) {
      state.service_price = action.payload;
    },
    VendorBannerSuccess(state, action) {
      state.VenBanner = action.payload;
    },
    VendorBannerPathSuccess(state, action) {
      state.VenBannerPath = action.payload;
    },
    VendorBannerFailure(state, action) {
      state.VenBanner = action.payload;
    },
    setVendorPaymentStatus(state, action) {
      state.paymentData = action.payload;
    },
    giveratingsuccess(state, action) {
      state.ratingmsg = action.payload;
    },
    giveratingfailure(state, action) {
      state.ratingmsg = [];
    },
    fetchratingsuccess(state, action) {
      state.fetchrating = action.payload;
    },
    fetchratingfailure(state, action) {
      state.fetchrating = [];
    },
    VendorProfileImageSuccess(state, action) {
      state.ProfileImage = action.payload;
    },
    VendorProfileImagePathSuccess(state, action) {
      state.ProfileImagePath = action.payload;
    },
    VendorProfileImageFailure(state, action) {
      state.ProfileImage = [];
    },
    ProfileImgInsertSuccess(state, action) {
      state.ProfileImageInsert = action.payload;
    },
    ProfileImgInsertFailure(state, action) {
      state.ProfileImageInsert = [];
    },
    ProfileImgDeleteSuccess(state, action) {
      state.ProfileImageDelete = null;
    },
    ProfileImgDeleteFailure(state, action) {
      state.ProfileImageDelete = [];
    },
    
  },
});

export const homeActions = homeSlice.actions;

export const getBannerPages = () => {
  return async (dispatch) => {
    try {
      await nbbApi.get("/webbanner").then((response) => {
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.BannerPagesSuccess(response.data?.Banner));
          dispatch(homeActions.bannerpathSuccess(response.data?.img_path));
          // console.log('bannerimg', response.data.Banner[0]);
          console.log("bannerimgpath", response.data.img_path);
        }
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.BannerPagesFailure(response.data.msg));
        }
      });
    } catch (e) {
      return;
    }
  };
};

export const getTopServicesCategory = () => {
  return async (dispatch) => {
    try {
      await nbbApi.get("/top_services").then((response) => {
        if (response.data.status.toString() === "1") {
          dispatch(
            homeActions.TopServicesCategorySuccess(response.data?.service)
          );
          dispatch(
            homeActions.TopservicesbannerpathSuccess(response.data?.img_path)
          );
          // console.log('topservicebannerimgpath', response.data.img_path);
          // console.log('Topservicesss', response.data.service[0]);
        }
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.TopServicesCategoryFailure(response.data.msg));
        }
      });
    } catch (e) {
      return;
    }
  };
};

export const getServiceCategory = () => {
  return async (dispatch) => {
    try {
      await nbbApi.post("/servicecategory").then((response) => {
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.ServiceCategorySuccess(response.data?.service));
          dispatch(
            homeActions.serviceCategorybannerpathSuccess(
              response.data?.img_path
            )
          );
          console.log("kalyaniiiii", response.data.img_path);
          // console.log('servicecategoryzz', response.data.service);
        }
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.ServiceCategoryFailure(response.data.msg));
        }
      });
    } catch (e) {
      return;
    }
  };
};

export const getfetchServiceCategory = (payload) => {
  return async (dispatch) => {
    const productData = new FormData();
    productData.append("cat_id", payload);
    try {
      await nbbApi.post("/fetch-srvc-by-cat", productData).then((response) => {
        if (response.data.status.toString() === "1") {
          //console.log("asdfvv",response.data.service);
          dispatch(
            homeActions.fetchServiceCategorySuccess(response.data.service)
          );
          console.log("fetchservicecategoryzz", response.data.service);
        } else if (response.data.status.toString() === "0") {
          dispatch(homeActions.fetchServiceCategoryFailure(response.data.msg));
        }
      });
    } catch (e) {
      return;
    }
  };
};

export const getFeatureCategory = () => {
  return async (dispatch) => {
    try {
      await nbbApi.get("/feature_cat").then((response) => {
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.FeatureCategorySuccess(response.data?.service));
          dispatch(
            homeActions.FeatureCategoryImgSuccess(response.data?.img_path)
          );
          // console.log('featurecategory', response.data.service);
        }
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.FeatureCategoryFailure(response.data.msg));
        }
      });
    } catch (e) {
      return;
    }
  };
};

export const getAbout = (payload) => {
  return async (dispatch) => {
    const aboutData = new FormData();
    aboutData.append("page", "about");

    try {
      await nbbApi.post("/pages", aboutData).then((response) => {
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.AboutSuccess(response.data));
          console.log("about", response.data.title);
        }
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.AboutFailure(response.data.msg));
        }
      });
    } catch (e) {
      return;
    }
  };
};
export const getPrivacy = (payload) => {
  return async (dispatch) => {
    const privacyData = new FormData();
    privacyData.append("page", "privacy");

    try {
      await nbbApi.post("/pages", privacyData).then((response) => {
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.PrivacySuccess(response.data));
          console.log("privacy", response.data.title);
        }
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.PrivacyFailure(response.data.msg));
        }
      });
    } catch (e) {
      return;
    }
  };
};
export const getTerms = (payload) => {
  return async (dispatch) => {
    const termsData = new FormData();
    termsData.append("page", "terms");

    try {
      await nbbApi.post("/pages", termsData).then((response) => {
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.TermsSuccess(response.data));
          console.log("Terms", response.data.title);
        }
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.TermsFailure(response.data.msg));
        }
      });
    } catch (e) {
      return;
    }
  };
};

export const getHeader = () => {
  return async (dispatch) => {
    try {
      await nbbApi.get("/navigation").then((response) => {
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.HeaderSuccess(response.data?.menuList));
          // console.log('Header', response.data.menuList);
        }
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.HeaderFailure(response.data.msg));
        }
      });
    } catch (e) {
      return;
    }
  };
};

export const saveContact = (payload) => {
  return async (dispatch) => {
    // const productData = new FormData();
    // productData.append("name", payload);
    try {
      const { name, email, phone, subject, query } = payload;

      const productData = new FormData();
      productData.append("name", name);
      productData.append("email", email);
      productData.append("phone", phone);
      productData.append("subject", subject);
      productData.append("query", query);

      await nbbApi.post("/save-contact", productData).then((response) => {
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.ContactSuccessSubmit(response.data.msg));
          // console.log("kalyanicontact", response.data.msg);
        }
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const postRegistration = (payload) => {
  return async (dispatch) => {
    try {
      const {
        user_name,
        business_name,
        email,
        cat_id,
        user_address,
        service_id,
        mobile_no,
        address,
        latitude,
        longitude,
        landmark,
        place_id,
        country,
        state,
        city,
        area,
        pincode,
        gstn,
        tandc,
        district,
        description,
      } = payload;
      const formData = new FormData();
      formData.append("mobile_no", mobile_no);
      formData.append(" email", email);
      formData.append("user_name", user_name);
      formData.append("business_name", business_name);
      formData.append("cat_id", cat_id);
      formData.append("service_id", service_id);
      formData.append("address", address);
      formData.append("user_address", user_address);
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);
      formData.append("landmark", landmark);
      formData.append("place_id", place_id);
      formData.append(" country", country);
      formData.append("state", state);
      formData.append("city", city);
      formData.append("area", area);
      formData.append(" pincode", pincode);
      formData.append(" gstn", gstn);
      formData.append("tandc", tandc);
      formData.append("district", district);
      formData.append("description", description);

      const response = await nbbApi.post("/vendor_register", formData);

      if (response.data.Regstn_status.toString() === "1") {
        Swal.fire({
          title: "Success",
          text: response.data?.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        dispatch(homeActions.VendorRegisterSuccess(response.data?.message));
        console.log("kalyaniRegister", response.data?.message);
      } else {
        Swal.fire({
          title: "Error",
          text: response.data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
        // Handle failure case, you might dispatch a failure action
        console.error("Vendor registration failed:", response.data?.message);
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong. Please try again later.",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
      console.error("Error in postRegistration:", error);
      // Handle any unexpected errors, you might dispatch a failure action
    }
  };
};


export const getState = () => {
  return async (dispatch) => {
    try {
      await nbbApi.get("/state").then((response) => {
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.StateSuccess(response.data?.states));
          console.log("kalyaniState", response.data.states);
        }
        if (response.data.status.toString() === "0") {
          dispatch(homeActions.StateFailure(response.data.msg));
        }
      });
    } catch (e) {
      return;
    }
  };
};
export const getDistrict = (payload) => {
  return async (dispatch) => {
    const stateDatas = new FormData();
    stateDatas.append("state", payload);

    try {
      await nbbApi.post("/district", stateDatas).then((response) => {
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.DistrictSuccess(response.data.districts));
          console.log("kalyaniDistrict", response.data.districts);
        } else if (response.data.status.toString() === "0") {
          dispatch(homeActions.DistrictFailure(response.data.msg));
        }
      });
    } catch (e) {
      return;
    }
  };
};

export const gethomeServiceCategory = () => {
  return async (dispatch) => {
    try {
      await nbbApi.get("/all_services").then((response) => {
        if (response.data.status.toString() === "1") {
          dispatch(
            homeActions.homeServiceCategorySuccess(response.data?.service)
          );
        }
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.homeServiceCategoryFailure(response.data.msg));
        }
      });
    } catch (e) {
      return;
    }
  };
};

// vendor_profile_api_call

export const getVendorProfilepage = (phone) => {
  console.log('vendorprofile', phone)
  return async (dispatch) => {
    const formData = new FormData();
    formData.append("phone", phone);
    try {
      await nbbApi.post("/vendor_profile", formData).then((response) => {
        if (response.data.data.status.toString() === "1") {
          dispatch(
            homeActions.VendorServiceSuccess(response.data.data.service[0])
          );

          localStorage.setItem("vendor_id",response.data.data.vendor_profile.id)
          
          // sessionStorage.setItem("paymentstatus", response.data.data.vendor_payment.payment_status)
          localStorage.setItem("vendor_payment_date_time", response.data.data.vendor_payment.entry_date)
          dispatch(homeActions.setVendorPaymentStatus(response.data.data.vendor_payment.payment_status));

          // dispatch(
          //   homeActions.VendorGallerySuccess(
          //     response.data.data.vendor_gallery[0]
          //   )
          // );
        } else {
          dispatch(homeActions.VendorProfileFailure(response.data.message));
        }
        dispatch(homeActions.VendordataSuccess(response.data.data));
        dispatch(
          homeActions.VendorProfileSuccess(response.data.data.vendor_profile)
        );
      });
    } catch (error) {
      dispatch(homeActions.VendorProfileFailure(error.message));
    }
  };
};

// export const VendorProfileEditSuccess = (status) => ({
//   type: "VENDOR_PROFILE_EDIT_SUCCESS",
//   payload: status,
// });
// export const getDistrict = (payload) => {
// return async (dispatch) => {
// const stateDatas = new FormData();
// stateDatas.append("state", payload);

// try {
// await nbbApi.post("/district", stateDatas).then((response) => {
// if (response.data.status.toString() === "1") {
// dispatch(
// homeActions.DistrictSuccess(response.data.districts)
// );
// console.log("kalyaniDistrict", response.data.districts);
// } else if (response.data.status.toString() === "0") {
// dispatch(homeActions.DistrictFailure(response.data.msg));
// }
// });
// } catch (e) {
// return;
// }
// };
// };
// export const getVendorProfileEdit = (payload) => {
//   console.log("kaiiiiiii", payload);

//   return async (dispatch) => {
//     try {
//     const {firstName, orgName, location, email, landmark, gstn, state, district,
//        city, image, phone, vendor_id } = payload;
//     const formEditData = new FormData();

//     formEditData.append("firstName", firstName);
//     formEditData.append("orgName", orgName);
//     formEditData.append("location", location);
//     formEditData.append("email", email);
//     formEditData.append("landmark", landmark);
//     formEditData.append("gstn", gstn);
//     formEditData.append("state", state);
//     formEditData.append("district", district);
//     formEditData.append("city", city);
//     formEditData.append("image", image);
//     formEditData.append("phone", phone);
//     formEditData.append("vendor_id", vendor_id);

//     const response = await nbbApi.post("/vendor_update", formEditData);

//     if (response.data.status.toString() === "1") {
//       dispatch(homeActions.VendorProfileEditSuccess(response.data.response));
//       console.log("VendorProfileEdit", response.data.response);
//     } else{
//       dispatch(homeActions.VendorProfileEditFailure(response.data.message));
//     }
//   } catch (error) {
//     console.error("Error in getVendorProfileEdit:", error);
//     dispatch(homeActions.VendorProfileEditFailure(error.message));
//   }
// };
// };
// export const getVendorProfileEdit = (payload) => {
// console.log('kaiiiiiii', payload);
// const { phone, name, email, businessName, landmark, state, city, area, district, gstn, profile } = payload;

// return async (dispatch) => {
// const formEditData = new FormData();

// formEditData.append("phone", phone);
// formEditData.append("name", name);
// formEditData.append("email", email);
// formEditData.append("businessName", businessName);
// formEditData.append("state", state);
// formEditData.append("landmark", landmark);
// formEditData.append("area", area);
// formEditData.append("city", city);
// formEditData.append("district", district);
// formEditData.append("gstn", gstn);
// formEditData.append("profile", profile);

// try {
// const response = await nbbApi.post('/vendor_update', formEditData);

// if (response.data.status.toString() === '1') {
// dispatch(homeActions.VendorProfileEditSuccess(response.data.response));
// console.log("VendorProfileEdit", response.data.status);
// } else if (response.data.status.toString() === '0') {
// dispatch(homeActions.VendorProfileEditFailure(response.data.message));
// }

// } catch (error) {
// dispatch(homeActions.VendorProfileEditFailure(error.message));
// }
// };
// };


// export const getVendorUpdate = (vendor_phoneUpdate) => {
//   console.log('vendorprofileUPDATE', vendor_phoneUpdate)
//   return async (dispatch) => {
//     const formData = new FormData();
//     formData.append("phone", vendor_phoneUpdate);
//     try {
//       const response = await nbbApi.post("/vendor_edit_form", formData);

//       if (response.data.status.toString() === "1") {
//         dispatch(homeActions.VendorUpdateSuccess(response.data.vendor_info));
//       } else {
//         dispatch(homeActions.VendorUpdateFailure(response.data.message));
//       }
//     } catch (error) {
//       dispatch(homeActions.VendorUpdateFailure(error.message));
//     }
//   };
// };



export const findServicebydistance = (formData) => {
  return async (dispatch) => {
    try {
      await nbbApi
        .post("/find_service_by_distance", formData)
        .then((response) => {
          if (response.data.status.toString() === "1") {
            dispatch(homeActions.findServicebydistanceSuccess(response.data));
          } else {
            dispatch(
              homeActions.findServicebydistanceFailure(response.data.message)
            );
          }
        });
    } catch (error) {}
  };
};

export const userRegistration = (formDataObject,navigate) => {
  return async (dispatch) => {
    try {
      const response = await nbbApi.post("/user_register", formDataObject);
      if (response.data.status.toString() === 1) {
        dispatch(
          homeActions.userRegistrationSuccess(response.data.user_details[0])
        );
        dispatch(homeActions.userRegistrationSuccessMsg(response.data.message));
      } else {
        dispatch(homeActions.userRegistrationFailureMsg(response.data.message));
      }
      // Set the statusmsg in the state
      dispatch(
        homeActions.setStatusMsg(
          response.data.status === 1 ? "success" : "fail"
        )
      );
      // Display a success or error message using Swal with didClose callback
      Swal.fire({
        title: response.data.status === 1 ? "Success!" : "Error!",
        html:
          response.data.status === 1
            ? `
    <div style="font-size: 25px; color: #888;">${response.data.message
            } ${" "} ${response.data.user_details[0].customer_name}</div>
    <div id="timer">Redirecting to login page in <span id="countdown">5</span> seconds</div>`
            : `
      <div>${response.data.message} ${" "} ${response.data.user_details.customer_name
            }</div>
         <div id="timer">Redirecting to login page in <span id="countdown">5</span> seconds</div>`,
        icon: response.data.status === 1 ? "success" : "error",
        timer: 5000, 
        timerProgressBar: true,
        didOpen: () => {
          const countdownElement = document.getElementById("countdown");
          const timerInterval = setInterval(() => {
            const countdown = parseInt(countdownElement.textContent, 10);
            if (countdown > 1) {
              countdownElement.textContent = (countdown - 1).toString();
            } else {
              clearInterval(timerInterval);
            }
          }, 1000);
        },
        didClose: () => {
          // Redirect to the "user_login" page
          navigate("/user_login"); // You can use your routing method here
        },
      });
    } catch (error) {
      // Handle any network or other errors here
      console.error(error);
      // Set the statusmsg in the state to "fail" on error
      dispatch(homeActions.setStatusMsg("fail"));
      // Display an error message using Swal with didClose callback
      Swal.fire({
        title: "Error!",
        text: "An error occurred while registering the user.",
        icon: "error",
        didClose: () => {
          // Redirect to the "user_login" page
          navigate("/user_registration"); // You can use your routing method here
        },
      });
    }
  };
};

export const userProfile = (formData) => {
  return async (dispatch) => {
    try {
      await nbbApi.post("/user_profile", formData).then((response) => {
        if (response.data.status === 1) {
          dispatch(
            homeActions.userProfileSuccess(response.data?.user_details[0])
          );
        } else {
          dispatch(homeActions.userProfilefailure(response.data.message));
        }
      });
    } catch (error) {}
  };
};

export const getFooter = () => {
  return async (dispatch) => { 
      try{
          await nbbApi.get('/footerDetail').then(response => {
          
              if(response.data.status.toString() === "1")
              {
                  dispatch(homeActions.FooterSuccess(response.data?.record['0']))
                  // console.log('footerdata', response.data?.record);
                  
              } 
              // if(response.data.status.toString() === "0"){
              //     dispatch(homeActions.FooterFailure(response.data.msg))
              // }
          })
      }
      catch (e) {
          return 
      }
  }
}

// export const getVendorPokelist = (vendor_id) => {
//   console.log('vendorprofile', vendor_id)
//   return async (dispatch) => {
//     const formData = new FormData();
//     formData.append("vendor_id", vendor_id);
//     try {
//       await nbbApi.post("/vendor_pokeList", formData).then((response) => {
//         if (response.data.status.toString() === "1") {
//           dispatch( homeActions.VendorPokeListSuccess(response.data.result['0'])); 
//           // console.log("VENDORPOKE", response.data.result);
//         } else {
//           dispatch(homeActions.VendorPokeListFailure(response.data.message));
//         }
        
//       });
//     } catch (error) {
//       dispatch(homeActions.VendorPokeListFailure(error.message));
//     }
//   };
// };

export const getVendorGallery = (phone) => {
  return async (dispatch) => {
    const galleryData = new FormData();
    galleryData.append("phone", phone);

    try {
      const response = await nbbApi.post("/vendor_gallery_fetch_user", galleryData);

      if (response.data.status.toString() === "1") {
        dispatch(homeActions.VendorGalleryPath(response.data.gallery_path));
        console.log('VendorGalleryPath', response.data.gallery_path)

        // localStorage.setItem("vend_gall_img_id", response.data.result[0].id);
        // console.log("kalyaniGallerDATA", response.data.result[0].id);

        dispatch(homeActions.GallerySuccess(response.data.result));
        console.log("kalyaniGallerresultttt", response.data.result);

      } else if (response.data.status.toString() === "0") {
        dispatch(homeActions.GalleryFailure(response.data.msg));
      }
    } catch (e) {
      console.error("Error fetching vendor gallery:", e);
    }
  };
}


export const getVendorBanner = () => {
  return async (dispatch) => {
    try {
      await nbbApi.get("/web_vendor_banner").then((response) => {
        if (response.data.status.toString() === "1") {
          dispatch(homeActions.VendorBannerSuccess(response.data?.Banner[0]?.image));
          dispatch(homeActions.VendorBannerPathSuccess(response.data?.img_path));
          // dispatch(homeActions.bannerpathSuccess(response.data?.img_path));
          // console.log('bannerimg', response.data.Banner[0]);
          console.log("vendorbannerimg", response.data?.Banner[0]?.image);
        }
        if (response.data.status.toString() === '0') {
          dispatch(homeActions.VendorBannerFailure(response.data.msg));
        }
      });
    } catch (e) {
      return;
    }
  };
};
// export const getVendorGallery = (id) => {
//   console.log('aaaaaaaaa', id);
//   return async (dispatch) => {
//     const galleryData = new FormData();
//     galleryData.append("id", id);

//     try {
//       await nbbApi.post("/vendor_gallery_fetch", galleryData).then((response) => {
//         if (response.data.status.toString() === "1") {
//           dispatch(homeActions.VendorGallerydata(response.data.gallery_path));
//           localStorage.setItem("vend_gall_img_id",response.data.result[0].id)
//           console.log("kalyaniGallerDATA", response.data.result[0].id);
//           dispatch(homeActions.GalleryResultSuccess(response.data.result));
//           console.log("kalyaniGallerresultttt", response.data.result);
//         } else if (response.data.status.toString() === "0") {
//           dispatch(homeActions.GalleryFailure(response.data.msg));
//         }
//       });
//     } catch (e) {
//       return;
//     }
//   };
// };



// export const getVendorGalleryAdd = (id, image) => {
//   return async (dispatch) => {
    
//     try {
//       const response = await nbbApi.post("/vendor_gallery_add", image);
//       if (response.data.status.toString() === "1") {
//         dispatch(homeActions.VendorGalleryAddSuccess(response.data));
//         console.log("Kalyani Vendor galleryADD: ", response.data.message);
//       } else {
//         dispatch(homeActions.VendorGalleryAddFailure(response.data.message));
//         console.log("Error adding gallery: ", response.data.message);
//       }
//     } catch (error) {
//       console.error("Error adding gallery: ", error);
//       dispatch(homeActions.VendorGalleryAddFailure("Failed to add gallery"));
//     }
//   };
// };

// export const getVendorGalleryDelete = (VenGaid) => {
//   console.log('kalDELETE', VenGaid)
//   return async (dispatch) => {
//     const galleryDeleteData = new FormData();
//     galleryDeleteData.append("id", VenGaid);
//     try {
      
//       const response = await nbbApi.post("/vendor_gallery_delete", galleryDeleteData);
//       if (response.data.status.toString() === "1") {
//         dispatch(homeActions.VendorGalleryDeleteSuccess(response.data));
//         console.log("Kalyani Vendor galleryDelete: ", response.data);
//       } 
//       else {
//         dispatch(homeActions.VendorGalleryDeleteFailure(response.data.msg));
//         console.log("Error delete gallery: ", response.data.msg);
//       }
//     } catch (error) {
//       console.error("Error delete gallery: ", error);
//       // dispatch(homeActions.VendorGalleryDeleteFailure("Failed to add gallery"));
//     }
//   };
// };


export const vendorDeletePoke = (pokeid) => {
  return async (dispatch) => {
    try {
      const user_poke_id = new FormData();
      user_poke_id.append("id", pokeid);

      await nbbApi.post("/vendor_dlete_poke", user_poke_id);
      // if (response.data.status.toString() === "1") {
      //   dispatch(homeActions.userPokeDeleteSuccess(response.data));
      //   console.log("Kalyani Vendor galleryADD: ", response.data.msg);
      // } else {
      //   dispatch(homeActions.userPokeDeleteFailure(response.data.msg));
      //   console.log("Error adding gallery: ", response.data.msg);
      // }
    } catch (error) {
      console.error("Error adding gallery: ", error);
      // dispatch(homeActions.userPokeDeleteFailure("Failed to add gallery"));
    }
  };
};



export const vendorCreateregistration = (vendor_id) =>{
const formEditData = new FormData();
formEditData.append("vendor_id", vendor_id);
return async (dispatch) => {
try {
await nbbApi.post("/vendor_create_registration", formEditData).then((response) => {

dispatch(homeActions.registration_pay_data(response.data));
});
} catch (error) {}
};
}

export const paymentSuccess_vendor = (formEditData) => {
  console.log("success", formEditData);
  return async (dispatch) => {
    try {
      const response = await nbbApi.post("/vendor_payment_update", formEditData);
      dispatch(homeActions.PAYMENT_SUCCESS());
      if (response.data.status === true) {
        Swal.fire({
          title: 'PAYMENT SUCCESSFUL',
          text: 'Thank You 🙏',
          icon: 'success'
        }).then((result) => {
          // Check if the "OK" button is clicked
          if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
            // Reload the page
          }
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
};

export const serviceprice = () => {
  return async (dispatch) => {
    try {
      await nbbApi.get("/service_price").then((response) => {
        if (response.data.status === "1") {
          dispatch(homeActions.service_price(response.data));
          console.log("service_price", response.data);
        }
        if (response.data.status === "0") {
          dispatch(homeActions.service_price(response.data));
        }
      });
    } catch (e) {
      return;
    }
  };
};

export const giveratingnreview = (ratingnreviewdata) => {
  return async (dispatch) => {
    try {
      await nbbApi.post("/give_rating", ratingnreviewdata).then((response) => {
        if (response.status === "1") {
          dispatch(homeActions.giveratingsuccess(response.data.message));
        }
        if (response.data.status === "0") {
          dispatch(homeActions.giveratingfailure(response.data.message));
        }
      });
    } catch (e) {
      return;
    }
  };
};

// export const FetchRating = (service_providerId) => {
//   console.log('Fetchhhhhh', service_providerId);
//   return async (dispatch) => {
//     try {
//       await nbbApi.post("/fetch_rating", service_providerId).then((response) => {
//         if (response.status.toString() === "1") {
//           dispatch(homeActions.fetchratingsuccess(response.data.user_feedbacks));
//           console.log('kalyaniRating', response.data.user_feedbacks)
//         }
//         if (response.data.status.toString()  === "0") {
//           dispatch(homeActions.fetchratingfailure(response.data.message));
//         }
//       });
//     } catch (e) {
//       return;
//     }
//   };
// };
export const FetchRating = (formDataRating) => {
  console.log('Fetchhhhhh', formDataRating);
  return async (dispatch) => {
    try {
      const response = await nbbApi.post("/fetch_rating", formDataRating);
      if (response.data.status.toString() === "1") {
        dispatch(homeActions.fetchratingsuccess(response.data.user_feedbacks));
        console.log('kalyaniRating', response.data.user_feedbacks);
      }
      else if(response.data.status.toString() === "0") {
        dispatch(homeActions.fetchratingfailure(response.data.message));
      }
    } catch (e) {
      console.error('Error fetching ratings:', e);
    }
  };
};

// export const getVendorProfileImage = (phone) => {
//   console.log('vendorprofileimage', phone)
//   return async (dispatch) => {
//     const formData = new FormData();
//     formData.append("phone", phone);
//     try {
//       await nbbApi.post("/vendor_profile_image_fetch", formData).then((response) => {
//         if (response.data.status.toString() === "1") {
//           dispatch(homeActions.VendorProfileImageSuccess(response.data.result));
//           dispatch(homeActions.VendorProfileImagePathSuccess(response.data.image_path));
//           console.log('kalyaniProfileImage555555', response.data.result)
//         } 
//         else if(response.data.status.toString() === "0") {
//           dispatch(homeActions.VendorProfileFailure(response.data.message));
//         }
//       });
//     } catch (error) {
//       dispatch(homeActions.VendorProfileFailure(error.message));
//     }
//   };
// };

// export const getVendorProfileImgInsert = (phone, image) => {
//   console.log('vendorprofileimageinsert', phone)
//   return async (dispatch) => {
//     const formDataImgInsert = new FormData();
//     formDataImgInsert.append("phone", phone);
//     formDataImgInsert.append("image", image);
    
//     try {
//       const response = await nbbApi.post("/vendor_profile_image_insert", formDataImgInsert);
//       if (response.data.status.toString() === "1") {
//         dispatch(homeActions.ProfileImgInsertSuccess(response.data));
//         console.log("sssssss: ", response.data.message);
//       } else {
//         dispatch(homeActions.ProfileImgInsertFailure(response.data.message));
//         console.log("ttttttttttt: ", response.data.message);
//       }
//     } catch (error) {
//       console.error("Error adding gallery: ", error);
//       dispatch(homeActions.ProfileImgInsertFailure("Failed to add gallery"));
//     }
//   };
// };

// export const getVendorProfileImgDelete = (phone) => {
//   console.log('vendorprofileimageDelete', phone)
//   return async (dispatch) => {
//     const formDataImgDelete = new FormData();
//     formDataImgDelete.append("phone", phone);
    
//     try {
//       const response = await nbbApi.post("/vendor_profile_image_delete", formDataImgDelete);
//       if (response.data.status.toString() === "1") {
//         dispatch(homeActions.ProfileImgDeleteSuccess(response.data));
//         console.log("vvvvvvvv: ", response.data.message);
//       } else {
//         dispatch(homeActions.ProfileImgDeleteFailure(response.data.message));
//         console.log("wwwwwwwwww: ", response.data.message);
//       }
//     } catch (error) {
//       console.error("Error adding gallery: ", error);
//       dispatch(homeActions.ProfileImgDeleteFailure("Failed to add gallery"));
//     }
//   };
// };

export const { updateServiceStatus } = homeSlice.actions;
export default homeSlice.reducer;
