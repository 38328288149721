import React, {  useState, useEffect } from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import { useDispatch, useSelector } from 'react-redux';
import { getPrivacy } from '../store/home';


const PrivacyPolicy = () => {

  const dispatch = useDispatch();
  const [loaderStatus, setLoaderStatus] = useState(true);



  useEffect(() => {
    dispatch(getPrivacy());
  },[]);
  
  const privacy = useSelector(state => state.home.Privacy);
  console.log('Privacykalyani', privacy.title);



  useEffect(() => {
    setTimeout(() => {
      setLoaderStatus(false);
    }, 2000); // Set loaderStatus to false after 3 seconds (adjust as needed)
  }, []);


  return (
    <>
    {loaderStatus ? (
      <div className="loader-container">
        <PulseLoader loading={loaderStatus} size={50} color="#36d7b7" />
      </div>
    ) : (
    <div>

    <section className="android ptb30" style={{overflow: 'hidden'}}>
  <div className="content-banner">
    <div className="container">
      <h3 style={{width: '100%', textAlign: 'left', color: 'white', textTransform: 'inherit', fontSize: '20px', paddingTop: '3%'}}>Privacy Policy</h3>
    </div>
  </div>
</section>

   <section className="smooth-scroll ptb60" id="about">
  <div className="container">
    <h3 className="heading3 h3">{privacy.title}</h3>
    <div className="main-content" dangerouslySetInnerHTML={{ __html: privacy.contents }}>  
    </div>
  </div>
</section>
    </div>
    )}
    </>
  )
}

export default PrivacyPolicy