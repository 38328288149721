import React, { useState, useEffect } from "react";
import logo from "../Components/assets/img/logo.png";
import darklogo from "../Components/assets/img/Darklogo.png";
// import PulseLoader from "react-spinners/PulseLoader";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getHeader } from "../store/home";
import { userLogout } from "../store/auth";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Alert } from "@chakra-ui/react";
import { vendorLogout } from "../store/auth";
import humbergur from "../Components/assets/img/humbergur.png";
import playstore from "../Components/assets/img/playstore.png";


function Header() {
  const dispatch = useDispatch();
  const [showNav, setShowNav] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const navigate = useNavigate();

  // Animate header start
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showSecondHeader, setShowSecondHeader] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition =
        window.scrollY || document.documentElement.scrollTop;
      setScrollPosition(currentPosition);

      if (window.scrollY >= 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    background:
      scrollPosition > 100
        ? "linear-gradient(90deg, #eff3f6, #d9e8eb)"
        : "transparent",
    //  position: scrollPosition < 100 ? 'fixed' : '',
    position: scrollPosition < 100 ? "absolute" : "absolute",
    top: scrollPosition > 100 ? "0px" : "7px",
    zIndex: scrollPosition > 100 ? "999" : "999",
    transition: "background-color 0.5s ease",
  };

  // Animate header end

  //   Mobile menu start
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  //   Mobile menu end

  // scroll to top button start

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const isVisible = scrollY > 100;
    setIsVisible(isVisible);
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // scroll to top button end

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    dispatch(getHeader());
  }, []);

  const header = useSelector((state) => state.home.Header);
  // console.log('header', header);

  const handleLogin = () => {
    // Your login logic here
    setIsLoggedIn(true);
  };
  const handleLogout = () => {
    // Your logout logic here
    setIsLoggedIn(false);
  };
  const isUserLoggedStatus = useSelector((state) => state.auth.isUserLoggedIn);

  const handleUserLogout = () => {
    Swal.fire({
      title: "Are you sure want to?",
      text: "LOG OUT!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f1a228", //#3085d6
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Log Out!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Put your navigation and dispatch logic here
        // navigate("/");
        window.location.href = "/";
        dispatch(userLogout());
        Swal.fire("Logged Out!", "You have been logged out.", "success");
      }
    });
  };

  const vendorphonestatus =
    localStorage.getItem("formDataVendorPhone") !== null;

  const handleVendorLogout = () => {
    Swal.fire({
      title: "Are you sure want to?",
      text: "LOG OUT!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f1a228", //#3085d6
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Log Out!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Put your navigation and dispatch logic here
        // navigate("/");
        window.location.href = "/";
        dispatch(vendorLogout());
        Swal.fire("Logged Out!", "You have been logged out.", "success");
      }
    });
  };



  const handleLinkClick = (url) => {
    setIsOpen(false); 
    navigate(url);
  };


  return (
    <>
      <header
        id="header"
        style={navbarStyle}
        className="fixed-top d-flex align-items-center d-none d-lg-block"
      >
        <div className="container" style={{ display: "flex", alignItems: 'center'  }}>
          <div className=" col-md-12">
            <div
              className="navbar navbar-expand-lg {`navbar ${scrolled ? '' : 'scrolled'}`}"
              id="navbar"
            >
              <div className="container d-flex justify-content-between align-items-center headerlogo">
                <div className="logo">
                  <Link to="/" onClick={handleScrollToTop}>
                    <img src={darklogo} alt="nbblogo" />
                  </Link>
                </div>
                <button
                  className={`navbar-toggler ${isOpen ? "collapsed" : ""}`}
                  type="button"
                  aria-controls="navbarNavDropdown"
                  aria-expanded={isOpen ? "true" : "false"}
                  onClick={handleToggle}
                  id="menubutton"
                >
                  <span className="navbar-toggler-icon menubuttonsize"></span>
                </button>
                <div
                  className={`collapse navbar-collapse justify-content-center ${
                    isOpen ? "show" : ""
                  }`}
                  id="navbarNavDropdown"
                >
                  <ul className="navbar-nav" style={{columnGap: '30px'}}>
                    {header?.map((menuItem, index) => (
                      <li className="nav-item dropdown" key={index}>
                        {menuItem.sub_array && menuItem.sub_array.length > 0 ? (
                          <div className="dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href={menuItem.url}
                              id={`menu${index}`}
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {menuItem.name}
                            </a>

                            {/* Render submenu items */}

                            <ul
                              className="dropdown-menu"
                              aria-labelledby={`menu${index}`}
                            >
                              {menuItem.sub_array.map((subMenuItem) => (
                                <li key={subMenuItem.id}>
                                  <a
                                    className="dropdown-item"
                                    href={subMenuItem.url}
                                  >
                                    {subMenuItem.title}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : (
                          <Link className="nav-link" to={menuItem.url}>
                            {menuItem.name}
                          </Link>
                        )}
                      </li>
                    ))}
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Account
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#">
                            {isUserLoggedStatus ? (
                              <div className="button-container" style={{alignItems: "center", columnGap: "5px"}}>
                                <div
                                  id="homevendorbtndesgn"
                                  // className="btn  btn-get-started header-button"
                                  onClick={handleUserLogout}
                                >
                                  USER LOGOUT
                                </div>
                                <div
                                  spiketip-title="USER PROFILE"
                                  spiketip-pos="bottom"
                                >
                                  <button  className="btn btn-get-started user-button" id="homevendorbtndesgn" onClick={() => navigate("/user_profile")}>
                                  {/* <i
                                    class="fas fa-user-circle usericon"
                                    style={{
                                      "font-size": "26px",
                                      color: "green",
                                      paddingTop: "10px",
                                    }}
                                    onClick={() => navigate("/user_profile")}
                                  ></i> */}
                                  USER PROFILE
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <button
                                className="btn btn-get-started header-button"
                                onClick={() => navigate("/user_login")}
                              >
                                USER LOGIN
                              </button>
                            )}
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            {vendorphonestatus ? (
                              <div className="homelogin">
                                <div
                                id="homevendorbtndesgn"
                                  onClick={handleVendorLogout}
                                >
                                  VENDOR LOGOUT
                                </div>
                                {/* <div
                                  spiketip-title="VENDOR PROFILE"
                                  spiketip-pos="bottom"
                                >
                                  <i
                                    className="fas fa-user-circle usericon"
                                    style={{
                                      "font-size": "26px",
                                      color: "green",
                                      paddingTop: "10px",
                                    }}
                                    onClick={() => (window.location.href = "/vendor_profile")}
                                  ></i>
                                </div> */}
                                <div
                                  spiketip-title="VENDOR PROFILE"
                                  spiketip-pos="bottom"
                                >
                                  <button  className="btn btn-get-started user-button" id="homevendorbtndesgn" onClick={() => (window.location.href = "/vendor_profile")}>
                                  {/* <i
                                    class="fas fa-user-circle usericon"
                                    style={{
                                      "font-size": "26px",
                                      color: "green",
                                      paddingTop: "10px",
                                    }}
                                    onClick={() => navigate("/user_profile")}
                                  ></i> */}
                                  VENDOR PROFILE
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <button
                                id="homevendorbtndesgn"
                                onClick={() =>
                                  (window.location.href = "/login")
                                }
                                className="btn  btn-get-started "
                              >
                                VENDOR LOGIN
                              </button>
                            )}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-2">
            <a href="https://play.google.com/store/apps/details?id=com.newbharattech.newbharatbiz&hl=en&gl=US">
              <img
                src={playstore}
                alt="playstore"
                style={{ height: "32px", marginTop: "10%" }}
              />
            </a>
          </div> */}
        </div>
        {/* </div> */}
      </header>
      <header
        id="header"
        style={navbarStyle}
        className="fixed-top d-flex align-items-center  d-lg-none"
      >
        <div className="container headerlogo col-md-12" style={{ display: "flex", alignItems: 'center' }}>
          <div className="col-8 logo">
          <Link to="/" onClick={() => handleLinkClick('/')}>
              <img src={darklogo} alt="nbblogo" />
            </Link>
          </div>
          {/* <div className="col-md-2">
            <a href="https://play.google.com/store/apps/details?id=com.newbharattech.newbharatbiz&hl=en&gl=US">
              <img
                src={playstore}
                alt="playstore"
                style={{ height: "30px"}}
              />
            </a>
          </div> */}
          <div className="col-4" style={{display: 'grid'}}>
          <button
            className={`navbar-toggler ${isOpen ? "collapsed" : ""}`}
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-expanded={isOpen ? "true" : "false"}
            onClick={handleToggle}
          >
            <span className="navbar-toggler-icon menubuttonsize">
              <img
                src={humbergur}
                alt="icon"
                style={{
                  height: "30px",
                  border: "1px solid gray",
                  boxShadow: "2px 2px 3px 1px gray",
                  float: "left",
                }}
              />
            </span>
          </button>
          </div>
          <div
            // className="offcanvas offcanvas-end"
            className={`offcanvas offcanvas-end${isOpen ? ' show' : ''}`}
            tabindex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
            {/* <div className="logo" >
                  <Link to="/" 
                  //  onClick={handleScrollToTop}
                  onClick={() => {
                    handleLinkClick('/'); 
                    handleScrollToTop(); 
                  }}
                  >
                    <img src={darklogo} alt="nbblogo"/>
                  </Link>
                </div> */}
                <div className="logo">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => {
                      handleLinkClick('/');
                      window.location.href = '/'; 
                    }}
                  >
                    <img src={darklogo} alt="nbblogo" />
                  </button>
                </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => setIsOpen(false)}
              />
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav">
                {header?.map((menuItem, index) => (
                  <li className="nav-item dropdown" key={index}>
                    {menuItem.sub_array && menuItem.sub_array.length > 0 ? (
                      <div className="dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href={menuItem.url}
                          id={`menu${index}`}
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={() => handleLinkClick(menuItem.url)}
                        >
                          {menuItem.name}
                        </a>

                        {/* Render submenu items */}

                        <ul
                          className="dropdown-menu"
                          aria-labelledby={`menu${index}`}
                        >
                          {menuItem.sub_array.map((subMenuItem) => (
                            <li key={subMenuItem.id}>
                              <a
                                className="dropdown-item"
                                href={subMenuItem.url}
                                onClick={() => handleLinkClick(subMenuItem.url)}
                              >
                                {subMenuItem.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <Link className="nav-link" to={menuItem.url} 
                      // onClick={() => handleLinkClick(menuItem.url)}
                      onClick={() => {
                        handleLinkClick(menuItem.url);
                        window.location.href = menuItem.url;
                      }}
                      >
                        {menuItem.name}
                      </Link>
                      // <button
                      //   type="button"
                      //   className="btn btn-link"
                      //   onClick={() => {
                      //     handleLinkClick(menuItem.url);
                      //     window.location.href = menuItem.url; // Navigating using window.location.href
                      //   }}
                      // >
                      //   {menuItem.name}
                      // </button>
                    )}
                  </li>
                ))}
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    // onClick={handleLinkClick}
                  >
                    Account
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" 
                      href="#" 
                      // onClick={handleLinkClick}
                      onClick={() => handleLinkClick('/user_login')}
                      >
                        {isUserLoggedStatus ? (
                          <div className="button-container" style={{alignItems: "center"}}>
                            <div
                              id="homevendorbtndesgn"
                              // className="btn  btn-get-started header-button"
                              onClick={handleUserLogout}
                            >
                              USER LOGOUT
                            </div>
           
                            <div
                                  spiketip-title="USER PROFILE"
                                  spiketip-pos="bottom"
                                >
                                  <button  className="btn btn-get-started user-button" id="homevendorbtndesgn" onClick={() => (window.location.href ="/user_profile")}>
                                  USER PROFILE
                                  </button>
                                </div>
                          </div>
                        ) : (
                          <button
                          id="homevendorbtndesgn"
                            className="btn btn-get-started "
                            // onClick={() => navigate("/user_login")}
                            onClick={() => (window.location.href = "/user_login")}
                            // onClick={() => handleLinkClick('/user_login')}
                          >
                            USER LOGIN
                          </button>
                        )}
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a className="dropdown-item"
                       href="#" 
                      //  onClick={handleLinkClick}
                      onClick={() => handleLinkClick('/login')}
                       >
                        {vendorphonestatus ? (
                          <div className="homelogin">
                            <div
                            id="homevendorbtndesgn"
                              // className="btn btn-get-started  header-button"
                              onClick={handleVendorLogout}
                            >
                              VENDOR LOGOUT
                            </div>
                            <div
                                  spiketip-title="VENDOR PROFILE"
                                  spiketip-pos="bottom"
                                >
                                  <button  className="btn btn-get-started user-button" id="homevendorbtndesgn" onClick={() => (window.location.href = "/vendor_profile")}>
                                  VENDOR PROFILE
                                  </button>
                                </div>
                          </div>
                        ) : (
                          <button
                            id="homevendorbtndesgn"
                            onClick={() => (window.location.href = "/login")}
                            className="btn  btn-get-started "
                          >
                            VENDOR LOGIN
                          </button>
                        )}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* </div> */}
      </header>
    </>
  );
}
export default Header;
