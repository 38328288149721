import React, { useState, useEffect } from "react";
// import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
// import avatarimg from '../Components/assets/img/avatar.png';
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../store/home";

import axios from "axios";
import { Card, Button, Modal } from "react-bootstrap";

import Swal from "sweetalert2";
import { nbbApi } from "../store/axios";

const UserProfile = () => {
  const [isPanelOpen1, setIsPanelOpen1] = useState(false);
  const [isPanelOpen2, setIsPanelOpen2] = useState(false);
  const [pokeList, setPokeList] = useState([]);
  const [favouritelisting, setFavouritelisting] = useState([]);
  const isUserLoggedStatus = useSelector((state) => state.auth.isUserLoggedIn);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const user_id = localStorage.getItem("user_id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isUserLoggedStatus) {
      setShowLoginModal(true);
    } else {
      const formDataToSenduserid = new FormData();
      formDataToSenduserid.append("user_id", user_id);
      dispatch(userProfile(formDataToSenduserid));
    }
  }, [user_id]);

  const userprofiledata = useSelector((state) => state.home.userprofiledetails);

  const fetchPokeList = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", user_id);

      const response = await nbbApi.post("/user_poke_listing", formData);
      const data = response.data;

      if (data.status === 1) {
        setPokeList(data.poke_listing);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("API request failed:", error);
    }
  };

  const fetchFavoriteList = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", user_id);

      const response = await nbbApi.post("/favourite_listing", formData);
      const data = response.data;

      if (data.status === "1") {
        setFavouritelisting(data.record);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("API request failed:", error);
    }
  };

  const handledeletepoke = async (pokeid, pokename) => {
    try {
      const formData = new FormData();
      formData.append("id", pokeid);

      await nbbApi.post("/user_dlete_poke", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setPokeList((prevPokeList) =>
        prevPokeList.filter((poke) => poke.id !== pokeid)
      );

      // Add a success notification
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: `Poke for ${pokename} Deleted`,
      });
    } catch (error) {
      console.error("Delete poke request failed:", error);
    }
  };

  const handledeletefavourite = async (favid, favname, favvs) => {
    try {
      const formData = new FormData();
      formData.append("fav_id", favid);

      await nbbApi.post("/delete_fav_listing", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setFavouritelisting((prevFavourites) =>
        prevFavourites.filter((favorite) => favorite.favId !== favid)
      );
      // Add a success notification
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: `${favvs} ${favname} Deleted`,
      });
    } catch (error) {
      console.error("Delete favorite request failed:", error);
    }
  };

  useEffect(() => {
    // Call both fetchPokeList and fetchFavoriteList in parallel
    Promise.all([fetchPokeList(), fetchFavoriteList()])
      .then(() => {
        // Both API calls have completed
        // You can put your logic here to handle the completion of both calls.
        // For example, you can update your component state or trigger other actions.
      })
      .catch((error) => {
        console.error("API requests failed:", error);
      });
  }, [user_id]);

  const togglePanel1 = () => {
    setIsPanelOpen1(!isPanelOpen1);
  };

  const togglePanel2 = () => {
    setIsPanelOpen2(!isPanelOpen2);
  };

  return (
    <div className="page-wraper" style={{ marginTop: "10%" }}>
      {isUserLoggedStatus ? (
        <div className="page-content3">
          <div className="container col-md-6 col-md-12 pb-5 register">
            <main>
              <div className=" dashboarddesgn inner-breadcrumb">
                <div className="container">
                  <div className="breadcrumb-head" style={{ display: "flex" }}>
                    <div className="col-10">
                      <h3>Dashboard</h3>
                    </div>
                    <div className="col-2">
                      <Link to="/">
                        <i
                          class="fa fa-home"
                          style={{ "font-size": "36px", color: "#008000" }}
                        ></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <section className="single-wrapper section-wrapper">
                <div className="container">
                  <div className="flexTab d-flex tabpaneldesgn">
                    <div className="col-md-2" style={{ margin: "10px" }}>
                      <ul
                        className="nav nav-tabs d-lg-flex flex-column"
                        id="actionTab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="dashboard-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#dashboard-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="dashboard-tab-pane"
                            aria-selected="true"
                          >
                            <i className="ri-dashboard-line" />
                            Dashboard
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="poke-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#poke-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="poke-tab-pane"
                            aria-selected="false"
                          >
                            <i className="ri-account-circle-line" /> Poke
                            Listing
                          </button>
                        </li>

                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="favorite-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#favorite-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="favorite-tab-pane"
                            aria-selected="false"
                          >
                            <i className="ri-map-pin-user-line" /> Favorite
                            Services
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-10" style={{ margin: "10px" }}>
                      <div
                        className="tab-content accordion"
                        id="dashboardAction"
                      >
                        <div
                          className="tab-pane fade show active accordion-item"
                          id="dashboard-tab-pane"
                          role="tabpanel"
                          aria-labelledby="dashboard-tab"
                          tabIndex={0}
                        >
                          <h2
                            className="accordion-header d-lg-none"
                            id="headingOne"
                          >
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#dashboard"
                              aria-expanded="true"
                              aria-controls="dashboard"
                            >
                              <i className="ri-dashboard-line" /> Dashboard
                            </button>
                          </h2>
                          <div
                            id="dashboard"
                            className="accordion-collapse collapse show d-lg-block"
                            aria-labelledby="dashboard"
                            data-bs-parent="#dashboardAction"
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-md-6">
                                  <div
                                    className="card-dash bg-orange p-3"
                                    style={{
                                      backgroundColor: "#f1a228",
                                      cursor: "pointer",
                                    }}
                                    // onClick={togglePanel1}
                                  >
                                    <h5 className="mb-0 text-white">
                                      User Profile
                                    </h5>
                                  </div>
                                  {/* {isPanelOpen1 && ( */}
                                  <div className="col-md-12 mt-3">
                                    <div
                                      className="panel panel-info"
                                      id="panelborder"
                                    >
                                      <div className="panel-heading">
                                        <i className="fa fa-user" /> &nbsp;
                                        {userprofiledata.customer_name}
                                      </div>
                                      <div className="panel-body p-2">
                                        <p style={{ marginBottom: 8 }}>
                                          <b>Mobile Number :</b>
                                          {userprofiledata.customer_phone}
                                        </p>
                                        <p style={{ marginBottom: 8 }}>
                                          <b>Email : </b>
                                          {userprofiledata.email}
                                        </p>
                                        <p style={{ marginBottom: 8 }}>
                                          <b>Address : </b>
                                          {userprofiledata.address}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  {/* )} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade accordion-item"
                          id="poke-tab-pane"
                          role="tabpanel"
                          aria-labelledby="poke-tab"
                          tabIndex={0}
                        >
                          <h2
                            className="accordion-header d-lg-none"
                            id="headingTwo"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#pokeAc"
                              aria-expanded="false"
                              aria-controls="pokeAc"
                            >
                              <i className="ri-account-circle-line" />
                              Poke Listing
                            </button>
                          </h2>
                          <div
                            id="pokeAc"
                            className="accordion-collapse  d-lg-block"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#myTabContent"
                          >
                            <div className="accordion-body">
                            <div className="row">
                              <div className="row">
                                <div className="col">
                                  <div
                                    className="card card-dash bg-orange p-3"
                                    style={{
                                      backgroundColor: "#f1a228",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <h5 className="mb-0 text-white">
                                      Poke Listing
                                    </h5>
                                  </div>
                                  </div>
                              </div>
                                  <div className="row  row-cols-1 row-cols-md-3 g-4">
                                    {pokeList.length === 0 ? (
                                      <div className="col" >
                                      <div className=" card h-100">
                                        <div className="card-body no-services-message">
                                          <p className="card-text">
                                            Oops you have not poked anything
                                          </p>
                                        </div>
                                      </div>
                                      </div>
                                    ) : (
                                      pokeList.map((poke) => (
                                        <div className="col" style={{display: 'flex', columnGap: '10px'}}>
                                        <div className="card h-100" key={poke.id}>
                                          <div className="card-body">
                                            <h5 className="card-title"
                                              style={{
                                                backgroundColor: "#d9edf7",
                                                padding: "10px",
                                              }}
                                            >
                                              {poke.sername}
                                            </h5>
                                          <p className="card-text">
                                              <p>Provider Name: {poke.pname}</p>
                                              <p>Address: {poke.paddress}</p>
                                              <p>Date: {poke.date}</p>
                                              <p>Email: {poke.emaildta}</p>
                                              <p>Phone: {poke.serphone}</p>
                                            </p>
                                        </div>
                                            <div className="card-footer">
                                            <small className="text-body-secondary">
                                            <Button
                                              onClick={() =>
                                                handledeletepoke(
                                                  poke.id,
                                                  poke.pname
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-trash-o"
                                                style={{
                                                  fontSize: 20,
                                                  color: "red",
                                                }}
                                              />
                                              &nbsp; Remove Poke
                                            </Button>
                                            </small>
                                            </div>
                                        </div>
                                        </div>
                                      ))
                                    )}
                                  </div>
                                  {/* </div> */}
                                  {/* </div> */}
                                {/* </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade accordion-item"
                          id="favorite-tab-pane"
                          role="tabpanel"
                          aria-labelledby="favorite-tab"
                          tabIndex={0}
                        >
                          <h2
                            className="accordion-header d-lg-none"
                            id="favoriteAc"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#favoriteAc"
                              aria-expanded="false"
                              aria-controls="favoriteAc"
                            >
                              <i className="ri-map-pin-user-line" /> Favorite
                            </button>
                          </h2>
                          <div
                            id="favoriteAc"
                            className="accordion-collapse  d-lg-block"
                            aria-labelledby="favoriteAc"
                            data-bs-parent="#favoriteAc"
                          >
                            <div className="accordion-body">
                              {/* address */}
                              <div className="row">
                               <div className="row">
                                <div className="col">
                                  <div
                                    className="card card-dash bg-orange p-3"
                                    style={{
                                      backgroundColor: "#f1a228",
                                      cursor: "pointer",
                                    }}
                                    // onClick={togglePanel1}
                                  >
                                    <h5 className="mb-0 text-white">
                                      Favorite Service
                                    </h5>
                                  </div>
                                  </div>
                                  </div>
                                  <div className="favorite-card-main row  row-cols-1 row-cols-md-3 g-4">
                                      {favouritelisting.length === 0 ? (
                                        <div className="col">
                                          <div className=" card h-100">
                                          <div className="card-body no-services-message">
                                            <p className="card-text">
                                              Oops You have not added favourite
                                            </p>
                                          </div>
                                          </div>
                                        </div>
                                      ) : (
                                        
                                        favouritelisting.map((favorite) => (
                                          <div className="col" style={{display: 'flex', columnGap: '10px'}}>
                                          <div className="card h-100" key={favorite.favId}>
                                            <div className="card-body">
                                            <h5 className="card-title"
                                              style={{
                                                backgroundColor: "#d9edf7",
                                                padding: "10px",
                                              }}
                                            >
                                              {favorite.vendor_name}
                                            </h5>
                                              <p className="card-text">
                                                <p>Service Provider: {favorite.vendor_service}</p> 
                                                <p>Address: {favorite.vendor_address}</p>
                                                <p> Phone: {favorite.vendor_phone}</p>
                                                <p>Email: {favorite.vendor_email}</p>
                                              </p>
                                            </div>
                                            <div className="card-footer">
                                            <small className="text-body-secondary">
                                              <Button
                                                onClick={() =>
                                                  handledeletefavourite(
                                                    favorite.favId,
                                                    favorite.vendor_name,
                                                    favorite.vendor_service
                                                  )
                                                }
                                              >
                                                <i
                                                  className="fa fa-trash-o"
                                                  style={{
                                                    fontSize: 20,
                                                    color: "red",
                                                  }}
                                                />
                                                &nbsp; Delete favorite
                                              </Button>
                                            </small>
                                          </div>
                                          </div>
                                          </div>
                                        ))
                                      )}
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <hr />
            </main>
          </div>
        </div>
      ) : (
        <Modal show={showLoginModal} onHide={() => setShowLoginModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Login Required</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Please log in to view your profile.</p>
            
            <Button onClick={() => navigate("/user_login")}>
              Go to Login Page
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowLoginModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default UserProfile;
