import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import Redux hooks
import Loginimg from '../Components/assets/img/loginvender.png';
import { sendOtp, getVendorVerify } from '../store/auth';
import { useNavigate } from 'react-router-dom';
import { vendorLogin, vendorLogout } from "../store/auth";
import Swal from 'sweetalert2';

const Login = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch(); // Initialize the useDispatch hook
  const [mobileNumber, setMobileNumber] = useState('');
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [errMsg, setErrMsg] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [verificationError, setVerificationError] = useState('');
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isVendorLoggedIn = useSelector((state) => state.auth.isVendorLoggedIn);
  const [countdown, setCountdown] = useState(60); 



  // Countdown timer effect
  useEffect(() => {
    let timer;
    if (isOtpSent && countdown > 0) {
      timer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isOtpSent, countdown]);


  // Effect to reset countdown when OTP is sent
  useEffect(() => {
    if (isOtpSent) {
      setCountdown(60);
    }
  }, [isOtpSent]);


    // Effect to check if countdown has expired
    useEffect(() => {
      if (countdown === 0) {
        // Handle countdown expiration here
        // For now, let's reset isOtpSent
        setIsOtpSent(false);
      }
    }, [countdown]);


  useEffect(() => {
    // Check if mobile number is valid (e.g., 10 digits)
    if (mobileNumber.length === 10) {
      setIsMobileValid(true);
      setErrMsg('');
    }
  }, [mobileNumber]);

  const handleInputChange = (e) => {
    // Filter out non-numeric characters
    const numericValue = e.target.value.replace(/\D/g, '');
    setMobileNumber(numericValue);
  };
  const otp_sendStatus = useSelector(state => state.auth.otp_sent_status);
  const vendor_verify_status = useSelector(state => state.auth.vendorverify);

 
  useEffect(() => {
    console.log('useEffect is running'); // Add this line for debugging
    console.log('ggg', vendor_verify_status.status);
    if (vendor_verify_status.status === 1) {
      console.log('hiiiii');
      // localStorage.setItem("formDataphone", JSON.stringify(formDataJson));
      dispatch(sendOtp(vendor_verify_status?.mobile));
    }
  }, [vendor_verify_status]);
  useEffect(() => {
    // Check if mobile number is valid (e.g., 10 digits)
    if (isVendorLoggedIn) {
      navigate("/")
    }
  });

 const handleSubmit = (e) => {
  e.preventDefault();
  const validOTP = '123456';
  if (otp === validOTP) {
    setIsOTPVerified(true);
    navigate("/vendor_profile");
  } else {
    setIsOTPVerified(false);
    setErrMsg('Please enter a valid OTP.');
  }
  // if (isMobileValid && isOTPVerified) {
  //   navigate("/vendor_profile");
  // } else if (!isMobileValid) {
  //   setErrMsg('Please enter a valid mobile number.');
  // } else if (!isOTPVerified) {
  //   setErrMsg('Please enter a valid OTP.');
  // }
};

   
   const erMsg = () =>{
    if(vendor_verify_status != ''){
      return <p style={{ padding: '1px', color: 'red' }}>{vendor_verify_status.message}</p>;
    }else{
      return null;
    }
   }

   const handleOTPChange = (e) => {
    setOtp(e.target.value);
  };
 
  const send_otp = useSelector(state => state.auth.otpSent);
  console.log("success_otp",send_otp);
  const otpVal = send_otp.otp;
 

  
  const verifyOtp = (e) => {
    //const enteredOtp = e.target.value;
    const enteredOtp = document.getElementById('otp_v').value;
    console.log('enteredOtp',enteredOtp);
    console.log('otpVal',otpVal);

    if (otpVal === enteredOtp) {
      localStorage.setItem("formDataVendorPhone", mobileNumber );
      dispatch(vendorLogin())
      console.log('Successfully logged in');
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Login Successfull.',
        confirmButtonText: 'OK'
      });
      navigate('/vendor_profile')
    } else {
      console.log('Login fail');
      Swal.fire({
        icon: 'Error',
        title: 'Failure!',
        text: 'Wrong OTP. Please enter valid OTP.',
        confirmButtonText: 'OK'
      });
    }
  };
  return (


    <div className="page-header kalyani">
    <div className="container-fluid p-0">
      <div className="top-left">
    <div className="page-wraper">
      <div className="page-content pb-5">
        <div className="container col-md-6 col-md-12 pb-5 register">
          <div className="container col-md-6 registerhead">
            <h1 className="registerhead"> 
            Vendor Login </h1>
          </div>
          <div className="container">
            <div className="row row-cols-1 row-cols-md-3 g-4">
              <div className="col">
              <div class="card h-100">
                <div className="container-form">
                  <div className="form-item log-in">
                    <div className="table">
                      <div className="table-cell text-center" id="property">
                      <form onSubmit={handleSubmit}>
                        <p />
                        {erMsg()}
                        <h5 className="heading5 mb-2">
                          <img src={Loginimg} width="200px" alt="Login" />
                        </h5>
                        {isOtpSent ? (
                          <>
                            <input
                              name="otp"
                              placeholder="Enter OTP"
                              type="text"
                              className="form-control"
                              maxLength={4}
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                              required
                              id="otp_v"
                            />
                            <button type="submit" id="verify_otp" className="btn" onClick={(e) => verifyOtp(e)}>
                              Verify OTP
                            </button>
                            <p style={{color: 'red', fontWeight: '600', fontSize: '14px'}}>Resend OTP in {countdown} seconds</p>
                          </>
                        ) : (
                          <>
                            <input
                              name="Username"
                              id="mobile_number"
                              placeholder="Enter Mobile Number"
                              type="tel"
                              className={`form-control js-input-mobile ${
                                !isMobileValid ? 'is-invalid' : ''
                              }`}
                              maxLength={10}
                              value={mobileNumber}
                              onChange={handleInputChange}
                              required
                            />
                            <button
                              type="button"
                              onClick={() => {
                                if (isMobileValid) {
                                  dispatch(getVendorVerify(mobileNumber));
                                  setIsOtpSent(true);
                                } else {
                                  setErrMsg('Please enter a valid mobile number.');
                                }
                              }}
                              id="send_otp"
                              className="btn"
                            >
                              Send OTP
                            </button>
                          </>
                        )}
                        </form>
                       <div className="table-cell p-2">
                        New user? <a href="/vendor_registration" style={{color: '#00cc95'}}>Register now</a> /
                        <a href="/login" style={{color: '#00cc95'}}>Login</a>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
  );
};

export default Login;