import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getServiceCategory, getfetchServiceCategory } from "../store/home";
import { getState, getDistrict, postRegistration } from "../store/home";
import AutoComplete from "../Components/AutoComplete";


export default function App() {
  const navigate = useNavigate();

  const dispatch = useDispatch();



  useEffect(() => {
    dispatch(getServiceCategory());
  }, []);
  const serviceCategory = useSelector((state) => state.home.ServiceCategory);

 
  const serviceCatbannrpath = useSelector((state) => state.home.serviceCategoryBannrpath);

  const handleSelectChange_category = (e) => {
    const selectedCategory = e.target.value;
    // console.log("Selected category :", selectedCategory);
    dispatch(getfetchServiceCategory(selectedCategory));
  };

  const {fetchServiceCategoryList}  = useSelector(state => state.home);
  // console.log("category list chek" , fetchServiceCategoryList)



  // Location API START
 


  useEffect(() => {
    dispatch(getState());
  },[]);
  
  const statedata = useSelector((state) => state.home.StateList);

  const handleSelectChange_state = (e) => {
    const selectedState = e.target.value;
    console.log("Selected state :", selectedState);
    dispatch(getDistrict(selectedState));
};

  const {districtdata} = useSelector(state => state.home);
  // console.log('districtDataKalyani', districtdata);
  // Location API END



  // Register API Start

 
  const [formData, setFormData] = useState({
    username: "",
    mobile: "",
    email: "",
    orgName: "",
    category: "",
    service: "",
    latitude: "",
    Longitude: "",
    landmark: "",
    place_id: "",
    country: "",
    state: "",
    city: "",
    area: "",
    pincode: "",
    gstn: "",
    district: "",
    description: "",
    location: "",
    tandc: false, 
  });
  const [errors, setErrors] = useState({
    username: "",
    mobile: "",
    email: "",
    orgName: "",
    category: "",
    service: "",
    latitude: "",
    Longitude: "",
    landmark: "",
    place_id: "",
    country: "",
    state: "",
    city: "",
    area: "",
    pincode: "",
    gstn: "",
    district: "",
    description: "",
    location: "",
    tandc: "",
  });

 

  /*const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });

    // Reset the error message for the current field
    setErrors({
      ...errors,
      [name]: "",
    });
  };*/


  const validateForm = () => {
    let isValid = true;

    if (formData.username.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        username: "Username is required",
      }));
      isValid = false;
    }

    if (formData.mobile.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "Mobile number is required",
      }));
      isValid = false;
    }

    if (!/^\d{10}$/.test(formData.mobile.trim())) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "Mobile number must be 10 digits",
      }));
      isValid = false;
    }

    if (formData.email.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required",
      }));
      isValid = false;
    }

    if (!/\S+@\S+\.\S+/.test(formData.email.trim())) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email format",
      }));
      isValid = false;
    }

    if (!formData.tandc) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        tandc: "Please accept the terms and conditions",
      }));
      isValid = false;
    }

    return isValid;
  };


  const OnSubmitForm = (e) => {
     console.log('1');
    // if (validateForm()) {
     
      const formDataObject = new FormData();
      formDataObject.append("user_name", formData.username);
      formDataObject.append("mobile_no", formData.mobile);
      formDataObject.append("email", formData.email);
      formDataObject.append("business_name", formData.orgName);
      formDataObject.append("cat_id", formData.category);
      formDataObject.append("service_id", formData.service);
      formDataObject.append("latitude", formData.latitude);
      formDataObject.append("Longitude", formData.Longitude);
      formDataObject.append("landmark", formData.landmark);
      formDataObject.append("place_id", formData.place_id);
      formDataObject.append("country", formData.country);
      formDataObject.append("state", formData.state);
      formDataObject.append("city", formData.city);
      formDataObject.append("area", formData.area);
      formDataObject.append("pincode", formData.pincode);
      formDataObject.append("gstn", formData.gstn);
      formDataObject.append("district", formData.district);
      formDataObject.append("description", formData.description);
      formDataObject.append("address", formData.location);
      formDataObject.append("tandc", formData.tandc ? 1 : 0);

      dispatch(postRegistration(formDataObject));
      console.log("kalyaniregister", formDataObject);
      localStorage.setItem("formData", JSON.stringify(formDataObject));
      //  navigate("/vendor_profile");
      // } else {
      
      //   // toast.error("Vendor registration failed. Please try again.");
      // }
     
  };
  // Register API end

  return (
    <div className="page-wraper">
      <div className="page-content">
        <div className=" container col-md-6 col-md-12 pb-5 register">
          <div className="container col-md-6 registerhead">
            <h1 className="registerhead">
              <i>Register</i>
            </h1>
          </div>
          <div className="container col-md-7 registerhead1">
            <div className="row">
              <div className="col-md-12">
                <div className="dashboard-profile-section clearfix">
                  <div className="dasboard-profile-form overflow-hide">
                    <form onSubmit={OnSubmitForm}>
                      <div className="row">
                        <div className="col-lg-6 p-2 col-md-12">
                          <div className="form-group">
                            <label>
                              <i>Name </i>
                              <span className="req">*</span>
                            </label>
                            <div className="ls-inputicon-box">
                              <input
                                className="form-control"
                                id="firstName"
                                name="firstName"
                                placeholder="Enter your name"
                                // onChange={handleInputChange}
                              />
                              <i className="fs-input-icon sl-icon-user " />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 p-2 col-md-12">
                          <div className="form-group">
                            <label>
                              <i>Organization Name</i>
                              <span className="req">*</span>
                            </label>
                            <div className="ls-inputicon-box">
                              <input
                              //  onChange={handleInputChange}
                                className="form-control"
                                id="orgName"
                                name="orgName"
                                placeholder="Enter organization"
                              />
                              <i className="fs-input-icon sl-icon-layers " />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 p-2 col-md-12">
                          <div className="form-group">
                            <label>
                              <i>Select Category </i>
                              <span className="req">*</span>
                            </label>
                            <div className="ls-inputicon-box">
                              <select
                                className="form-control"
                                id="category"
                                name="category"
                                // onChange={(e) => handleSelectChange_category(e)}
                                onChange={(e) => {
                                  // handleInputChange(e);
                                  handleSelectChange_category(e);
                                }}
                              >
                                <option value="">Choose Category</option>
                                {serviceCategory?.map((serviceCat) => (
                                  <option value={serviceCat.id}>{serviceCat.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 p-2 col-md-12">
                          <div className="form-group">
                            <label>
                              <i>Select Service </i>
                              <span className="req">*</span>
                            </label>
                            <div className="ls-inputicon-box">
                              <select
                                className="form-control"
                                id="service"
                                name="service"
                                // onChange={handleInputChange}
                              >
                                <option value="">Choose Service</option>{
                                 fetchServiceCategoryList?.map((fetchserviceCat) => (
                                    <option key={fetchserviceCat.id} value={fetchserviceCat.id}>
                                      {fetchserviceCat.service_name}
                                    </option>
                                  ))
                                 }
                              </select>
                            </div>
                          </div>
                        </div>

                        

                        <div className="col-lg-6 p-2 col-md-12">
                          <div className="form-group">
                            <label>
                              <i>Mobile</i> <span className="req">*</span>
                            </label>
                            <div className="ls-inputicon-box">
                              <input
                                className="form-control"
                                // onChange={handleInputChange}
                                maxLength={10}
                                id="mobile"
                                name="mobile"
                                placeholder="Enter mobile"
                              />
                              <i className="fs-input-icon sl-icon-layers " />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 p-2 col-md-12">
                          <div className="form-group">
                            <label>
                              <i>Email</i>
                              <span className="req">*</span>
                            </label>
                            <div className="ls-inputicon-box">
                              <input
                                type="email"
                                autoComplete="off"
                                className="form-control"
                                // onChange={handleInputChange}
                                id="email"
                                name="email"
                                placeholder="Enter Email ID"
                                
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 p-2 col-md-12">
                          <div className="form-group">
                            <label>
                              <i>Location</i> <span className="req">*</span>
                            </label>
                            
                              <AutoComplete />
                          </div>
                        </div>

                        <div className="col-lg-6 p-2 col-md-12">
                          <div className="form-group">
                              <label>
                                  <i>State</i> <span className="req">*</span>
                              </label>
                              <div className="ls-inputicon-box">
                                  <select 
                                      className="form-control"
                                      id="state"
                                      name="state"
                                      // onChange={(e) => handleSelectChange_state(e)}
                                      onChange={(e) => {
                                        // handleInputChange(e);
                                        handleSelectChange_state(e);
                                      }}
                                  >
                                      <option value="">Select State</option>
                                      
                                      {statedata?.map((stateLi) => (
                                          <option key={stateLi.id} value={stateLi.id}>
                                              {stateLi.statename}
                                          </option>
                                      ))}
                                  </select>
                              </div>
                          </div>
                      </div>


                         <div className="col-lg-6 p-2 col-md-12">
                          <div className="form-group">
                            <label>
                              <i>District</i> <span className="req">*</span>
                             </label>
                             <div className="ls-inputicon-box">
                             
                               <select 
                                      className="form-control"
                                      id="district"
                                      name="district"
                                      // onChange={handleInputChange}
                                  >
                                     <option value="">Select district</option>
                                     {
                                 districtdata?.map((districtlist) => (
                                    <option key={districtlist.id} value={districtlist.id}>
                                      {districtlist.district_name}
                                    </option>
                                  ))
                                 } 
                                  </select>

                                  
                               <i className="fs-input-icon sl-icon-layers " />
                             </div>
                           </div>
                         </div>

                         <div className="col-lg-6 p-2 col-md-12">
                          <div className="form-group">
                            <label>
                              <i>City</i> <span className="req">*</span>
                             </label>
                             <div className="ls-inputicon-box">
                              <input
                                 className="form-control"
                                 id="city"
                                 name="city"
                                 placeholder="Enter city"
                                //  onChange={handleInputChange}
                               />
                                
                             </div>
                           </div>
                         </div>

                         <div className="col-lg-6 p-2 col-md-12">
                          <div className="form-group">
                            <label>
                              <i>Area</i> <span className="req">*</span>
                             </label>
                             <div className="ls-inputicon-box">
                              <input
                                 className="form-control"
                                 id="area"
                                 name="area"
                                 placeholder="Enter area"
                                //  onChange={handleInputChange}
                               />
                             </div>
                           </div>
                         </div>

                        <div className="col-lg-6 p-2 col-md-12">
                          <div className="form-group">
                            <label>
                              <i>GSTN</i>
                            </label>
                            <div className="ls-inputicon-box">
                              <input
                                type="text"
                                maxLength={15}
                                autoComplete="off"
                                className="form-control"
                                name="gstn"
                                id="gstn"
                                placeholder="Enter GSTN number"
                                // onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              <i>Description</i>
                            </label>
                            <textarea
                              // {...register("description", { required: true })}
                              className="form-control"
                              id="description"
                              rows={3}
                              // onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            <i></i>
                            <span className="req">*</span>
                          </label>
                          <div className="ls-inputicon-box">
                            <label className="checkbox-label">
                              <input
                                type="checkbox"
                                name="tandc"
                                checked={formData.tandc}
                                // onChange={handleInputChange}
                              />
                              <a
                                href="/terms_condition"
                                target="_blank"
                                spiketip-title="Read Terms and Conditions"
                                spiketip-pos="bottom"
                              >
                                {" "}
                                I agree with terms and conditions
                              </a>
                            </label>
                          </div>
                          {errors.tandc && (
                            <span className="error">{errors.tandc}</span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <button type="submit" className="btn btn-primary" >
                          Register
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}