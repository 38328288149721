import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import defUsericon from "../Components/assets/img/usericon_p.png";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { Helmet } from 'react-helmet';
import SiteLogo from "../Components/assets/img/logo.png";


import {
  getVendorProfilepage,
  // getVendorProfileEdit,
  // getVendorUpdate,
  vendorCreateregistration,
  paymentSuccess_vendor,
  getVendorGallery,
  vendorDeletePoke,
  getVendorGalleryDelete,
} from "../store/home";
import { updateServiceStatus, getState, getDistrict, getVendorProfileImage,
  getVendorProfileImgInsert, getVendorProfileImgDelete } from "../store/home";
import { vendorLogout } from "../store/auth";
import Swal from "sweetalert2";
import { nbbApi } from "../store/axios";
import NOSERVICEimage from "../Components/assets/img/NoGallery.jpg";
import { Card, Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import usericon from '../Components/assets/img/usericon_p.png';



const VendorProfile = () => {
  const dispatch = useDispatch();
  const [image, setImage] = useState(
    localStorage.getItem("uploadedImage") || null
  );
 
  const [imageError, setImageError] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isPanelOpen1, setIsPanelOpen1] = useState(false);
  const [isPanelOpen2, setIsPanelOpen2] = useState(false);
  const [imagedata, setimagedata] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [galleryPath, setGalleryPath] = useState([]);
  const [vendpokeList, setVendPokeList] = useState([]);
  const isVendorLoggedStatus = useSelector((state) => state.auth.isVendorLoggedIn);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const phone = localStorage.getItem("formDataVendorPhone");
  const [localProfileImage, setLocalProfileImage] = useState(null);
  const [profileImageContData, setProfileImageContData] = useState([]);
  const[ProfileImagePath,setProfileImagePath]=useState([]);


  const navigate = useNavigate();

  const handleClose = () => setShowModal(false);

  useEffect(() => {
    if (!isVendorLoggedStatus) {
      setShowLoginModal(true);
    } else {
      const phone = localStorage.getItem("formDataVendorPhone");
      dispatch(getVendorProfilepage(phone));
    }
  }, [dispatch, isVendorLoggedStatus]);

 
  const ven_pay_time = localStorage.getItem("vendor_payment_date_time");
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      firstName: "",
      business_name: "",
      service: "",
      category: "",
      email: "",
      location: "",
      mobile: "",
      aadhar_no: "",
      landmark: "",
      gstn: "",
      description: "",
      name: "",
      // checkbox: [],
      // radio: ''
    },
  });

  const handleMobileInputChange = (event) => {
    const input = event.target;
    input.value = input.value.replace(/[^0-9]/g, "");
  };
  const isValidEmail = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailPattern.test(email);
  };

  const onSubmit = (data) => {
    if (Object.keys(errors).length === 0) {
      console.log(data);
      // navigate("/register/uploadfile");
    }
  };

  useEffect(() => {
    if (image) {
    }
  }, [image]);

  const handleSubmitValue = () => {
    const newMobileInput = document.getElementById("newMobile");
    const previousMobileInput = document.getElementById("previousMobile");

    newMobileInput.addEventListener("input", function (event) {
      const inputValue = event.target.value.replace(/[^0-9]/g, "");

      event.target.value = inputValue;
    });

    previousMobileInput.addEventListener("input", function (event) {
      const inputValue = event.target.value.replace(/[^0-9]/g, "");

      event.target.value = inputValue;
    });

    // console.log("New Mobile Number:", newMobileInput.value);
    // console.log("Previous Mobile Number:", previousMobileInput.value);
  };

  const togglePanel1 = () => {
    setIsPanelOpen1(!isPanelOpen1);
  };

  const togglePanel2 = () => {
    setIsPanelOpen2(!isPanelOpen2);
  };

  const [isEditMode, setIsEditMode] = useState(false);
  const handleEditClick = () => {
    setIsEditMode(true);
  };
  const handleUpdateClick = () => {
    setIsEditMode(false);
  };

  // GALLERY UPLOAD START
  const [selectedImages, setSelectedImages] = useState([]);

  const handleVendorImageChange = (event) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const imagesArray = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );
      setSelectedImages((prevImages) => [...prevImages, ...imagesArray]);

      const allImages = [...selectedImages, ...imagesArray];
      const imagesJSON = JSON.stringify(allImages);
      localStorage.setItem("selectedImages", imagesJSON);
    }
  };

  // const handleImageUpload = () => {

  // };

  const removeVendorImage = (index) => {
    const updatedImages = [...selectedImages];
    URL.revokeObjectURL(selectedImages[index]); // Revoke object URL of the removed image
    updatedImages.splice(index, 1); // Remove the image at the specified index
    setSelectedImages(updatedImages);

    const imagesJSON = JSON.stringify(updatedImages);
    localStorage.setItem("selectedImages", imagesJSON);
  };
  const vendata = useSelector((state) => state.home.vendordata);
  // console.log("PRAMODINI", vendata?.payment_status);
  const venprofile = useSelector((state) => state.home.vendorProfileData);
  const venservice = useSelector((state) => state.home.vendorService);
  console.log("KalyaniVenDetails", venservice);
  const vengallery = useSelector((state) => state.home.VgalleryStatus);
  const [isChecked, setIsChecked] = useState(venservice.status === "1");
  // const [isChecked, setIsChecked] = useState(venservice.status);

  const handleServiceStatusChange = () => {
    const newStatus = isChecked ? "0" : "1";
    dispatch(updateServiceStatus(newStatus));
    setIsChecked(!isChecked);
  };

  // API END

  //STATE OPTION START
  useEffect(() => {
    dispatch(getState());
  }, []);

  const statedata = useSelector((state) => state.home.StateList);

  const handleSelectChange_state = (e) => {
    const selectedState = e.target.value;
    console.log("Selected state :", selectedState);
    dispatch(getDistrict(selectedState));
  };

  const { districtdata } = useSelector((state) => state.home);
  //STATE OPTION END

  //----------EDIT FUNCTIONALITY START-----------------
  const [isEditing, setIsEditing] = useState(false);
  const [location, setLocation] = useState(venprofile.user_address || "");
  const handleEditlocation = () => {
    setIsEditing(true);
  };
  // const handleEdit = () => {

  const handleSavelocation = () => {
    setIsEditing(false);
  };

  const [userLocation, setUserLocation] = useState("");

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          const reverseGeocodeUrl = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`;

          fetch(reverseGeocodeUrl)
            .then((response) => response.json())
            .then((data) => {
              if (data.display_name) {
                const locationName = data.display_name;
                setUserLocation(locationName);
                // setLocation(locationName);
              } else {
                console.error("No location name found in the response.");
              }
            })
            .catch((error) => {
              console.error("Error in reverse geocoding:", error);
            });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    if (isEditing) {
      getUserLocation(); // Get user location automatically when editing is enabled
    }
  }, [isEditing]);

  const [formEditData, setFormEditData] = useState({
    phone: "",
    name: "",
    email: "",
    businessName: "",
    landmark: "",
    state: "",
    city: "",
    area: "",
    district: "",
    gstn: "",
    profile: "",
  });

  useEffect(() => { }, [dispatch, formEditData]);
  // const vendataedit = useSelector((state) => state.home.VenProfileEditResponse);
  // console.log("vendataedit11", vendataedit);

  useEffect(() => {
    const storedFormData = localStorage.getItem("formData");
    if (storedFormData) {
      setFormEditData(JSON.parse(storedFormData));
    } else {
      setFormEditData({
        name: venprofile.name,
        email: venprofile.email,
        mobile: venprofile.user_phone,
        phone: venprofile.user_phone,
        cat_name: venservice.cat_name,
        service_id: venservice.sname,
        address: venservice.address,
        state: venservice.state,
        district: venservice.district_name,
        city: venservice.city,
        area: venservice.area,
        gstin: venservice.gstn,
        business_name: venservice.businessName,
      });
    }
    const phone = localStorage.getItem("formDataVendorPhone");
    dispatch(getVendorProfilepage(phone));

    return () => {
      localStorage.removeItem("formData");
    };
  }, [dispatch]);

  // useEffect(() => {
  //   const phone = localStorage.getItem("formDataVendorPhone");
  //   dispatch(getVendorProfilepage(phone));
  // }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormEditData({
      ...formEditData,
      [name]: value,
    });
  };

  // const handleVendorLogout = () => {
  //   Swal.fire({
  //     title: "Are you sure want to?",
  //     text: "LOG OUT!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#f1a228", 
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, Log Out!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       navigate("/");
  //       dispatch(vendorLogout());
  //       Swal.fire("Logged Out!", "You have been logged out.", "success");
  //     }
  //   });
  // };

  // const handleVendorLogout = () => {
  //   setShowModal(true);
  // };

  // const confirmLogout = () => {
  //   setShowModal(false);
  //   navigate("/");
  //   dispatch(vendorLogout());
  //   alert("Logged Out! You have been logged out.");
  // };

  const closeModal = () => {
    setShowModal(false);
  };
  

  // const imagehandler = (e) => {
  //   const file = e.target.files[0];
  //   const acceptedFormats = ["image/jpeg", "image/png"];
  //   setimagedata(e.target.files[0]);
  //   if (file) {
  //     if (acceptedFormats.includes(file.type)) {
  //       const reader = new FileReader();

  //       reader.onload = (e) => {
  //         setImagePreview(e.target.result);
  //       };

  //       reader.readAsDataURL(file);
  //       console.log("file...check", file);
  //       setFormEditData({
  //         ...formEditData,
  //         profile: file,
  //       });
  //       setImageError("");
  //     } else {
  //       setImageError(
  //         "Invalid image format. Please select a JPEG or PNG image."
  //       );
  //     }
  //   }
  // };
  const imagehandler = (event) => {
    const selectedImage = event.target.files[0];

    if (selectedImage) {
      setImage(selectedImage);

      // Preview the image
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    } else {
      setImage(null);
      setImagePreview(null);
    }
  };
  const handleUpdate = () => {
    console.log('Update button clicked');
  };
  //vendor profile Edit end
  /**PAYMENT GATEWAY INTEGRATION STARTS */

 

  const paymentStatus = useSelector((state) => state.home.paymentData); // Add this line


  const [razorpayScriptLoaded, setRazorpayScriptLoaded] = useState(false);
  const res = useSelector((state) => state.home.vendRegDetail);

  async function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        console.error("Error loading Razorpay SDK");
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const initializeRazorpay = async () => {
    if (!razorpayScriptLoaded) {
      const result = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      setRazorpayScriptLoaded(result);
    }
  };

  const displayRazorpay = async () => {
    await initializeRazorpay();

    // if (!razorpayScriptLoaded) {
    //   alert("Razorpay SDK failed to load. Are you online?");
    //   return;
    // }

    const vendorId = venprofile?.id;
    dispatch(vendorCreateregistration(vendorId));

    const mobile = res.mobile;
    const order_id = res.order_id;

    const options = {
      key: res.key,
      amount: res.amount,
      currency: "INR",
      name: "NewbharatBiz",
      description: "Vendor registration payment",
      order_id: res.order_id,
      handler: async function (response) {
        const transaction_id = response.razorpay_payment_id;
        const data = {
          transanction_id: response.razorpay_payment_id,
        };

        const formEditData = new FormData();
        formEditData.append("mobile", mobile);
        formEditData.append("order_id", order_id);
        formEditData.append("transaction_id", transaction_id);

        dispatch(paymentSuccess_vendor(formEditData));
      },
      prefill: {
        name: "Newbharatbiz",
        email: "pramodini.wayindia@gmail.com",
        contact: "9999999999",
      },
      notes: {
        address: "NewbharatBiz",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  useEffect(() => {
    if (paymentStatus === "0") {
      setShowModal(true);
    }
    else {
      setShowModal(false);
    }
  }, [paymentStatus]);
 
  useEffect(() => {
    if (paymentStatus === "0") {
      setShowModal(true);
    }
    else {
      setShowModal(false);
    }
  }, [paymentStatus]);
  //VENDOR POKE API START
  // useEffect(() => {
  //   dispatch(getVendorPokelist());
  // },[]);

  const vendorPoke = useSelector((state) => state.home.VendorPoke);
  // console.log('vendorPokekalyani', vendorPoke);
  //VENDOR POKE API END

  // Run the Razorpay logic when the component mounts
  // useEffect(() => {
  //   displayRazorpay();
  // }, []);
  /**PAYMENT GATEWAY INTEGRATION ENDS */

  //GALLERY START
  //  const id = useSelector(state => state.home.VendorGalleryId);
  // const vendorId = {VenGallery.vendor_id}
  const id = localStorage.getItem("vendor_id");
  // const id ='35072';
  // useEffect(() => {
  //     dispatch(getVendorGallery(id));
  // }, [dispatch, id]);

  const VenGallery = useSelector((state) => state.home.GalleryDataa);
  // console.log('VenGallerDataaaa', VenGallery);
  const VenGalleryresult = useSelector(
    (state) => state.home.VendorGalleryResult
  );
  console.log("VenGallerResulttt", VenGalleryresult?.id);
  // console.log('VenGallerPathhh', VenGallery.gallery_path);
  // console.log('VenGalleryyy', VenGalleryResult.file_name);

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedImage(file);

  //   const objectUrl = URL.createObjectURL(file);
  //   setImageUrl(objectUrl);
  // };

  const handleUploadClick = async () => {
    if (selectedImage) {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("image", selectedImage);

      try {
        const response = await nbbApi.post("/vendor_gallery_add", formData);
        if (response.data.status === 1) {
          Swal.fire("Success!", "Image added successfully.", "success");
          setSelectedImage(null);
          setImageUrl(null);
          fetchGallery();
        } else {
          Swal.fire("Error!", "You have already added 10 images.", "error");
        }
      } catch (error) {
        console.error("Error adding image:", error);
        Swal.fire(
          "Error!",
          "Failed to add the image. Please try again later.",
          "error"
        );
      }
    } else {
      console.error("No image selected");
    }
  };

  const VendGalAdd = useSelector((state) => state.home.VenGalleryAdd);
  console.log("ADDDDDDgallery:", VendGalAdd);

  const fetchGallery = useMemo(
    () => async () => {
      try {
        const formData = new FormData();
        formData.append("id", id);
        const response = await nbbApi.post("/vendor_gallery_fetch", formData);
        const data = response.data;
        if (data.status === 1) {
          setGalleryData(data.result); // Update the galleryData state with fetched images
          setGalleryPath(data.gallery_path);
        }
      } catch (error) {
        console.error("API request failed:", error);
      }
    },
    [id]
  );

  //DELETE GALLERY START
  const BenGaid = localStorage.getItem("vend_gall_img_id");
  const VenGalleryresultDelete = useSelector((state) => state.VenGalleryDelete);
  console.log("kalyaniDelete", VenGalleryresultDelete);

  const vendor_phone = localStorage.getItem("formDataVendorPhone");
  const fetchVendorPokeList = useMemo(
    () => async () => {
      try {
        const formData = new FormData();
        formData.append("phone", vendor_phone);
        const response = await nbbApi.post("/vendor_pokeList", formData);
        const data = response.data;

        if (data.status === 1) {
          setVendPokeList(data.result);
        }
      } catch (error) {
        console.error("API request failed:", error);
      }
    },
    [vendor_phone]
  );

  const handledeleteVendorPokeList = async (pokeid, pokename) => {
    try {
      dispatch(vendorDeletePoke(pokeid));
      setVendPokeList((prevPokeList) =>
        prevPokeList.filter((poke) => poke.id !== pokeid)
      );
      // Add a success notification
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: `Poke Deleted`,
      });
    } catch (error) {
      console.error("Delete poke request failed:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        Promise.all([fetchVendorPokeList()]);
      } catch (error) {
        console.error("API requests failed:", error);
      }
    };
    fetchData();
  }, [fetchVendorPokeList, vendor_phone]);

  const handleDeleteImg = async (imageId) => {
    try {
      const formData = new FormData();
      formData.append("id", imageId);
      const response = await nbbApi.post("/vendor_gallery_delete", formData);

      if (response.data.status === 1) {
        // Remove the deleted image from the galleryData
        const updatedGallery = galleryData.filter(
          (image) => image.id !== imageId
        );
        setGalleryData(updatedGallery);
        Swal.fire("Success!", "Image deleted successfully.", "success");
      } else {
        Swal.fire("Error!", "Failed to delete the image.", "error");
      }
    } catch (error) {
      console.error("Error deleting image:", error);
      Swal.fire(
        "Error!",
        "Failed to delete the image. Please try again later.",
        "error"
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        Promise.all([fetchGallery()]);
      } catch (error) {
        console.error("API requests failed:", error);
      }
    };
    fetchData();
  }, [fetchGallery, id]);

  //GALLERY END

  //VENDOR PROFILE UPDATE START
  // const vendor_phoneUpdate = localStorage.getItem("formDataVendorPhone");
  // useEffect(() => {
  //   dispatch(getVendorUpdate(vendor_phoneUpdate));
  // }, []);

  // const ProfileUpdate = useSelector((state) => state.home.VendorProfileUpdate);
  // console.log("ProfileUpdate", ProfileUpdate);

  // const handleVendorUpdateSave = () => {
  //   dispatch(getVendorUpdate(vendor_phoneUpdate));
  // };

  //VENDOR PROFILE UPDATE END

  //VENDOR PROFILE EDIT START

  // useEffect(() => {
  //   dispatch(getVendorProfileEdit(formEditData));
  // }, []);

  const vendataedit = useSelector((state) => state.home.VenProfileEditResponse);
  console.log("VENDOR DATA EDITTT", vendataedit);

  const handleEdit = () => {
    setIsEditing(true);
  };
  // const handleVendorEditSave = () => {
  //   dispatch(getVendorProfileEdit(formEditData));
  //   setIsEditing(false);
  // };

  // const handleVendorEditSave = () => {
  //   dispatch(getVendorProfileEdit(formEditData));
  // };
  // const vendor_phoneUpdate=localStorage.getItem("formDataVendorPhone")

  // const storedFormEditData = JSON.parse(localStorage.getItem("formEditData")) || initialFormEditData;
  // const storedFormEditData = JSON.parse(localStorage.getItem("formEditData"));
  // const [formEditData, setFormEditData] = useState(storedFormEditData);

  // useEffect(() => {
  //   Promise.all([
  //     dispatch(getVendorUpdate(vendor_phoneUpdate)),
  //     dispatch(getVendorProfileEdit(formEditData)),
  //   ]);
  // }, [dispatch, vendor_phoneUpdate, formEditData]);

  // const ProfileUpdate = useSelector(state => state.home.VendorProfileUpdate);
  // console.log('ProfileUpdate', ProfileUpdate);

  // const vendataedit = useSelector(state => state.home.VenProfileEditResponse);
  // console.log('VENDOR DATA EDITTT', vendataedit);

  // const handleVendorEditSave = () => {
  //   dispatch(getVendorProfileEdit(formEditData));
  // };

  // const handleEdit = () => {
  //   setIsEditing(true);
  // };

  // const handleSave = () => {
  //   setIsEditing(false);
  //   dispatch(getVendorUpdate(vendor_phoneUpdate));
  //   localStorage.setItem("formEditData", JSON.stringify(formEditData));
  // };

  // const handleActionClick = () => {
  //   if (isEditing) {
  //     handleSave();
  //   } else {
  //     handleEdit();
  //     handleVendorEditSave();
  //   }
  // };

  //VENDOR PROFILE EDIT END

  //VENDOR PROFILE GALLERY IMAGE COMPRESSOR START
  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    // Check if the file is an image
    if (file && file.type.startsWith("image/")) {
      // Check if the image size is greater than 500KB
      if (file.size > 500 * 1024) {
        // Resize and compress the image
        const compressedImage = await compressImage(file, 500); // 500KB

        setSelectedImage(compressedImage);

        // Display the selected image preview
        const objectUrl = URL.createObjectURL(compressedImage);
        setImageUrl(objectUrl);
      } else {
        // If the image is already smaller than 500KB, use the original image
        setSelectedImage(file);

        // Display the selected image preview
        const objectUrl = URL.createObjectURL(file);
        setImageUrl(objectUrl);
      }
    } else {
      // Handle non-image files
      console.error("Selected file is not an image");
    }
  };

  // Function to resize and compress the image
  const compressImage = async (file, maxSizeInKB) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Calculate the new dimensions to maintain the aspect ratio
          const maxWidth = 800; // Adjust this value based on your requirements
          const maxHeight = 600; // Adjust this value based on your requirements
          let newWidth = img.width;
          let newHeight = img.height;

          if (img.width > maxWidth) {
            newWidth = maxWidth;
            newHeight = (img.height * maxWidth) / img.width;
          }

          if (newHeight > maxHeight) {
            newHeight = maxHeight;
            newWidth = (img.width * maxHeight) / img.height;
          }

          // Set the canvas dimensions
          canvas.width = newWidth;
          canvas.height = newHeight;

          // Draw the image on the canvas
          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          // Convert the canvas content to base64-encoded data URL with specified quality
          canvas.toBlob(
            (blob) => {
              resolve(
                new File([blob], file.name, {
                  type: "image/jpeg",
                  lastModified: Date.now(),
                })
              );
            },
            "image/jpeg",
            0.5 // Adjust the quality (0.7 means 70% quality)
          );
        };
      };
    });
  };
  //VENDOR PROFILE GALLERY IMAGE COMPRESSOR END
  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    nbbApi.get("/service_price")
      .then(response => {
        console.log('Response:', response.data);

        setResponseData(response.data);

      })
      .catch(error => {
        console.error('Error:', error.message);
      });
  }, []);


 

  //VENDOR PROFILE IMAGE START

  const handleImageChangeprofile = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);

    // Display the selected image preview
    const reader = new FileReader();
    reader.onload = (e) => {
      setImageUrl(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleUploadClickprofile = async () => {
    if (selectedImage) {
      const formData = new FormData();
      const phone=localStorage.getItem("formDataVendorPhone")
      formData.append('phone', phone);
      formData.append('image', selectedImage);

      try {
        const response = await nbbApi.post('/vendor_profile_image_insert', formData);
        if (response.data.status === 1) {
          Swal.fire('Success!', 'Profile image added successfully.', 'success');
          setSelectedImage(null);
          setImageUrl(null);
          fetchProfileImages();
        } else {
          Swal.fire('Error!', 'Failed to add profile image.', 'error');
        }
      } catch (error) {
        console.error('Error adding profile image:', error);
        Swal.fire('Error!', 'Failed to add profile image. Please try again later.', 'error');
      }
    } else {
      console.error('No image selected');
    }
  };

  const fetchProfileImages = useMemo(
    () => async () => {
      try {
        const formData = new FormData();
        const phone = localStorage.getItem("formDataVendorPhone")
        formData.append('phone', phone);
        const response = await nbbApi.post('/vendor_profile_image_fetch', formData);
        const data = response.data;
        if (data.status === 1) {
          setProfileImagePath(data.image_path);

          setProfileImageContData(data.result);
        }
      } catch (error) {
        console.error('API request failed:', error);
      }
    },
    []
  );

  const handleDeleteImgprofile = async (imageId) => {
    try {
      const formData = new FormData();
      formData.append('id', imageId);
      const response = await nbbApi.post('/vendor_profile_image_delete', formData);

      if (response.data.status === 1) {
        // Remove the deleted image from profileImageContData
        const updatedProfileImages = profileImageContData.filter(
          (image) => image.id !== imageId
        );
        setProfileImageContData(updatedProfileImages);
        Swal.fire('Success!', 'Profile image deleted successfully.', 'success');
      } else {
        Swal.fire('Error!', 'Failed to delete profile image.', 'error');
      }
    } catch (error) {
      console.error('Error deleting profile image:', error);
      Swal.fire('Error!', 'Failed to delete profile image. Please try again later.', 'error');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchProfileImages();
      } catch (error) {
        console.error('API request failed:', error);
      }
    };
    fetchData();
  }, [fetchProfileImages]);

  //VENDOR PROFILE IMAGE END


  const siteUrl = window.location.href;

  
  return (
    <div className="page-wraper" style={{ marginTop: "10%" }}>
      {isVendorLoggedStatus ? (
        <div className="page-content3">
          {paymentStatus === "1" ? (
            <div className="container col-md-6 col-md-12 pb-5 register">
              <main>
                <div className=" dashboarddesgn inner-breadcrumb">
                  <div className="container">
                    <div
                      className="breadcrumb-head"
                      style={{ display: "flex" }}
                    >
                      <div className="col-md-11">
                        <h3 className="dashboardhead">Dashboard</h3>
                        <Helmet>
                          <meta charSet="utf-8" />
                          <title>"New Bharat Biz - Get Expert Professional Services near by you."</title>
                          <meta name="description" content="New Bharat Biz: Connecting Small Entrepreneurs, Get connected to business owners around you and build a Biz network, business listing, New Bharat Biz mobile app help you grow." />
                          <meta property="og:type" content="website" />
                          <meta property="og:url" content={siteUrl} />
                          <meta property="og:title" content="Connecting 40,000+ Small and Micro Service Providers" />
                          <meta property="og:description" content="New Bharat Biz: Connecting Small Entrepreneurs, Get connected to business owners around you and build a Biz network, business listing, New Bharat Biz mobile app help you grow." />
                          <meta property="og:image" content={SiteLogo} />
                        </Helmet>
                      </div>
                      <div className="col-md-1">
                        <Link to="/">
                          <i class="fa fa-home homeicon"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <section className="single-wrapper section-wrapper">
                  <div className="container">
                    <div className="flexTab d-flex tabpaneldesgn">
                      <div className="col-md-2" style={{ margin: "10px" }}>
                        <ul
                          className="nav nav-tabs d-lg-flex flex-column"
                          id="actionTab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="dashboard-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#dashboard-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="dashboard-tab-pane"
                              aria-selected="true"
                            >
                              <i className="ri-dashboard-line" /> Profile
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link "
                              id="service-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#service-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="service-tab-pane"
                              aria-selected="false"
                            >
                              <i className="ri-service-line" /> Service
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="account-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#account-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="account-tab-pane"
                              aria-selected="false"
                            >
                              <i className="ri-account-circle-line" /> Account
                              Details
                            </button>
                          </li>

                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="poke-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#poke-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="poke-tab-pane"
                              aria-selected="false"
                            >
                              <i className="ri-map-pin-user-line" /> Poke
                              Listing
                            </button>
                          </li>

                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="gallery-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#gallery-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="gallery-tab-pane"
                              aria-selected="false"
                            >
                              <i className="ri-map-pin-user-line" /> Gallery
                            </button>
                          </li>

                          {/* <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="logout-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#logout-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="logout-tab-pane"
                              aria-selected="false"
                              onClick={handleVendorLogout}
                              
                            >
                              <i className="ri-map-pin-user-line" /> Logout
                            </button>
                          </li> */}
                        </ul>
                      </div>

                      {/* -----------------DASHBOARD ACTION START ---------------------*/}
                      <div className="col-md-9">
                        <div
                          className="tab-content accordion"
                          id="dashboardAction"
                        >
                          <div
                            className="tab-pane fade show active accordion-item"
                            id="dashboard-tab-pane"
                            role="tabpanel"
                            aria-labelledby="dashboard-tab"
                            tabIndex={0}
                            style={{ border: "1px solid #e5e5e5" }}
                          >
                            <h2
                              className="accordion-header d-lg-none"
                              id="headingOne"
                            >
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#dashboard"
                                aria-expanded="true"
                                aria-controls="dashboard"
                              >
                                <i className="ri-dashboard-line" /> Profile
                              </button>
                            </h2>
                            <div
                              id="dashboard"
                              className="accordion-collapse collapse show d-lg-block"
                              aria-labelledby="dashboard"
                              data-bs-parent="#dashboardAction"
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  {venprofile.payment_status === "1" ? (
                                    <div id="unpaid" className="col-md-6">
                                      <div
                                        className="card-dash bg-orange p-3"
                                        style={{
                                          backgroundColor: "#f1a228",
                                          cursor: "pointer",
                                        }}
                                        onClick={togglePanel1}
                                      >
                                        <h5 className="mb-0 text-white">
                                          PROFILE
                                        </h5>
                                      </div>
                                      <div className="col-md-12 mt-3">
                                        <div
                                          className="panel panel-info"
                                          id="panelborder"
                                        >
                                          <div className="panel-heading">
                                            <div>
                                              <i
                                                className="fa fa-user"
                                                style={{ margin: "5px" }}
                                              />{" "}
                                              &nbsp;
                                              {venprofile.name}
                                            </div>
                                          </div>
                                          <div className="panel-body">
                                            <h3>
                                              Pay ₹236 to avail the service
                                            </h3>
                                            <button className="btn btn-warning">
                                              Payment
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div id="paid" className="col-md-12">
                                      <div
                                        className="card-dash bg-orange p-3"
                                        style={{
                                          backgroundColor: "#f1a228",
                                          cursor: "pointer",
                                        }}
                                        onClick={togglePanel1}
                                      >
                                        <h5 className="mb-0 text-white">
                                          Profile
                                        </h5>
                                      </div>
                                      <div className="col-md-12 mt-3">
                                        <div
                                          className="panel panel-info"
                                          id="panelborder"
                                        >
                                          <div className="panel-heading">
                                            <div className="col-md-9">
                                              <i
                                                className="fa fa-user"
                                                style={{ margin: "5px" }}
                                              />{" "}
                                              &nbsp;
                                              {venprofile.name}
                                              {/* Kalyani Sahoo */}
                                            </div>
                                            {/* <div
                                              className="col-md-3"
                                              style={{
                                                float: "right",
                                                display: "flex",
                                              }}
                                            >
                                              <div style={{ margin: "auto" }}>
                                                Availability
                                              </div>
                                              <div className="form-check form-switch">
                                                <input
                                                  style={{
                                                    width: "60px",
                                                    height: "25px",
                                                  }}
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  role="switch"
                                                  id="flexSwitchCheckChecked"
                                                  checked={isChecked}
                                                  onChange={
                                                    handleServiceStatusChange
                                                  }
                                                />
                                              </div>
                                            </div> */}
                                          </div>
                                          <div className="panel-body">
                                            <h3 style={{ textAlign: "center" }}>
                                              Welcome to Dashboard
                                            </h3>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* ----------------SERVICE DETAILS START ------------------*/}

                          <div
                            className="tab-pane fade show accordion-item"
                            id="service-tab-pane"
                            role="tabpanel"
                            aria-labelledby="service-tab"
                            tabIndex={0}
                            style={{ border: "1px solid #e5e5e5" }}
                          >
                            <h2
                              className="accordion-header d-lg-none"
                              id="headingOne"
                            >
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#service"
                                aria-expanded="true"
                                aria-controls="service"
                              >
                                <i className="ri-dashboard-line" /> Service
                              </button>
                            </h2>
                            <div
                              id="dashboard"
                              className="accordion-collapse collapse show d-lg-block"
                              aria-labelledby="service"
                              data-bs-parent="#serviceAction"
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div
                                      className="card-dash bg-orange p-3"
                                      style={{
                                        backgroundColor: "#f1a228",
                                        // cursor: "pointer",
                                      }}
                                      // onClick={togglePanel1}
                                    >
                                      <h5 className="mb-0 text-white">
                                        Service
                                      </h5>
                                    </div>
                                    {/* {isPanelOpen1 && ( */}
                                    <div className="col-md-12 mt-3">
                                        <>
                                          {responseData && (
                                          <div>
                                            <div className="col-md-12" style={{ padding: '5px', marginBottom: '10px', border: '1px solid #bce8f1' }}>
                                              {/* <div className="green-check">
                                              &#10004;
                                            </div> */}
                                              <i class="fa fa-check" style={{ "font-size": "24px", color: 'green' }}></i>

                                                  You have paid ₹
                                              {
                                                responseData
                                                  .vendor_registration_price
                                                  .final_price
                                              }{" "}
                                              on <span>{ven_pay_time}</span>

                                            </div>
                                          </div>
                                        )}
                                          <div
                                            className="panel panel-info"
                                            id="panelborder"
                                          >
                                            <div className="panel-heading m-1">
                                              <i
                                                className="fa fa-user"
                                                style={{ margin: "5px" }}
                                              />
                                              &nbsp;
                                              {vendata.user_name}
                                            </div>

                                            <div className="panel-body p-2">
                                              <p style={{ marginBottom: 8 }}>
                                                {/* {venprofile.service[0]?.sname} */}
                                              </p>

                                              <p style={{ marginBottom: 8 }}>
                                                <i className="fa fa-map-marker" />{" "}
                                                {venservice.address}
                                              </p>
                                            </div>
                                          </div>
                                        </>
                                     
                                    </div>

                                    {/* )} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* ----------------SERVICE DETAILS START ------------------*/}

                          {/* ----------------ACCOUNT DETAILS START ------------------*/}

                          <div
                            className="tab-pane fade show accordion-item"
                            id="account-tab-pane"
                            role="tabpanel"
                            aria-labelledby="account-tab"
                            tabIndex={0}
                            style={{ border: "1px solid #e5e5e5" }}
                          >
                            <h2
                              className="accordion-header d-lg-none"
                              id="headingTwo"
                            >
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#accountAc"
                                aria-expanded="true"
                                aria-controls="accountAc"
                              >
                                <i className="ri-account-circle-line" /> Account
                                Details
                              </button>
                            </h2>
                            <div
                              id="accountAc"
                              className="accordion-collapse collapse show d-lg-block"
                              aria-labelledby="headingTwo"
                              data-bs-parent="#myTabContent"
                            >
                              <div className="accordion-body">
                                {/* <div
                                  className="col-md-4 profile position-relative"
                                  style={{ textAlign: "center" }}
                                >
                                  <div className="avatar"> */}
                                   
                                  <div className="container">
                                <div className="row">
                                  <div className="col-md-12">
                                    {profileImageContData.length === 0 ? (
                                      <div className="no-services-message">
                                        <p>Sorry, no profile images found. </p>
                                        <img
                                          src={defUsericon}
                                          alt="No Profile Images Icon"
                                          style={{ height: '200px' }}
                                        />
                                        <div className="upload">
                                          <input
                                            type="file"
                                            accept="image/png"
                                            onChange={handleImageChangeprofile}
                                          />
                                          {imageUrl && (
                                            <img
                                              src={imageUrl}
                                              alt="Uploaded Preview"
                                              style={{
                                                width: 'auto',
                                                height: '200px',
                                                marginTop: '10px',
                                              }}
                                            />
                                          )}
                                          <button onClick={handleUploadClickprofile}>Upload Profile Image</button>
                                        </div>
                                      </div>
                                    ) : (
                                      <div>
                                        {/* <div className="row row-cols-1 row-cols-md-3 g-4"> */}
                                        <div className="row">
                                          {profileImageContData.map((image) => (
                                            <div className="col" key={image.id}>
                                              <div className="card image-fluid" key={image.id}  style={{"width": "15rem"}}>
                                                <img
                                                  src={`${ProfileImagePath}${image.image}`}
                                                  className="card-img-top"
                                                  alt="profileimg"
                                                  style={{
                                                    height: '200px',
                                                    width: '100%',
                                                  }}
                                                />
                                                <div className="fadesign">
                                                  <button
                                                    onClick={() => handleDeleteImgprofile(image.id)}
                                                    style={{
                                                      color: 'red',
                                                    }}
                                                  >
                                                    <i
                                                      className="fa fa-trash-o"
                                                      style={{
                                                        color: 'red',
                                                      }}
                                                    ></i>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>

                                        {imageUrl ? (
                                          // If an image is uploaded, show the uploaded image and provide delete option
                                          <div>
                                            <img
                                              src={imageUrl}
                                              alt="Uploaded Preview"
                                              style={{
                                                width: 'auto',
                                                height: '200px',
                                                marginTop: '10px',
                                              }}
                                            />
                                            <button onClick={() => handleDeleteImgprofile(profileImageContData[0].id)}>
                                              Delete Uploaded Image
                                            </button>
                                          </div>
                                        ) : (
                                          // If no image is uploaded, show the file input for uploading
                                          <div className="upload">
                                            <input
                                              type="file"
                                              accept="image/png"
                                              onChange={handleImageChangeprofile}
                                            />
                                            {imageUrl && (
                                              <img
                                                src={imageUrl}
                                                alt="Uploaded Preview"
                                                style={{
                                                  width: 'auto',
                                                  height: '200px',
                                                  marginTop: '10px',
                                                }}
                                              />
                                            )}
                                            <button onClick={handleUploadClickprofile}>Upload Profile Image</button>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                                  {/* </div>
                                </div> */}
                                {/* end */}
                                {/* details form */}
                                <div className="row">
                                  <div className="col-lg-4 p-2 col-md-12">
                                    <div className="form-group">
                                      <label>Name</label>
                                      <div className="ls-inputicon-box">
                                        <input
                                          className="form-control"
                                          id="firstName"
                                          name="firstName"
                                          placeholder={venprofile.name}
                                          readOnly
                                          value={formEditData.name}
                                          onChange={(e) =>
                                            setFormEditData({
                                              ...formEditData,
                                              name: e.target.value,
                                            })
                                          }
                                        />
                                        <i className="fs-input-icon sl-icon-user " />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4 p-2 col-md-12">
                                    <div className="form-group">
                                      <label>Email</label>
                                      <div className="ls-inputicon-box">
                                        <input
                                          type="email"
                                          autoComplete="off"
                                          className="form-control"
                                          // onChange={isValidEmail}
                                          id="email"
                                          name="email"
                                          placeholder={venprofile.email}
                                          readOnly
                                          value={formEditData.email}
                                          onChange={(e) =>
                                            setFormEditData({
                                              ...formEditData,
                                              email: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-2 col-md-12">
                                    <div className="form-group">
                                      <label>Phone</label>
                                      <div className="ls-inputicon-box">
                                        <input
                                          className="form-control"
                                          // onChange={handleMobileInputChange}
                                          maxLength={10}
                                          id="mobile"
                                          name="mobile"
                                          placeholder={venprofile.user_phone}
                                          readOnly
                                          value={formEditData.user_phone}
                                          onChange={(e) =>
                                            setFormEditData({
                                              ...formEditData,
                                              user_phone: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-2 col-md-12">
                                    <div className="form-group">
                                      <label>Organization Name</label>
                                      <div className="ls-inputicon-box">
                                        <input
                                          className="form-control"
                                          id="name"
                                          name="name"
                                          placeholder={venservice.name}
                                          readOnly
                                          value={formEditData.name}
                                          onChange={(e) =>
                                            setFormEditData({
                                              ...formEditData,
                                              name: e.target.value,
                                            })
                                          }
                                        />
                                        <i className="fs-input-icon sl-icon-layers " />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-2 col-md-12">
                                    <div className="form-group">
                                      <label>Category Name</label>
                                      <div className="ls-inputicon-box">
                                        <input
                                          className="form-control"
                                          id="category"
                                          name="category"
                                          placeholder={venservice.cat_name}
                                          readOnly
                                          value={formEditData.cat_name}
                                          onChange={(e) =>
                                            setFormEditData({
                                              ...formEditData,
                                              cat_name: e.target.value,
                                            })
                                          }
                                        />
                                        <i className="fs-input-icon sl-icon-user " />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-2 col-md-12">
                                    <div className="form-group">
                                      <label>Service Name</label>
                                      <div className="ls-inputicon-box">
                                        <input
                                          className="form-control"
                                          id="service"
                                          name="service"
                                          placeholder={venservice.sname}
                                          readOnly
                                          value={formEditData.service}
                                          onChange={(e) =>
                                            setFormEditData({
                                              ...formEditData,
                                              service: e.target.value,
                                            })
                                          }
                                        />
                                        <i className="fs-input-icon sl-icon-user " />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-2 col-md-12">
                                    <div className="form-group ls-inputicon-box">
                                      <label>Location</label>
                                      <div className="input-group">
                                        <input
                                          readOnly
                                          className="form-control"
                                          id="location"
                                          name="location"
                                          placeholder={venservice.address}
                                          value={location}
                                          onChange={(e) =>
                                            setLocation(e.target.value)
                                          }
                                          disabled={!isEditing}
                                        />
                                        {/* <span
                                      className="input-group-text"
                                      onClick={handleEditlocation}
                                    >
                                      <i
                                        className="fas fa-map-marker-alt"
                                        style={{ color: "red" }}
                                      />
                                    </span> */}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4 p-2 col-md-12">
                                    <div className="form-group">
                                      <label> State </label>
                                      {/* <div className="ls-inputicon-box">
                                    <select
                                      className="form-control"
                                      id="state"
                                      name="state"
                                      placeholder={venservice.state}
                                      value={vendataedit.state}
                                      onChange={(e) => {
                                        setFormEditData({
                                          ...vendataedit,
                                          state: e.target.value,
                                        });
                                        handleInputChange(e);
                                        handleSelectChange_state(e);
                                      }}
                                    >
                                      <option>{venservice.state}</option>

                                      {statedata?.map((stateLi) => (
                                        <option
                                          key={stateLi.id}
                                          value={stateLi.id}
                                        >
                                          {stateLi.statename}
                                        </option>
                                      ))}
                                    </select>
                                  </div> */}

                                      <div className="ls-inputicon-box">
                                        <input
                                          readOnly
                                          className="form-control"
                                          id="state"
                                          name="state"
                                          placeholder={venservice.state}
                                          value={formEditData.state}
                                          onChange={(e) =>
                                            setFormEditData({
                                              ...formEditData,
                                              state: e.target.value,
                                            })
                                          }
                                          disabled={!isEditing}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-2 col-md-12">
                                    <div className="form-group">
                                      <label>District</label>
                                      {/* <div className="ls-inputicon-box">
                                    <select
                                      className="form-control"
                                      id="district"
                                      name="district"
                                      onChange={(e) => {
                                        setFormEditData({
                                          ...vendataedit,
                                          state: e.target.value,
                                        });
                                        handleInputChange(e);
                                      }}
                                    >
                                      <option>
                                        {venservice.district_name}
                                      </option>
                                      {districtdata?.map((districtlist) => (
                                        <option
                                          key={districtlist.id}
                                          value={districtlist.id}
                                        >
                                          {districtlist.district_name}
                                        </option>
                                      ))}
                                    </select>
                                  </div> */}
                                      <div className="ls-inputicon-box">
                                        <input
                                          readOnly
                                          className="form-control"
                                          id="district"
                                          name="district"
                                          placeholder={venservice.district_name}
                                          value={formEditData.district_name}
                                          onChange={(e) =>
                                            setFormEditData({
                                              ...formEditData,
                                              district: e.target.value,
                                            })
                                          }
                                          disabled={!isEditing}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-2 col-md-12">
                                    <div className="form-group">
                                      <label>City</label>
                                      <div className="ls-inputicon-box">
                                        <input
                                          readOnly
                                          className="form-control"
                                          id="city"
                                          name="city"
                                          placeholder={venservice.city}
                                          value={vendataedit.city}
                                          onChange={(e) =>
                                            setFormEditData({
                                              ...vendataedit,
                                              city: e.target.value,
                                            })
                                          }
                                          disabled={!isEditing}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-2 col-md-12">
                                    <div className="form-group">
                                      <label>Area</label>
                                      <div className="ls-inputicon-box">
                                        <input
                                          readOnly
                                          className="form-control"
                                          id="area"
                                          name="area"
                                          placeholder={venservice.area}
                                          value={vendataedit.area}
                                          onChange={(e) =>
                                            setFormEditData({
                                              ...vendataedit,
                                              area: e.target.value,
                                            })
                                          }
                                          disabled={!isEditing}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-2 col-md-12">
                                    <div className="form-group">
                                      <label>GSTN</label>
                                      <div className="ls-inputicon-box">
                                        <input
                                          readOnly
                                          className="form-control"
                                          id="gstn"
                                          name="gstn"
                                          placeholder={venservice.gstn}
                                          value={vendataedit.gstn}
                                          onChange={(e) =>
                                            setFormEditData({
                                              ...vendataedit,
                                              gstn: e.target.value,
                                            })
                                          }
                                          disabled={!isEditing}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {/*  <div className="col-lg-4 p-2 col-md-12">
                                {isEditing ? (
                                  <div className="col-lg-4 p-2 col-md-12">
                                    <button
                                      className="btn btn-primary mt-2"
                                      onClick={handleVendorEditSave}
                                    >
                                      Save
                                    </button>
                                  </div>
                                ) : (
                                  <div className="col-lg-4 p-2 col-md-12">
                                    <button
                                      className="btn btn-secondary mt-2"
                                      onClick={handleEdit}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                )}
                              </div> */}
                                  {/* <div className="col-lg-4 p-2 col-md-12">
                                    <button
                                      className="btn btn-secondary mt-2"
                                      onClick={handleActionClick}
                                    >
                                      {isEditing ? "Save" : "Edit"}
                                    </button>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* ----------------ACCOUNT DETAILS END ------------------*/}

                          {/* ----------------POKE DETAILS START ------------------*/}

                          <div
                            className="tab-pane fade show accordion-item"
                            id="poke-tab-pane"
                            role="tabpanel"
                            aria-labelledby="poke-tab"
                            tabIndex={0}
                            style={{ border: "1px solid #e5e5e5" }}
                          >
                            <h2
                              className="accordion-header d-lg-none"
                              id="poke"
                            >
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#poke"
                                aria-expanded="false"
                                aria-controls="poke"
                              >
                                <i className="ri-map-pin-user-line" /> POKE
                              </button>
                            </h2>
                            <div
                              id="poke"
                              className="accordion-collapse collapse show d-lg-block"
                              aria-labelledby="poke"
                              data-bs-parent="#poke"
                            >
                              <div className="accordion-body">
                                <div className="row">
                                 <div className="row">
                                  <div className="col">
                                    <div
                                      className="card card-dash bg-orange p-3"
                                      style={{
                                        backgroundColor: "rgb(241, 162, 40)",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <h5 className="mb-0 text-white">
                                        Poke Listing
                                      </h5>
                                    </div>
                                    </div>
                                    </div>
                                    <div className="row  row-cols-1 row-cols-md-3 g-4">
                                      {vendpokeList.length === 0 ? (
                                        <div className="col" >
                                         <div className=" card h-100">
                                          <div className="card-body no-services-message">
                                            <p className="card-text">
                                              Oops you dont have any poke
                                            </p>
                                          </div>
                                         </div>
                                        </div>
                                      ) : (
                                        vendpokeList.map((poke) => (
                                          <div className="col" style={{display: 'flex', columnGap: '10px'}}>
                                           <div className="card h-100" key={poke.id}>
                                           <div className="card-body">
                                           <h5 className="card-title"
                                            style={{
                                              backgroundColor: "#d9edf7",
                                              padding: "10px",
                                            }}
                                          >
                                            {poke.sername}
                                          </h5>
                                            <p className="card-text">
                                                <p>Provider Name: {poke.pname}</p>
                                                <p>Address: {poke.paddress}</p>
                                                <p>Date: {poke.date}</p>
                                                <p>Email: {poke.emaildta}</p>
                                                <p>Phone: {poke.userPhone}</p>
                                            </p>
                                            </div>
                                            <div className="card-footer">
                                              <small className="text-body-secondary">
                                              <Button
                                                //   variant="primary"
                                                onClick={() =>
                                                  handledeleteVendorPokeList(
                                                    poke.id
                                                  )
                                                }
                                              >
                                                <i
                                                  className="fa fa-trash-o"
                                                  style={{
                                                    fontSize: 20,
                                                    color: "red",
                                                  }}
                                                />
                                                &nbsp; Remove Poke
                                              </Button>
                                              </small>
                                              </div>
                                          </div>
                                          </div>
                                        ))
                                      )}
                                    {/* </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ----------------POKE DETAILS END ------------------*/}

                          {/* ----------------GALLERY DETAILS START ------------------*/}

                          <div
                            className="tab-pane fade accordion-item"
                            id="gallery-tab-pane"
                            role="tabpanel"
                            aria-labelledby="gallery-tab"
                            tabIndex={0}
                            style={{ border: "1px solid #e5e5e5" }}
                          >
                            <h2
                              className="accordion-header d-lg-none"
                              id="gallery"
                            >
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#gallery"
                                aria-expanded="false"
                                aria-controls="gallery"
                              >
                                <i className="ri-map-pin-user-line" /> Gallery
                              </button>
                            </h2>
                            <div
                              id="gallery"
                              className="accordion-collapse collapse show d-lg-block"
                              aria-labelledby="gallery"
                              data-bs-parent="#gallery"
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div
                                      className="card-dash bg-orange p-3"
                                      style={{
                                        backgroundColor: "#f1a228",
                                        cursor: "pointer",
                                      }}
                                      onClick={togglePanel1}
                                    >
                                      <h5 className="mb-0 text-white">
                                        Gallery
                                      </h5>
                                    </div>
                                    {/* {isPanelOpen1 && ( */}
                                    <div className="col-md-12 mt-3">
                                      <div
                                        className="panel panel-info"
                                        id="panelborder"
                                      >
                                        <div className="panel-heading">
                                          <i
                                            className="fa fa-user"
                                            style={{ margin: "5px" }}
                                          />{" "}
                                          &nbsp;
                                          {venprofile.name}
                                        </div>
                                        <div className="panel-body">
                                          <div className="container">
                                            <h4>Upload image here</h4>
                                            {galleryData.length === 0 ? (
                                              <div className="col-md-12">
                                                <div className="no-services-message">
                                                  <p>
                                                    Sorry, no images found.{" "}
                                                  </p>

                                                  <img
                                                    src={NOSERVICEimage}
                                                    alt="No Services Icon"
                                                    style={{ height: "200px" }}
                                                  />
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="row row-cols-1 row-cols-md-3 g-4">
                                                {galleryData.map((image) => (
                                                  <div
                                                    className="col"
                                                    key={image.id}
                                                  >
                                                    <div className="card">
                                                      <img
                                                        src={
                                                          galleryPath +
                                                          image.file_name
                                                        }
                                                        className="card-img-top"
                                                        alt="galleryimg"
                                                        style={{
                                                          height: "200px",
                                                          width: "100%",
                                                        }}
                                                      />
                                                      <div className="fadesign">
                                                        <button
                                                          onClick={() =>
                                                            handleDeleteImg(
                                                              image.id
                                                            )
                                                          }
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        >
                                                          <i
                                                            className="fa fa-trash-o"
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          ></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            )}
                                            <div className="upload">
                                              <input
                                                type="file"
                                                accept="image/png"
                                                onChange={handleImageChange}
                                              />
                                            </div>
                                            {imageUrl && (
                                              <img
                                                src={imageUrl}
                                                alt="Uploaded Preview"
                                                style={{
                                                  width: "auto",
                                                  height: "200px",
                                                  marginTop: "10px",
                                                }}
                                              />
                                            )}
                                            <button onClick={handleUploadClick}>
                                              Upload Images
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* )} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ----------------GALLERY DETAILS END ------------------*/}
                          {/* ----------------LOGOUT DETAILS START ------------------*/}
                       
                          {/* <div
                            className="modal fade"
                            id="exampleModal"
                            tabIndex={-1}
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="exampleModalLabel"
                                  >
                                    LOGOUT
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  />
                                </div>
                                <div className="modal-body">
                                  Are you sure you want to logout?
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                  >
                                    Logout
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div> */}
{/* <div
                            className="modal fade"
                            id="exampleModal"
                            tabIndex={-1}
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="exampleModalLabel"
                                  >
                                    LOGOUT
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  />
                                </div>
                                <div className="modal-body">
                                  Are you sure you want to logout?
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                  >
                                    Logout
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          {/* ----------------LOGOUT DETAILS END ------------------*/}
                        </div>
                      </div>

                      {/* -----------------DASHBOARD ACTION END ---------------------*/}
                    </div>
                  </div>
                </section>
                <hr />
              </main>
            </div>
          ) : (
            <Modal show={showModal} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Payment Information</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Pay Rs 200 + 18% GST = Rs 236 to activate your service</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={displayRazorpay}>
                  Pay Now
                </Button>
              </Modal.Footer>
            </Modal>
          )} 
        </div>
      ) : (
        <Modal show={showLoginModal} onHide={() => setShowLoginModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Login Required</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Please log in to view your profile.</p>
            {/* Add login form or redirect to the login page */}
            <Button onClick={() => navigate("/login")}>Go to Login Page</Button>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowLoginModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default VendorProfile;