import React, { useRef, useState, useEffect, Component } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import PulseLoader from "react-spinners/PulseLoader";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import img1 from '../Components/assets/img/hero-bg.jpg';
import img2 from '../Components/assets/img/Electrician.png';
import img3 from '../Components/assets/img/Doctors.png';
import img4 from '../Components/assets/img/Decorer.png';
import { Carousel } from 'react-bootstrap';
// import ExampleCarouselImage from '../Components/assets/img/hero-bg.jpg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import MagicSliderDots from 'react-magic-slider-dots';
// import 'react-magic-slider-dots/dist/magic-dots.css';
import gifchoose from '../Components/assets/img/shipping.gif';
import location from '../Components/assets/img/location.gif';
import owner from '../Components/assets/img/owner.gif';
import Agriculturist from '../Components/assets/img/Feature/Agricultur.png';
import Architect from '../Components/assets/img/Feature/Architect.png';
import Astrologer from '../Components/assets/img/Feature/Astrologer.png';
import Auto from '../Components/assets/img/Feature/Auto.png';
import Barber from '../Components/assets/img/Feature/Barber.png';
import Bike from '../Components/assets/img/Feature/Bike.png';
import Book from '../Components/assets/img/Feature/Book.png';
import Brahmin from '../Components/assets/img/Feature/Brahmin.png';
import Agriculture from "../Components/assets/img/TopServices/Agriculture.jpg";
import Beauty from "../Components/assets/img/TopServices/Beauty.jpg";
import BusinessService from "../Components/assets/img/TopServices/Business service.jpg";
import Education from "../Components/assets/img/TopServices/Education.jpg";
import Telismith from "../Components/assets/img/TopServices/Telismith.jpg";
import Doctor from "../Components/assets/img/TopServices/Doctor.jpg";
import Wedding from "../Components/assets/img/TopServices/Wedding & Events.jpg";
import Food from "../Components/assets/img/TopServices/Food.jpg";
import Health from "../Components/assets/img/TopServices/Health.jpg";
import TravelService from "../Components/assets/img/TopServices/Travel service.jpg";
import Finance from "../Components/assets/img/TopServices/Finance.jpg";
import MedicineStore from "../Components/assets/img/TopServices/MedicineStore.jpg";
import Production from "../Components/assets/img/TopServices/Product & Manufacturing.jpg";
import Household from "../Components/assets/img/TopServices/HouseholdServices.jpg";
import img from "../Components/assets/img/Finance.jpg";
import { useDispatch, useSelector } from "react-redux";
import {getTopServicesCategory} from "../store/home";
import { Helmet } from 'react-helmet';
import SiteLogo from "../Components/assets/img/logo.png";



const Services = () => {
  const dispatch = useDispatch();
  const [loaderStatus, setLoaderStatus] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoaderStatus(false);
    }, 2000); // Set loaderStatus to false after 3 seconds (adjust as needed)
  }, []);

  // const settings = {
  //   dots: false,
  //   arrows: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true, 
  //   autoplaySpeed: 2000,
  // };

  const settings = {
    effect: 'coverflow',
    grabCursor: true,
    loop: true,
    slidesPerView: 4,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    pagination: true,
    modules: [EffectCoverflow, Pagination, Autoplay],
    autoplay: {
      delay: 1000,
      disableOnInteraction: false,
    },
    speed: 2000,
  };

  const settingss = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const setting = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    autoplay: true,
    autoplaySpeed: 2000,
  };



  
  useEffect(() => {
    dispatch(getTopServicesCategory());
  }, []);

  const TopServices = useSelector((state) => state.home.TopServicesCategory);
  // console.log('servicessimg', TopServices?.['0']?.image);

  // useEffect(() => {
  // dispatch(getTopServicesBannrpath());
  // },[]);

  const TopServicesBannerpath = useSelector(
    (state) => state.home.TopservicesBannrpath
  );


  const siteUrl = window.location.href;
  

  return (
    <>
      {loaderStatus ? (
        <div className="loader-container">
          <PulseLoader loading={loaderStatus} size={50} color="#36d7b7" />
        </div>
      ) : (
        <div style={{overflowX: 'hidden'}}>
          <section className="android ptb30" style={{ overflow: "hidden" }}>
            <div className="content-banner">
              <div className="container">
                <h3
                  style={{
                    width: "100%",
                    textAlign: "left",
                    color: "white",
                    textTransform: "inherit",
                    fontSize: "20px",
                    paddingTop: "3%",
                  }}
                >
                  Services
                </h3>

                <Helmet>
                          <meta charSet="utf-8" />
                          <title>"New Bharat Biz - Get Expert Professional Services near by you."</title>
                          <meta name="description" content="New Bharat Biz: Connecting Small Entrepreneurs, Get connected to business owners around you and build a Biz network, business listing, New Bharat Biz mobile app help you grow." />
                          <meta property="og:type" content="website" />
                          <meta property="og:url" content={siteUrl} />
                          <meta property="og:title" content="Connecting 40,000+ Small and Micro Service Providers" />
                          <meta property="og:description" content="New Bharat Biz: Connecting Small Entrepreneurs, Get connected to business owners around you and build a Biz network, business listing, New Bharat Biz mobile app help you grow." />
                          <meta property="og:image" content={SiteLogo} />
                        </Helmet>

              </div>
            </div>
          </section>

          
          <>
          <div className="section-full p-t80 bg-gray " id="about">
            <div className="section-head1 text-center">
              <h3 data-title="Services">Services</h3>
              <div className="wt-separator bg-primarys" />
              <div className="wt-separator2 bg-primarys" />

              <div className="container overflow-hidden text-center">
                <div className="row gx-5">
                  <div className="col-md-6">
                    <div className="p-3" style={{ textAlign: "justify" }}>
                      <p>
                        “New Bharat Biz understands the magic of the
                        neighborhood market place both for the suppliers of
                        goods and services and their customers. Transacting
                        within the neighborhood saves enormous time and cost for
                        each and every delivery of goods/services to customer
                        and makes great economic and environmental sense in
                        contributing towards conservation of national and
                        natural resources. New Bharat Biz adopts a generic and
                        non-interfering approach while providing down to earth
                        and simple mobile search app free of cost to citizens
                        all across the country. The Mobile Search App uses the
                        latest cutting-edge technology and a huge back end
                        infrastructure to enable you to search for anything at
                        any time in your neighborhood. For the user finding any
                        services required, locally available, is just a click
                        away.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="p-3">
                      <img src={img} alt="img" style={{height: '300px'}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>



          <>
            <div
              className="section-full  p-t80 p-b80 bg-gray section7"
              id="services"
            >
              {/* <div className="container"> */}
              {/* TITLE START*/}
              <div className="section-head text-center">
                {/* <div className="section-sub-title">FIND OUT MORE</div> */}
                <h3 data-title="Top Services">Top Services</h3>
                <div className="wt-separator bg-primarys" />
                <div className="wt-separator2 bg-primarys" />
                <p>We helping client to create with our talented expert.</p>
              </div>
              {/* TITLE END*/}
              <div className="section-content">
            <div className="row justify-content-center ">
              <div classname="swiper-wrapper d-flex align-items-center">
                <Swiper {...settings} className="mySwiper" >
                  <SwiperSlide>
                    <img src={Agriculture} height="250" width="200" />
                    <h5
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        padding: "5px",
                        // color: "#00897b",
                        color: "rgb(241, 162, 40)",
                      }}
                    >
                      Agriculture
                    </h5>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Doctor} height="250" width="200" />
                    <h5
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        padding: "5px",
                        color: "rgb(241, 162, 40)",
                      }}
                    >
                      Doctor
                    </h5>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Food} height="250" width="200" />
                    <h5
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        padding: "5px",
                        color: "rgb(241, 162, 40)",
                      }}
                    >
                      Food
                    </h5>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Beauty} height="250" width="200" />
                    <h5
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        padding: "5px",
                        color: "rgb(241, 162, 40)",
                      }}
                    >
                      Beauty
                    </h5>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Education} height="250" width="200" />
                    <h5
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        padding: "5px",
                        color: "rgb(241, 162, 40)",
                      }}
                    >
                      Education
                    </h5>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Wedding} height="250" width="200" />
                    <h5
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        padding: "5px",
                        color: "rgb(241, 162, 40)",
                      }}
                    >
                      Wedding
                    </h5>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Telismith} height="250" width="200" />
                    <h5
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        padding: "5px",
                        color: "rgb(241, 162, 40)",
                      }}
                    >
                      Telismith
                    </h5>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Health} height="250" width="200" />
                    <h5
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        padding: "5px",
                        color: "rgb(241, 162, 40)",
                      }}
                    >
                      Health
                    </h5>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={TravelService} height="250" width="200" />
                    <h5
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        padding: "5px",
                        color: "rgb(241, 162, 40)",
                      }}
                    >
                      TravelService
                    </h5>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Household} height="250" width="200" />
                    <h5
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        padding: "5px",
                        color: "rgb(241, 162, 40)",
                      }}
                    >
                      Household
                    </h5>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={BusinessService} height="250" width="200" />
                    <h5
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        padding: "5px",
                        color: "rgb(241, 162, 40)",
                      }}
                    >
                      BusinessService
                    </h5>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Production} height="250" width="200" />
                    <h5
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        padding: "5px",
                        color: "rgb(241, 162, 40)",
                      }}
                    >
                      Production
                    </h5>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={MedicineStore} height="250" width="200" />
                    <h5
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        padding: "5px",
                        color: "rgb(241, 162, 40)",
                      }}
                    >
                      Medicine Store
                    </h5>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Finance} height="250" width="200" />
                    <h5
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        padding: "5px",
                        color: "rgb(241, 162, 40)",
                      }}
                    >
                      Finance Services
                    </h5>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
              {/* </div> */}
            </div>
          </>

          <>
            <section className=" serviceslide section-full p-t80 p-t70 p-b50 " style={{ backgroundColor: "#0077db" }} >
              <div class="container text-center">
                <div class="row">
                  <div class="col">
                    <div className="wpb_wrapper servicebenefits">
                      <h3 style={{color: '#fff', fontSize: 22, fontWeight: 600, margin: 0}}>Benefits of NBB</h3>
                      <h2 style={{color: '#fff', fontSize: 65, fontWeight: 600, margin: 0}}>Super Fast</h2>
                      &nbsp;
                      <h3 style={{color: '#fff', fontSize: 22, fontWeight: 600, margin: 0}}>Service Search</h3>
                    </div>
                  </div>
                  <div class="col-md-8">
                  <div class="container px-4 text-center">
                  <div class="row ">
                    <div class="col-md-4">
                    <div class="">
                    <div className="shadow-effect">
                      <div className="wt-icon-box-wraper1 center m-b30 bdr-1 bdr-gray bdr-solid corner-radius1 step-icon-box bg-white v-icon-effect">
                        <span className="icon-count-number">1</span>
                        <div className="icon-lg m-b20" >
                          <span className="icon-cell text-primary ">
                            {/* <i className="im-icon-Four-FingersTouch v-icon" /> */}
                            <img src={gifchoose} alt="Animated Gesture" style={{height: '100px'}} />
                          </span>
                        </div>
                        <div className="icon-content mt-4">
                          <h5 className="wt-tilte">Choose a Category</h5>
                          {/* <p>Choose from more than 200 identified categories in which you want to avail services.</p> */}
                        </div>
                      </div>
                    </div>
                    </div>
                    </div>
                    <div class="col-md-4">
                      <div class="">
                      <div className="shadow-effect">
                        <div className="wt-icon-box-wraper1 center m-b30 bdr-1 bdr-gray bdr-solid corner-radius1 step-icon-box bg-white v-icon-effect">
                          <span className="icon-count-number">2</span>
                          <div className="icon-lg m-b20" >
                            <span className="icon-cell text-primary ">
                              {/* <i className="im-icon-Four-FingersTouch v-icon" /> */}
                              <img src={location} alt="Animated Gesture" style={{height: '100px'}} />
                            </span>
                          </div>
                          <div className="icon-content mt-4">
                            <h5 className="wt-tilte">Find Location</h5>
                            {/* <p>Choose from more than 200 identified categories in which you want to avail services.</p> */}
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="">
                      <div className="shadow-effect">
                        <div className="wt-icon-box-wraper1 center m-b30 bdr-1 bdr-gray bdr-solid corner-radius1 step-icon-box bg-white v-icon-effect">
                          <span className="icon-count-number">3</span>
                          <div className="icon-lg m-b20" >
                            <span className="icon-cell text-primary ">
                              {/* <i className="im-icon-Four-FingersTouch v-icon" /> */}
                              <img src={owner} alt="Animated Gesture" style={{height: '100px'}} />
                            </span>
                          </div>
                          <div className="icon-content mt-4">
                          <h5 className="wt-tilte">Contact Owners</h5>
                            {/* <p>Choose from more than 200 identified categories in which you want to avail services.</p> */}
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                  </div>
                </div>
              </div>
            </section>
          </>

          <>
          
          <section
            className=" serviceslide section-full p-t80 p-b50 "
            style={{ backgroundColor: "#e4ebef" }}
          >
            {/* className=" serviceslide section-full  p-t80 p-b80 bg-gray section7 " style={{ backgroundColor: '#e4ebef' }}> */}
            <div className="container ">
              <h2
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  // marginBottom: 80,
                }}
              >
                We Give Awesome Service,
                <br />
                See How{" "}
                <span style={{ color: "#0077db" }}>Customers Love Us.</span>
              </h2>
              <Slider {...settingss}>
                <div className="carousel">
                  <div className="text-center" style={{ backgroundColor: "#e4ebef", marginTop:'40px' }} >
                    <div className=" d-flex align-items-center text-center" style={{position: 'relative'}}>
                      <i className="fa fa-quote-left" style={{  fontSize: "36px", margin: "0% 5%", color: "#b7b7b7", }}></i>
                      <p className="servicepara" style={{ maxWidth: "90%" }}>
                      I had the opportunity of using the services of NewBharatBiz Software. They have been prompt, courteous and delivered the required results. I would not hesitate to use their services or recommend them to my friends any time.
                      </p>
                      <i className="fa fa-quote-right" style={{ fontSize: "36px",  margin: "0% 5%", color: "#b7b7b7"}}></i>
                    </div>
                    <div style={{ margin: "auto" }}>
                      <img
                        src={img4}
                        className="rounded-circle"
                        alt="Cinque Terre"
                        width="100"
                        height="100"
                        style={{ margin: "auto", display: 'block' }}
                      />
                    </div>
                  </div>
                </div>
                <div className="carousel">
                  <div className="text-center" style={{ backgroundColor: "#e4ebef", marginTop:'40px' }} >
                    <div className=" d-flex align-items-center text-center" style={{position: 'relative'}}>
                      <i className="fa fa-quote-left" style={{  fontSize: "36px", margin: "0% 5%", color: "#b7b7b7", }}></i>
                      <p className="servicepara" style={{ maxWidth: "90%" }}>
                      We would like to express our satisfaction and appreciation for the services provided by NewBharatBiz. This software has demonstrated a high level of commitment and professionalism in successfully delivering the required services.
                      </p>
                      <i className="fa fa-quote-right" style={{ fontSize: "36px",  margin: "0% 5%", color: "#b7b7b7"}}></i>
                    </div>
                    <div style={{ margin: "auto" }}>
                      <img
                        src={img3}
                        className="rounded-circle"
                        alt="Cinque Terre"
                        width="100"
                        height="100"
                        style={{ margin: "auto", display: 'block' }}
                      />
                    </div>
                  </div>
                </div>
                <div className="carousel">
                  <div className="text-center" style={{ backgroundColor: "#e4ebef", marginTop:'40px' }} >
                    <div className=" d-flex align-items-center text-center" style={{position: 'relative'}}>
                      <i className="fa fa-quote-left" style={{  fontSize: "36px", margin: "0% 5%", color: "#b7b7b7", }}></i>
                      <p className="servicepara" style={{ maxWidth: "90%" }}>
                      I am delighted to share my experience with NewBharatBiz services. Furthermore, the efficiency and user-friendly interface of NewBharatBiz's software have significantly streamlined our processes for searching services nearby.
                      </p>
                      <i className="fa fa-quote-right" style={{ fontSize: "36px",  margin: "0% 5%", color: "#b7b7b7"}}></i>
                    </div>
                    <div style={{ margin: "auto" }}>
                      <img
                        src={img2}
                        className="rounded-circle"
                        alt="Cinque Terre"
                        width="100"
                        height="100"
                        style={{ margin: "auto", display: 'block' }}
                      />
                    </div>
                  </div>
                </div>

               
              </Slider>
            </div>
          </section>
          </>

          
          <>
            <div
              className="row text-center section-full p-t80 p-b50 bg-gray"
              style={{
                backgroundColor: "rgb(143 143 143)",
                // paddingBottom: "50px",
                margin: "auto",
              }}
            >
              <div
                className="col-sm-6 col-md-8"
                style={{ fontSize: "25px", color: "white" }}
              >
                40,000+ People trusted NewBharatBiz! Be one of them today.
              </div>
              <div className="col-6 col-md-4" style={{margin: 'auto'}}>
                <a
                  href="/vendor_registration"
                  className="btn btn-get-started  scrollto"
                >
                  GET START NOW
                </a>
              </div>
            </div>
          </>
        

        <>
        <div className="section-full  p-t80 p-b80 bg-gray section6" id="services">
        <div className="section-head text-center">
          <h3 data-title="FEATURED CATEGORIES">FEATURED CATEGORIES</h3>
          <div className="wt-separator bg-primarys" />
          <div className="wt-separator2 bg-primarys" />
          <p>Connecting with entrepreneurs online, is just a few clicks away.</p>
        </div>

        <div className="section-content container">
      <div className="row justify-content-center "  >
      <Slider {...setting}>
                  {TopServices?.map((topservice) => (
                  <div classname=" swiper-wrapper d-flex align-items-center">
                    <div
                      // className="col-sm-6 col-md-4 mb-4 mb-lg-0 col-lg-2 cardpadding"
                      className="cardpadding"
                      style={{ marginLeft: "8px" }}
                    >
                      <a href="#" className="popular-category h-100">
                        <span className="icon">
                          <span className="flaticon-house" />

                          <img
                            src={TopServicesBannerpath + topservice.image}
                            className="centered-image"
                            alt={topservice.service_name}
                          />
                        </span>
                        <span className="caption mb-2 d-block">
                          {topservice.service_name}
                        </span>
                      </a>
                    </div>
    </div>
                  ))}
                </Slider>
      </div>
    </div>
        </div>
        </>
        </div>
      )}
    </>
  );
}
export default Services