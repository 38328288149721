import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  postRegistration,
  getServiceCategory,
  getfetchServiceCategory,
  getState,
  getDistrict,
} from "../store/home";
import AutoComplete from "../Components/AutoComplete";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import SiteLogo from "../Components/assets/img/logo.png";




const VendorRegistration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    user_name: "",
    business_name: "",
    email: "",
    mobile_no: "",
    cat_id: "",
    service_id: "",
    address: "",
    user_address: "",
    latitude: "",
    longitude: "",
    landmark: "",
    place_id: "",
    country: "India",
    state: "",
    city: "",
    area: "",
    pincode: "",
    gstn: "",
    tandc: "",
    district: "",
    description: "",
  });
  const [disableRegistration, setDisableRegistration] = useState(false); // New state to control button disable

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: "in" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      console.log(place);

      setFormData({
        ...formData,
        address: place.name,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      });

      console.log(autoCompleteRef.current.value);
      console.log("kalyaniAutoLocation", inputRef.current.value);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    formData.tandc = formData.tandc ? 1 : 0;
    console.log(formData);

    dispatch(postRegistration(formData));
    navigate("/login");
  };

  useEffect(() => {
    dispatch(getServiceCategory());
  }, []);
  const serviceCategory = useSelector((state) => state.home.ServiceCategory);

  const serviceCatbannrpath = useSelector(
    (state) => state.home.serviceCategoryBannrpath
  );

  const handleSelectChange_category = (e) => {
    const selectedCategory = e.target.value;
    dispatch(getfetchServiceCategory(selectedCategory));
  };

  const { fetchServiceCategoryList } = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(getState());
  }, []);

  const statedata = useSelector((state) => state.home.StateList);

  const handleSelectChange_state = (e) => {
    const selectedState = e.target.value;
    setFormData({ ...formData, state: selectedState });
    dispatch(getDistrict(selectedState));
  };

  const handleSelectChange_district = (e) => {
    const selectedDistrict = e.target.value;
    setFormData({ ...formData, district: selectedDistrict });
  };

  const { districtdata } = useSelector((state) => state.home);

  const [errors, setErrors] = useState({
    tandc: "", // initialize the error message for tandc field
    // other form fields' error messages
  });

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, tandc: e.target.checked });

    // Validate checkbox state and update errors state
    if (!e.target.checked) {
      setErrors({
        ...errors,
        tandc: "Please agree to the terms and conditions",
      });
    } else {
      setErrors({ ...errors, tandc: "" }); // Clear the error message when checkbox is checked
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };


  const siteUrl = window.location.href;
  
  return (

   <div className="page-header kalyani">
  <div className="container-fluid p-0">
    <div className="top-left">
    <div className="page-wraper ">
      <div className="page-content ">
        <div className="container col-md-6 registerhead">
          <h1 className="registerhead"> Vendor Register</h1>
          <Helmet>
                          <meta charSet="utf-8" />
                          <title>"New Bharat Biz - Get Expert Professional Services near by you."</title>
                          <meta name="description" content="New Bharat Biz: Connecting Small Entrepreneurs, Get connected to business owners around you and build a Biz network, business listing, New Bharat Biz mobile app help you grow." />
                          <meta property="og:type" content="website" />
                          <meta property="og:url" content={siteUrl} />
                          <meta property="og:title" content="Connecting 40,000+ Small and Micro Service Providers" />
                          <meta property="og:description" content="New Bharat Biz: Connecting Small Entrepreneurs, Get connected to business owners around you and build a Biz network, business listing, New Bharat Biz mobile app help you grow." />
                          <meta property="og:image" content={SiteLogo} />
                        </Helmet>
        </div>
        <div className="container col-md-7 registerhead1">
          <div className="row">
            <div className="col-md-12">
              {/* <div className="dashboard-profile-section clearfix"> */}
              <div className="dashboard-profile-section clearfix">
                <div className="dasboard-profile-form overflow-hide">
                  <form onSubmit={handleSubmit}>
                    <div className="container">
                      <div
                        className="col-md-12"
                        style={{
                          textAlign: "right",
                          marginBottom: "2%",
                          color: "green",
                        }}
                      >
                        If already registered then please{" "}
                        <a href="/login">
                          <strong>Login</strong>{" "}
                          <i
                            class="fa fa-arrow-right"
                            style={{ "font-size": "16px" }}
                          ></i>
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 p-2 col-md-12">
                        <div className="form-group">
                          <label>
                            Location
                            <span className="req">*</span>
                          </label>
                          <input
                            className="form-control"
                            id="address"
                            type="address"
                            name="address"
                            onChange={handleInputChange}
                            value={formData.address}
                            ref={inputRef}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 p-2 col-md-12">
                        <div className="form-group">
                          <label>
                            Organization Name
                            <span className="req">*</span>
                          </label>
                          <div className="ls-inputicon-box">
                            <input
                              placeholder="Enter Organization Name"
                              className="form-control"
                              type="text"
                              name="business_name"
                              value={formData.business_name}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 p-2 col-md-12">
                        <div className="form-group">
                          <label>
                            Name
                            <span className="req">*</span>
                          </label>
                          <div className="ls-inputicon-box">
                            <input
                              placeholder="Enter Name"
                              className="form-control"
                              type="text"
                              name="user_name"
                              value={formData.user_name}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 p-2 col-md-12">
                        <div className="form-group">
                          <label>
                            Mobile
                            <span className="req">*</span>
                          </label>
                          <input
                            placeholder="Enter mobile number"
                            className="form-control"
                            maxLength={10}
                            type="tel"
                            name="mobile_no"
                            value={formData.mobile_no}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 p-2 col-md-12">
                        <div className="form-group">
                          <label>
                            Select Category
                            <span className="req">*</span>
                          </label>
                          <div className="ls-inputicon-box">
                            <select
                              className="form-control"
                              id="cat_id"
                              name="cat_id"
                              // onChange={(e) => handleSelectChange_category(e)}
                              onChange={(e) => {
                                handleInputChange(e);
                                handleSelectChange_category(e);
                              }}
                            >
                              <option value={formData.cat_id} required>
                                Choose Category
                              </option>
                              {serviceCategory?.map((serviceCat) => (
                                <option value={serviceCat.id}>
                                  {serviceCat.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 p-2 col-md-12">
                        <div className="form-group">
                          <label>
                            Select Service
                            <span className="req">*</span>
                          </label>
                          <div className="ls-inputicon-box">
                            <select
                              className="form-control"
                              id="service_id"
                              name="service_id"
                              onChange={handleInputChange}
                            >
                              <option value={formData.service_id}>
                                Choose Service
                              </option>
                              {fetchServiceCategoryList?.map(
                                (fetchserviceCat) => (
                                  <option
                                    key={fetchserviceCat.id}
                                    value={fetchserviceCat.id}
                                  >
                                    {fetchserviceCat.service_name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 p-2 col-md-12">
                        <div className="form-group">
                          <label>
                            Email
                            <span className="req">*</span>
                          </label>
                          <input
                            placeholder="Enter Email"
                            className="form-control"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 p-2 col-md-12">
                        <div className="form-group">
                          <label>
                            State
                            <span className="req">*</span>
                          </label>
                          <div className="ls-inputicon-box">
                            <select
                              className="form-control"
                              id="state"
                              name="state"
                              onChange={(e) => {
                                handleInputChange(e);
                                handleSelectChange_state(e);
                              }}
                              autoComplete="off"
                            >
                              <option value={formData.state} required>
                                Select State
                              </option>
                              {statedata?.map((stateLi) => (
                                <option key={stateLi.id} value={stateLi.id}>
                                  {stateLi.statename}
                                </option>
                              ))}
                            </select>
                          </div>
                          {errors.state && (
                            <span
                              style={{
                                color: "red", // Red color
                                animation: "fadeIn 0.5s", // Fade-in animation with a duration of 0.5 seconds
                              }}
                              className="error"
                            >
                              {errors.state}
                            </span>
                          )}
                          <style>
                            {/* Define the fadeIn animation */}
                            {`
 @keyframes fadeIn {
 from {
 opacity: 0;
 }
 to {
 opacity: 1;
 }
 }
 `}
                          </style>{" "}
                        </div>
                      </div>
                      <div className="col-lg-6 p-2 col-md-12">
                        <div className="form-group">
                          <label>
                            District
                            <span className="req">*</span>
                          </label>
                          <div className="ls-inputicon-box">
                            <select
                              className="form-control"
                              id="district"
                              name="district"
                              onChange={(e) => {
                                handleInputChange(e);
                                handleSelectChange_district(e);
                              }}
                            >
                              <option value={formData.district} required>
                                Select district
                              </option>
                              {districtdata?.map((districtlist) => (
                                <option
                                  key={districtlist.id}
                                  value={districtlist.id}
                                >
                                  {districtlist.district_name}
                                </option>
                              ))}
                            </select>
                          </div>
                          {errors.district && (
                            <span
                              style={{
                                color: "red",
                                animation: "fadeIn 0.5s",
                              }}
                              className="error"
                            >
                              {errors.district}
                            </span>
                          )}
                          <style>
                            {`
 @keyframes fadeIn {
 from {
 opacity: 0;
 }
 to {
 opacity: 1;
 }
 }
 `}
                          </style>
                        </div>
                      </div>

                      <div className="col-lg-6 p-2 col-md-12">
                        <div className="form-group">
                          <label>
                            City
                            <span className="req">*</span>
                          </label>
                          <input
                            placeholder="Enter City"
                            className="form-control"
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 p-2 col-md-12">
                        <div className="form-group">
                          <label>
                            Area
                            <span className="req">*</span>
                          </label>
                          <input
                            placeholder="Enter Area"
                            className="form-control"
                            type="text"
                            name="area"
                            value={formData.area}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 p-2 col-md-12">
                        <div className="form-group">
                          <label>
                            GSTN
                            {/* <span className="req">*</span> */}
                          </label>
                          <input
                            placeholder="Enter GSTN"
                            className="form-control"
                            name="gstn"
                            value={formData.gstn}
                            onChange={handleInputChange}
                            // required
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Description
                            <span className="req">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            type="text"
                            name="description"
                            rows={3}
                            value={formData.description}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            <span className="req">*</span>
                          </label>
                          {/* <div className="ls-inputicon-box">
 <label className="checkbox-label"> */}
                          <div
                            className={`ls-inputicon-box ${
                              !formData.tandc ? "focus-outline" : ""
                            }`}
                          >
                            <label className="checkbox-label">
                              <input
                                type="checkbox"
                                name="tandc"
                                checked={formData.tandc}
                                required
                                // onChange={handleCheckboxChange}
                                onChange={(e) => {
                                  handleInputChange(e);
                                  handleCheckboxChange(e);
                                }}
                              />
                              <a
                                href="/terms_condition"
                                target="_blank"
                                spiketip-title="Read Terms and Conditions"
                                spiketip-pos="bottom"
                              >
                                &nbsp; I agree with terms and conditions
                              </a>
                            </label>
                          </div>
                          {errors.tandc && (
                            <span className="error">{errors.tandc}</span>
                          )}
                        </div>
                      </div>
                      <button type="submit" className="btn">
                        Register
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>





   
  );
};

export default VendorRegistration;
