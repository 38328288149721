import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";

import PulseLoader from "react-spinners/PulseLoader";
import Slider from "react-slick";
import gifchoose from "../Components/assets/img/shipping.gif";
import locationgif from "../Components/assets/img/location.gif";
import owner from "../Components/assets/img/owner.gif";
import Agriculture from "../Components/assets/img/TopServices/Agriculture.jpg";
import Beauty from "../Components/assets/img/TopServices/Beauty.jpg";
import BusinessService from "../Components/assets/img/TopServices/Business service.jpg";
import Education from "../Components/assets/img/TopServices/Education.jpg";
import Telismith from "../Components/assets/img/TopServices/Telismith.jpg";
import Doctor from "../Components/assets/img/TopServices/Doctor.jpg";
import Wedding from "../Components/assets/img/TopServices/Wedding & Events.jpg";
import Food from "../Components/assets/img/TopServices/Food.jpg";
import Health from "../Components/assets/img/TopServices/Health.jpg";
import TravelService from "../Components/assets/img/TopServices/Travel service.jpg";
import Finance from "../Components/assets/img/TopServices/Finance.jpg";
import MedicineStore from "../Components/assets/img/TopServices/MedicineStore.jpg";
import Production from "../Components/assets/img/TopServices/Product & Manufacturing.jpg";
import Household from "../Components/assets/img/TopServices/HouseholdServices.jpg";
import Technical from "../Components/assets/img/technical-support.png";
import Social from "../Components/assets/img/social-care.png";
import Group from "../Components/assets/img/group.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Autoplay } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import {
  getBannerPages,
  getBannrpath,
  getTopServicesCategory,
  getTopServicesBannrpath,
  getFeatureCategory,
  gethomeServiceCategory,
} from "../store/home";
import mobileApp from "../Components/assets/img/googleapp.png";
import playstore from "../Components/assets/img/playstore.png";
import ios from "../Components/assets/img/ios.png";
import { Link, useNavigate } from "react-router-dom";
import SearchService from "./SearchService";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { vendorLogout } from "../store/auth";
import Swal from "sweetalert2";
import { nbbApi } from "../store/axios";
import { Helmet } from 'react-helmet';
import SiteLogo from "../Components/assets/img/logo.png";



/* global google */

const Home = () => {
  const dispatch = useDispatch();

  //Banner pages start

  useEffect(() => {
    dispatch(getBannerPages());
  }, []);

  const stat = useSelector((state) => state.home);
  const bannerpage = useSelector((state) => state.home.BannerPages);
  const bannerpath = useSelector((state) => state.home.Bannrpath);
  console.log("webbannerkalyani", bannerpath);

  console.log('bannerpagv', bannerpage);

  // useEffect(() => {
  // dispatch(getBannrpath());
  // },[]);

  // console.log('bannerpagvffff', bannerpath);

  //banner pages end

  useEffect(() => {
    dispatch(getTopServicesCategory());
  }, []);

  const TopServices = useSelector((state) => state.home.TopServicesCategory);
  // console.log('servicessimg', TopServices?.['0']?.image);

  // useEffect(() => {
  // dispatch(getTopServicesBannrpath());
  // },[]);

  const TopServicesBannerpath = useSelector(
    (state) => state.home.TopservicesBannrpath
  );
  

  useEffect(() => {
    dispatch(getFeatureCategory());
  }, []);

  const FeatureCat = useSelector((state) => state.home.FeatureCategory);
  console.log('featuredcatimg', FeatureCat);
  const featuredCatImgPath = useSelector( (state) => state.home.FeatureCategorybanner);

  const cssDoodleStyles = `
 <css-doodle><style>
 --color: #51eaea, #fffde1, #ff9d76, #FB3569;

 @grid: 30x1 / 99vw 80vh / #270f34; 
 
 :container {
 perspective: 30vmin;
 --deg: @p(-180deg, 180deg);
 }
 
 :after, :before {
 content: '';
 background: @p(--color); 
 @place: @r(100%) @r(100%);
 @size: @r(6px);
 @shape: heart;
 }

 @place: center;
 @size: 18vmin; 

 box-shadow: @m2(0 0 50px @p(--color));
 background: @m100(
 radial-gradient(@p(--color) 50%, transparent 0) 
 @r(-20%, 120%) @r(-20%, 100%) / 1px 1px
 no-repeat
 );

 will-change: transform, opacity;
 animation: scale-up 32s linear infinite;
 animation-delay: calc(-8s / @I * @i);

 @keyframes scale-up {
 0%, 95.01%, 100% {
 transform: translateZ(0) rotate(0);
 opacity: 0;
 }
 10% { 
 opacity: 1; 
 }
 95% {
 transform: 
 translateZ(35vmin) rotateZ(var(--deg));
 }
 }
</style></css-doodle>
 `;

  const settings = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const setting = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    autoplay: true,
    autoplaySpeed: 2000,
  };

  // useEffect(() => {
  // dispatch(gethomeServiceCategory());
  // }, []);
  // const homeserviceCategory = useSelector(
  // (state) => state.home.homeServiceCategory
  // );

  // console.log("kalyaniCategoryhome", homeserviceCategory);
  const vendorphonestatus = localStorage.getItem("formDataVendorPhone") !== null;

  const initialState = {
    latitude: "",
    longitude: "",
    userLocation: "",
    serviceSuggestions: [], // Initialize as an empty array
    selectedService: "",
    selectedServiceId: null,
    suggestionsCache: {},
    isSuggestionsVisible: false,
  };

  const [state, setState] = useState(initialState);
  const [suggestions, setSuggestions] = useState([]);
  const inputRef = useRef();
  const suggestionFetchDelay = 300;
  const navigate = useNavigate();
  const location = useLocation();

  // Memoize the fetchUserLocation function to prevent unnecessary re-renders
  const fetchUserLocation = async () => {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const { latitude, longitude } = position.coords;

      const reverseGeocodeUrl = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`;

      const response = await fetch(reverseGeocodeUrl);
      const data = await response.json();

      if (data.display_name) {
        const locationName = data.display_name;
        setState({ ...state, userLocation: locationName, latitude, longitude });
        localStorage.setItem("user_address", locationName);
      } else {
        console.error("No location name found in the response.");
      }
    } catch (error) {
      console.error("Error in reverse geocoding:", error);
    }
  };

  // Memoize the handleServiceSuggestions function to prevent unnecessary re-renders

  const handleServiceSuggestions = useMemo(() => async (input) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    try {
      const response = await nbbApi.get(
        `/srvc_autocomplete`,
        {
          params: {
            searchval: input,
          },
          cancelToken: source.token,
        }
      );

      const newSuggestions = response.data.service.slice(0, 5);
      setSuggestions(newSuggestions);
    } catch (error) {
      if (axios.isCancel(error)) {
        // Request was canceled, ignore this error
      } else {
        console.error("Error fetching service suggestions:", error);
        // Rethrow the error if needed
        throw error;
      }
    }
  }, []);

  // Memoize the handleSubmit function to prevent unnecessary re-renders
  const handleSubmit = useCallback(() => {
    if (!state.selectedServiceId) {
      console.error("Service not selected.");
      return;
    }

    const formData = new FormData();
    formData.append("latitude", state.latitude);
    formData.append("longitude", state.longitude);
    formData.append("service_id", state.selectedServiceId);
    formData.append("page_num", "1");
    formData.append("phone", "");

    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });
    sessionStorage.setItem("formData", JSON.stringify(formDataObject));
    navigate("/search_service");
  }, [state, navigate]);
  // Separate handleInputChange functions for service and location fields
  const handleServiceInputChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });

    // Call handleServiceSuggestions only when the input value changes
    handleServiceSuggestions(value);
  };

  const handleLocationInputChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleFocus = () => {
    setState({ ...state, isSuggestionsVisible: true });
  };
  const handleClickOutside = (event) => {
    if (
      inputRef.current && // Check if the inputRef is available
      inputRef.current.value.trim() === "" && // Check if the input field is empty
      !inputRef.current.contains(event.target)
    ) {
      // Click is outside the input field
      setSuggestions([]); // Clear suggestions
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [inputRef]); 

  // const serviceSuggestionsList =
  //   suggestions.length > 0 ? (
  //     <ListGroup className="suggestions suggestions-list">
  //       {suggestions.map((service) => (
  //         <ListGroupItem
  //           key={service.id}
  //           onClick={() => {
  //             setState({
  //               ...state,
  //               selectedServiceId: service.id,
  //               selectedService: service.service_name,
                
  //             });
  //             localStorage.setItem(
  //               "selected_service_name",
  //               service.service_name
  //             );
  //             setSuggestions([]); // Clear suggestions
  //           }}
  //         >
  //           {service.service_name}
  //         </ListGroupItem>
  //       ))}
  //     </ListGroup>
  //   ) : null; 

  const serviceSuggestionsList =
  suggestions.length > 0 ? (
    <div className="suggestions suggestions-list">
      {suggestions.map((service) => (
        <div
        className="suggestion-border"
          key={service.id}
          onClick={() => {
            setState({
              ...state,
              selectedServiceId: service.id,
              selectedService: service.service_name,
            });
            localStorage.setItem(
              "selected_service_name",
              service.service_name
            );
            setSuggestions([]); // Clear suggestions
          }}
        >
          {service.service_name}
        </div>
      ))}
    </div>
  ) : null;


    const handleVendorLogout = () => {
      Swal.fire({
        title: 'Are you sure want to?',
        text: "LOG OUT!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f1a228', //#3085d6
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Log Out!'
      }).then((result) => {
        if (result.isConfirmed) {
          // Put your navigation and dispatch logic here
          navigate("/");
          dispatch(vendorLogout());
          Swal.fire(
            'Logged Out!',
            'You have been logged out.',
            'success'
          );
        }
      });
    }

  const [loaderStatus, setLoaderStatus] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderStatus(false);
    }, 2000); 
  }, []);


  // const metaTags = state.allmeta;
  // const metaTags = useSelector((state) => state.contact.data.meta_data);
  const siteUrl = window.location.href;



  return (
    <>
      {loaderStatus ? (
        <div className="loader-container">
          <PulseLoader loading={loaderStatus} size={50} color="#36d7b7" />
        </div>
      ) : (
        <div style={{ overflowX: "hidden" }}>
          <>
            <section id="hero">
              <div
                id="carouselExampleFade"
                className="carousel carousel-fade"
                data-bs-ride="carousel"
                data-bs-interval={100000}
              >
                <div className="carousel-inner">
                  {bannerpage?.map((banner) => (
                    <div className="carousel-item active">
                      <img
                        src={bannerpath + banner.image}
                        className="d-block carousel-image img-fluid"
                        alt="First slide"
                      />
                      <div class="carousel-caption searchdesg ">
                        <h1>{banner.title}</h1>
                        <div className="col-md-12" style={{display: 'flex', justifyContent: 'center', columnGap: '7px'}}>
                        <div className="col-md-2" style={{ marginTop: "4%" }}>
                          <a href="/vendor_registration">
                            {/* <button type="button" className="btn btn-get-started  scrollto" >Vender Register</button> */}
                            <button
                              type="button"
                              className="btn btn-primary homeregisterbtn scrollto"
                            >
                              Vender Register
                            </button>
                          </a>
                        </div>
                        <div className="col-md-2" style={{ marginTop: "4%" }}>
                        <a href="https://play.google.com/store/apps/details?id=com.newbharattech.newbharatbiz&hl=en&gl=US" target="_blank">
                            <img
                              src={playstore}
                              alt="playstore"
                              className="home_button"
                              // style={{ height: "39px", width: "auto" }}
                            />
                          </a>
                          </div>
                        <div className="col-md-2" style={{ marginTop: "4%" }}>
                        <a href="https://apps.apple.com/us/app/new-bharat-biz-app/id6480580383" target="_blank">
                            <img
                              src={ios}
                              alt="ios"
                              className="home_button"
                              // style={{ height: "39px", width: "auto" }}
                            />
                          </a>
                          </div>
                        </div>
                        {/* <div className="col-md-2">
                          <a href="https://play.google.com/store/apps/details?id=com.newbharattech.newbharatbiz&hl=en&gl=US">
                            <img
                              src={playstore}
                              alt="playstore"
                              style={{ height: "32px", marginTop: "10%" }}
                            />
                          </a>
                        </div> */}
                        <div className="d-none d-lg-block">
                          <form onSubmit={handleSubmit}>
                            <div
                              className="container text-center"
                              style={{ color: "black" }}
                            >
                              <div className="row">
                                <div className="col-4">
                                  <div className="select-wrap">
                                    <span className="icon">
                                      <span className="icon-keyboard_arrow_down" />
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="selectedService"
                                      id="service"
                                      placeholder="Enter Service"
                                      value={state.selectedService}
                                      onChange={handleServiceInputChange}
                                      onFocus={handleFocus}
                                      autoComplete="off"
                                      ref={inputRef}
                                      required
                                      style={{border: '1px solid black'}}
                                    />
                                    {serviceSuggestionsList}
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="wrap-icon d-flex">
                                    <input
                                      type="text"
                                      className="form-control search-height"
                                      name="userLocation"
                                      placeholder="Location"
                                      value={state.userLocation}
                                      onChange={handleLocationInputChange}
                                      required
                                      ref={(input) => {
                                        if (input) {
                                          const autocomplete =
                                            new google.maps.places.Autocomplete(
                                              input
                                            );
                                          autocomplete.addListener(
                                            "place_changed",
                                            () => {
                                              const place =
                                                autocomplete.getPlace();
                                              if (place.geometry) {
                                                const lat =
                                                  place.geometry.location.lat();
                                                const lon =
                                                  place.geometry.location.lng();
                                                setState({
                                                  ...state,
                                                  userLocation:
                                                    place.formatted_address,
                                                  latitude: lat,
                                                  longitude: lon,
                                                });
                                              }
                                            }
                                          );
                                        }
                                      }}
                                      style={{border: '1px solid black'}}
                                    />
                                    <i
                                      className="fas fa-map-marker-alt mapdesgn"
                                      onClick={fetchUserLocation}
                                    ></i>
                                  </div>
                                </div>
                                {/* <div className="col-2  btn btn-sm searchbutndesgn" type="button"> */}
                                <div
                                  className="col-2"
                                  style={{
                                    textAlign: "center",
                                    margin: "auto",
                                  }}
                                >
                                  {/* <button
                                className=" btn btn-lg btn-primary"
                                type="submit"
                              >
                                Search
                              </button> */}
                                  <button
                                    type="submit"
                                    class="btn btn-primary"
                                    style={{"--bs-btn-padding-x": "2.5rem", "--bs-btn-font-size": "1rem", 'border': '1px solid black', 'backgroundColor': '#0d6efd'}}
                                  >
                                    Search
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <a
                  className="carousel-control-prev"
                  href="#carouselExampleFade"
                  role="button"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  href="#carouselExampleFade"
                  role="button"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Next</span>
                </a>
              </div>
            </section>

            <>
              <div
                className="container d-lg-none"
                style={{ marginBottom: "7%", marginTop: "2%" }}
              >
                <form onSubmit={handleSubmit}>
                  <div
                    className="container text-center"
                    style={{ color: "black" }}
                  >
                    <div
                      className="row m-3"
                      style={{
                        alignItems: "center",
                        margin: "auto",
                        justifyContent: "center",
                      }}
                    >
                      <div className="col-lg-4 p-2 col-md-8">
                        <div className="select-wrap">
                          <span className="icon">
                            <span className="icon-keyboard_arrow_down" />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            name="selectedService"
                            id="service"
                            placeholder="Enter Service"
                            value={state.selectedService}
                            onChange={handleServiceInputChange}
                            onFocus={handleFocus}
                            autoComplete="off"
                            ref={inputRef}
                            required
                          />
                          {serviceSuggestionsList}
                        </div>
                      </div>
                      <div className="col-lg-4 p-2 col-md-8">
                        <div className="wrap-icon d-flex">
                          <input
                            type="text"
                            className="form-control search-height"
                            name="userLocation"
                            placeholder="Location"
                            value={state.userLocation}
                            onChange={handleLocationInputChange}
                            required
                            ref={(input) => {
                              if (input) {
                                const autocomplete =
                                  new google.maps.places.Autocomplete(input);
                                autocomplete.addListener(
                                  "place_changed",
                                  () => {
                                    const place = autocomplete.getPlace();
                                    if (place.geometry) {
                                      const lat = place.geometry.location.lat();
                                      const lon = place.geometry.location.lng();
                                      setState({
                                        ...state,
                                        userLocation: place.formatted_address,
                                        latitude: lat,
                                        longitude: lon,
                                      });
                                    }
                                  }
                                );
                              }
                            }}
                          />
                          <i
                            className="fas fa-map-marker-alt mapdesgn"
                            onClick={fetchUserLocation}
                          ></i>
                        </div>
                      </div>
                      {/* <div className="col-2  btn btn-sm searchbutndesgn" type="button"> */}
                      <div
                        className=" col-lg-2 col-md-8"
                        style={{ textAlign: "center", margin: "auto" }}
                      >
                        <button
                          className=" btn btn-sm btn-primary"
                          type="submit"
                          style={{"--bs-btn-padding-x": "2.5rem", "--bs-btn-font-size": "1rem", 'border': '1px solid black', 'backgroundColor': '#0d6efd'}}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </>

            {/* TOP 10 SERVICE START */}
            <>
              <div className="site-section bg-light topservicedesgn">
                <div className="container">
                  <div className="overlap-category mb-5">
                    <div className="row align-items-stretch no-gutters">
                      <Slider {...settings}>
                        {TopServices?.map((topservice) => (
                          <div
                            className="col-sm-6 col-md-4 mb-4 mb-lg-0 col-lg-2 cardpadding"
                            // style={{ marginLeft: "8px" }}
                          >
                            <a href="#" className="popular-category ">
                              <span className="icon">
                                <span className="flaticon-house" />

                                <img
                                  src={TopServicesBannerpath + topservice.image}
                                  className="centered-image"
                                  alt="Utkal Air Voice TV"
                                />
                              </span>
                              <span className="caption mb-2 d-block">
                                {topservice.service_name}
                              </span>
                            </a>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </>

            {/* TOP 10 SERVICE END */}
            <>
              <div className="section-full p-t80 p-b50">
                <div className="container">
                  <div className="section-content">
                    {/* TITLE START*/}
                    <div className="section-head text-center">
                      <h3 data-title="Quick and easy search">
                        Quick and easy search
                      </h3>
                      <Helmet>
                          <meta charSet="utf-8" />
                          <title>"New Bharat Biz - Get Expert Professional Services near by you. "</title>
                          <meta name="description" content="New Bharat Biz: Connecting Small Entrepreneurs, Get connected to business owners around you and build a Biz network, business listing, New Bharat Biz mobile app help you grow." />
                          <meta property="og:type" content="website" />
                          <meta property="og:url" content={siteUrl} />
                          <meta property="og:title" content="Connecting 40,000+ Small and Micro Service Providers" />
                          <meta property="og:description" content="New Bharat Biz: Connecting Small Entrepreneurs, Get connected to business owners around you and build a Biz network, business listing, New Bharat Biz mobile app help you grow." />
                          <meta property="og:image" content={SiteLogo} />
                        </Helmet>
                      <div className="separator">
                        <div className="wt-separator bg-primarys custom-separator" />
                        <div className="wt-separator2 bg-primarys custom-separator2" />
                      </div>
                      <p>
                        Searching for services on our platform is made quick and
                        easy. Follow the steps given below to avail desired
                        services at your convenience.
                      </p>
                    </div>
                    {/* TITLE END*/}
                    <div className="row">
                      <div className="col-md-4 col-sm-6 ">
                        <div className="shadow-effect">
                          <div className="wt-icon-box-wraper center p-a25 p-b50 m-b30 bdr-1 bdr-gray bdr-solid corner-radius step-icon-box bg-white v-icon-effect">
                            <span className="icon-count-number">1</span>
                            <div
                              className="icon-lg m-b20"
                              style={{ marginLeft: "31%" }}
                            >
                              <span className="icon-cell text-primary ">
                                {/* <i className="im-icon-Four-FingersTouch v-icon" /> */}
                                <img
                                  src={gifchoose}
                                  alt="Animated Gesture"
                                  style={{ height: "100px" }}
                                />
                              </span>
                            </div>
                            <div className="icon-content">
                              <h4 className="wt-tilte">Choose a Category</h4>
                              <p>
                                Choose from more than 200 identified categories
                                in which you want to avail services.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="shadow-effect">
                          <div className="wt-icon-box-wraper center p-a25 p-b50 m-b30 bdr-1 bdr-gray bdr-solid corner-radius step-icon-box bg-white v-icon-effect">
                            <span className="icon-count-number">2</span>
                            <div
                              className="icon-lg m-b20"
                              style={{ marginLeft: "31%" }}
                            >
                              <span className="icon-cell text-primary">
                                <img
                                  src={locationgif}
                                  alt="Animated Gesture"
                                  style={{ height: "100px" }}
                                />
                              </span>
                            </div>
                            <div className="icon-content">
                              <h4 className="wt-tilte">Find Location</h4>
                              <p>
                                Enter the location around which you want to
                                avail your desired services.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12">
                        <div className="shadow-effect">
                          <div className="wt-icon-box-wraper center p-a25 p-b50 m-b30 bdr-1 bdr-gray bdr-solid corner-radius step-icon-box bg-white v-icon-effect">
                            <span className="icon-count-number">3</span>
                            <div
                              className="icon-lg m-b20"
                              style={{ marginLeft: "31%" }}
                            >
                              <span className="icon-cell text-primary">
                                <img
                                  src={owner}
                                  alt="Animated Gesture"
                                  style={{ height: "100px" }}
                                />
                              </span>
                            </div>
                            <div className="icon-content">
                              <h4 className="wt-tilte">Contact a Few Owners</h4>
                              <p>
                                Contact service providers of the desired
                                services in your locality.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* End #main */}
            </>

            <>
              <div className="section-full p-t50 p-b50 area section4">
                <div
                  dangerouslySetInnerHTML={{ __html: cssDoodleStyles }}
                  style={{
                    position: "absolute",
                    top: "10%",
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: -1,
                  }}
                />
                <div className="container">
                  <div className="section-content">
                    <div className="newsletter-section text-center text-white">
                      <div
                        className="row"
                        style={{ paddingTop: "15%", marginBottom: "6%" }}
                      >
                        <div className="col-md-4">
                          <div className="counter-box">
                            <img
                              src={Technical}
                              className="img-fluid"
                              style={{
                                width: 70,
                                marginBottom: 9,
                                marginTop: 10,
                              }}
                            />
                            <div className="counting counter" data-count={180}>
                              180
                            </div>
                            <h5>Services</h5>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="counter-box">
                            <img
                              src={Social}
                              className="img-fluid"
                              style={{
                                width: 70,
                                marginBottom: 9,
                                marginTop: 10,
                              }}
                            />
                            <div className="counting counter" data-count={1400}>
                              1400
                            </div>
                            <h5>Customers</h5>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="counter-box">
                            <img
                              src={Group}
                              className="img-fluid"
                              style={{
                                width: 70,
                                marginBottom: 9,
                                marginTop: 10,
                              }}
                            />
                            <div className="counting counter" data-count={1200}>
                              40,000
                            </div>
                            <h5>Service holders</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>

            {/* FEATURED CATEGORY START*/}

            {/* {FeatureCat?.map((featured) => ( */}
            <>
              <div
                className="section-full p-t80 p-b80 bg-gray section5"
                id="services"
              >
                <div className="container">
                  {/* TITLE START*/}
                  <div className="section-head text-center">
                    <h3 data-title="Featured Categories">
                      Featured Categories
                    </h3>
                    <div className="wt-separator bg-primarys" />
                    <div className="wt-separator2 bg-primarys" />
                    <p>
                      Connecting with entrepreneurs online, is just a few clicks
                      away.
                    </p>
                  </div>
                  {/* TITLE END*/}
                  <div className="section-content">
                    <div className="row justify-content-center">
                      {FeatureCat?.map((featured) => (
                        <div className="col-sm-3 col-lg-2" key={featured.id}>
                          <div className="am-item">
                            <div className="am-thumb">
                              <img
                                src={featuredCatImgPath + featured.cover_pic}
                                alt="feature"
                              />
                            </div>
                            <div className="am-content">
                              <h5 className="title">{featured.name}</h5>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>

            <>
              <div className="container aboutapp p-t80 p-b55">
                <div
                  className="section-full col section-head"
                  style={{ textAlign: "center" }}
                >
                  <h1>New Bharat Biz goApp</h1>
                  <div className="wt-separator bg-primarys" />
                  <div className="wt-separator2 bg-primarys" />
                  <p>
                    New Bharat Biz understands the magic of the neighborhood
                    market place <br /> both for the suppliers of goods and
                    services and their customers.{" "}
                  </p>
                </div>
                {/* <div className="container overflow-hidden text-center"> */}
                <div className="container text-center">
                  <div className="row">
                    <div className="col-sm-6" style={{display: "flex"}}>
                      <div
                        className="card col-md-6"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                      >
                        <div className="card-body">
                          <a
                            href="https://play.google.com/store/apps/details?id=com.newbharattech.newbharatbiz&hl=en&gl=US"
                            target="_blank"
                          >
                            <img
                              src={playstore}
                              className="card-img-top"
                              alt="playstore"
                              style={{ height: "43px", width: "auto" }}
                            />
                          </a>
                        </div>
                        {/* <div className="card-body">
                  <img src={ios} className="card-img-top" alt="ios" style={{height: '43px', width: 'auto'}}/>
                  </div> */}
                      </div>
                      <div className="card col-md-6" style={{backgroundColor: 'transparent', border: 'none'}}>
                  <div className="card-body">
                    <a
                      href="https://apps.apple.com/us/app/new-bharat-biz-app/id6480580383"
                      target="_blank"
                    >
                      <img src={ios} className="card-img-top" alt="ios" style={{height: '43px', width: 'auto'}}/>
                    </a>
                  </div>
                  {/* <div className="card-body">
                  <img src={ios} className="card-img-top" alt="ios" style={{height: '43px', width: 'auto'}}/>
                  </div> */}
                </div>
                    </div>
                    <div className="col-sm-6">
                      <div
                        className="card"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                      >
                        <div className="card-body">
                        <a
                            href="https://play.google.com/store/apps/details?id=com.newbharattech.newbharatbiz&hl=en&gl=US"
                            target="_blank"
                          >
                          <img
                            src={mobileApp}
                            className="image-fluid"
                            style={{ width: "60%" }}
                          />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row gx-5">
              <div className="col" style={{ margin: "auto" }}>
                <div className="row ">
                  <div className="col">
                    <div className="card" style={{ width: "140px" }}>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.newbharattech.newbharatbiz&hl=en&gl=US"
                        target="_blank"
                      >
                        <img
                          src={playstore}
                          className="card-img-top"
                          alt="..."
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card" style={{ width: "140px" }}>
                      <img src={ios} className="card-img-top" alt="..." />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col" style={{ margin: "auto" }}>
                <div className="p-3">
                  <img src={mobileApp} className="image-fluid" />
                </div>
              </div>
            </div> */}
                </div>
              </div>
            </>

            <>
              <div
                className="section-full p-t80 p-b80 bg-gray section8"
                id="services"
              >
                {/* <div className="container"> */}
                {/* TITLE START*/}
                <div className="section-head text-center">
                  {/* <div className="section-sub-title">FIND OUT MORE</div> */}
                  <h3 data-title="Top Services">Top Services</h3>
                  <div className="wt-separator bg-primarys" />
                  <div className="wt-separator2 bg-primarys" />
                  <p>We helping client to create with our talented expert.</p>
                </div>
                {/* TITLE END*/}
                <div className="section-content">
                  <div className="row justify-content-center ">
                    <div classname="swiper-wrapper d-flex align-items-center">
                      <Swiper
                        effect={"coverflow"}
                        grabCursor={true}
                        loop={true}
                        // centeredSlides={true}
                        slidesPerView={"4"}
                        coverflowEffect={{
                          rotate: 50,
                          stretch: 0,
                          depth: 100,
                          modifier: 1,
                          slideShadows: true,
                        }}
                        pagination={true}
                        modules={[EffectCoverflow, Pagination, Autoplay]}
                        className="mySwiper"
                        autoplay={{
                          // Add autoplay configuration
                          delay: 1000, // Delay in milliseconds
                          disableOnInteraction: false, // Autoplay continues even when the user interacts with it
                        }}
                        speed={1000} // Set the speed in milliseconds
                      >
                        <SwiperSlide>
                          <img src={Agriculture} height="250" width="200" />
                          <h5
                            style={{
                              textAlign: "center",
                              margin: "5px",
                              padding: "5px",
                              // color: "#00897b",
                              color: "rgb(241, 162, 40)",
                            }}
                          >
                            Agriculture
                          </h5>
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src={Doctor} height="250" width="200" />
                          <h5
                            style={{
                              textAlign: "center",
                              margin: "5px",
                              padding: "5px",
                              color: "rgb(241, 162, 40)",
                            }}
                          >
                            Doctor
                          </h5>
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src={Food} height="250" width="200" />
                          <h5
                            style={{
                              textAlign: "center",
                              margin: "5px",
                              padding: "5px",
                              color: "rgb(241, 162, 40)",
                            }}
                          >
                            Food
                          </h5>
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src={Beauty} height="250" width="200" />
                          <h5
                            style={{
                              textAlign: "center",
                              margin: "5px",
                              padding: "5px",
                              color: "rgb(241, 162, 40)",
                            }}
                          >
                            Beauty
                          </h5>
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src={Education} height="250" width="200" />
                          <h5
                            style={{
                              textAlign: "center",
                              margin: "5px",
                              padding: "5px",
                              color: "rgb(241, 162, 40)",
                            }}
                          >
                            Education
                          </h5>
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src={Wedding} height="250" width="200" />
                          <h5
                            style={{
                              textAlign: "center",
                              margin: "5px",
                              padding: "5px",
                              color: "rgb(241, 162, 40)",
                            }}
                          >
                            Wedding
                          </h5>
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src={Telismith} height="250" width="200" />
                          <h5
                            style={{
                              textAlign: "center",
                              margin: "5px",
                              padding: "5px",
                              color: "rgb(241, 162, 40)",
                            }}
                          >
                            Telismith
                          </h5>
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src={Health} height="250" width="200" />
                          <h5
                            style={{
                              textAlign: "center",
                              margin: "5px",
                              padding: "5px",
                              color: "rgb(241, 162, 40)",
                            }}
                          >
                            Health
                          </h5>
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src={TravelService} height="250" width="200" />
                          <h5
                            style={{
                              textAlign: "center",
                              margin: "5px",
                              padding: "5px",
                              color: "rgb(241, 162, 40)",
                            }}
                          >
                            TravelService
                          </h5>
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src={Household} height="250" width="200" />
                          <h5
                            style={{
                              textAlign: "center",
                              margin: "5px",
                              padding: "5px",
                              color: "rgb(241, 162, 40)",
                            }}
                          >
                            Household
                          </h5>
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src={BusinessService} height="250" width="200" />
                          <h5
                            style={{
                              textAlign: "center",
                              margin: "5px",
                              padding: "5px",
                              color: "rgb(241, 162, 40)",
                            }}
                          >
                            BusinessService
                          </h5>
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src={Production} height="250" width="200" />
                          <h5
                            style={{
                              textAlign: "center",
                              margin: "5px",
                              padding: "5px",
                              color: "rgb(241, 162, 40)",
                            }}
                          >
                            Production
                          </h5>
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src={MedicineStore} height="250" width="200" />
                          <h5
                            style={{
                              textAlign: "center",
                              margin: "5px",
                              padding: "5px",
                              color: "rgb(241, 162, 40)",
                            }}
                          >
                            Medicine Store
                          </h5>
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src={Finance} height="250" width="200" />
                          <h5
                            style={{
                              textAlign: "center",
                              margin: "5px",
                              padding: "5px",
                              color: "rgb(241, 162, 40)",
                            }}
                          >
                            Finance Services
                          </h5>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </>
          </>
        </div>
      )}
    </>
  );
};

export default Home;
