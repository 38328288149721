import React, {  useState, useEffect } from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import { useDispatch, useSelector } from 'react-redux';
import { getTerms } from '../store/home';



const TermsCondition = () => {

  const dispatch = useDispatch();
  const [loaderStatus, setLoaderStatus] = useState(true);



  useEffect(() => {
    dispatch(getTerms());
  },[]);
  
  const terms = useSelector(state => state.home.Terms);
  console.log('termskalyani', terms.title);

  useEffect(() => {
    setTimeout(() => {
      setLoaderStatus(false);
    }, 2000); // Set loaderStatus to false after 3 seconds (adjust as needed)
  }, []);


  return (
    <>
     {loaderStatus ? (
      <div className="loader-container">
        <PulseLoader loading={loaderStatus} size={50} color="#36d7b7" />
      </div>
    ) : (
    <div>

    <section className="android ptb30" style={{overflow: 'hidden'}}>
  <div className="content-banner">
    <div className="container">
      <h3 style={{width: '100%', textAlign: 'left', color: 'white', textTransform: 'inherit', fontSize: '20', paddingTop: '3%'}}>Terms and Condition </h3>
    </div>
  </div>
  {/* <div class="mask flex-center">
                  <div class="container">
                      <div class="row">
                      <div class="col-md-6">
                          <div class="android-txt flex-center flex-column">
                              <div class="flex-center flex-column white-text text-center wow fadeInUp"> New Bharat Biz provides free access to micro, mini and small vendors to neibhorhood market at incredible simplicity . <br/>
THINK OF IT,  IT'S IN IT. <br/>
                              <div class="text-center pt20 animated fadeInUp"><a href="https://play.google.com/store/apps/details?id=com.newbharattech.newbharatbiz" target="_blank" class=""><img src="../new-site/img/android-icon.png" width="170px"> </a> <a href="https://itunes.apple.com/in/app/new-bharat-biz/id1335933323?mt=8" target="_blank" class=""><img src="../new-site/img/Apple-store.png" width="170px"> </a></div>
                              </div>
                          </div>
                      </div>
                      <div class="col-md-6">
                          <img src="../new-site/img/newbharat_mobile.png" class="img-fluid wow slideInDown animated" alt="android"> 
                      </div>
                  </div>
                  </div>
              </div>*/}
</section>
        <section className="smooth-scroll ptb60" id="about">
  <div className="container">
    <h3 className="heading3 h3">{terms.title}</h3>
    <div className="main-content" dangerouslySetInnerHTML={{ __html: terms.contents }}>
    </div>
  </div>
</section>
    </div>
    )}
    </>
  )
}

export default TermsCondition