import axios from "axios";

const token = localStorage.getItem("userDetails");

const authToken = token && JSON.parse(token).token;

export const nbbApi = axios.create({

  //Staging Api 
  //  base_URL: "https://newbharatbiz.in/mobile_api/v4/",
   baseURL: "https://newbharatbiz.in/new_cms/admin/index.php",
  
  headers: {
    // 'Access-Control-Allow-Origin': '*',
     'Accept': "application/json",
  },
});




