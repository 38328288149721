import { createSlice } from "@reduxjs/toolkit";
import { nbbApi } from "./axios";

//show the loader

// var userDetails = localStorage.getItem("userDetails")
// const userObj = JSON.parse(userDetails)

const intialAuthState = {
  Contact: [],
  contact_submit: [],
  vendorverify: [],
  vendorverifyfail: [],
  isLoading: false,
  error: null,
  otpSent: [],
  verify_status: false,
  isUserLoggedIn: false,
  isOtpSent: false,
  isUserLoggedIn: localStorage.getItem("isLoggedIn") === "true",
  isVendorLoggedIn: false,
  isVendorLoggedIn: localStorage.getItem("isVendorLoggedIn") === "true",

  // user suth states
  isOtpSentUser: false,
  otpSentUser: [],
  isLoadingUser: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: intialAuthState,
  reducers: {
    sendOtpRequest(state) {
      state.isLoading = true;
      state.error = null;
      state.otpSent = false;
      state.isOtpSent = true;
    },
    sendOtpSuccess(state, action) {
      state.isLoading = false;
      state.otpSent = action.payload;
    },
    sendOtpFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isOtpSent = false;
    },
    UsersendOtpRequest(state) {
      state.isLoadingUser = true;
      state.error = null;
      state.otpSentUser = false;
      state.isOtpSentUser = true;
    },
    UsersendOtpSuccess(state, action) {
      state.isLoadingUser = false;
      state.otpSentUser = action.payload;
    },
    UserSendOtpFailure(state, action) {
      state.isLoadingUser = false;
      state.error = action.payload;
      state.isOtpSentUser = false;
    },
    //Login reducer end
    VendorVerifySuccess(state, action) {
      state.vendorverify = action.payload;
      state.verify_status = true;
      console.log("pramodini", state.vendorverify);
      // state.loaderStatus = false;
    },
    VendorVerifyFailure(state, action) {
      state.vendorverifyfail = action.payload;
      state.verify_status = false;
      // state.loaderStatus = false;
    },
    userLogin(state) {
      state.isUserLoggedIn = true;
      localStorage.setItem("isLoggedIn", "true");
      // You can also set other user-related data here if needed
    },
    // Action for user logout
    userLogout(state) {
      localStorage.removeItem("user_id");
      localStorage.removeItem("formDataUserPhone");
      state.isUserLoggedIn = false;
      localStorage.setItem("isLoggedIn", "false");
      state.isOtpSentUser=false;

      // Clear other user-related data as needed
    },
    
    vendorLogin(state) {
      state.isVendorLoggedIn = true;
      localStorage.setItem("isVendorLoggedIn", "true");
    },
    vendorLogout(state) {
      localStorage.removeItem("formDataVendorPhone");
      state.isVendorLoggedIn = false;
      localStorage.setItem("isVendorLoggedIn", "false");
    },
  },
});

export const authActions = authSlice.actions;

export const sendOtp = (mobile) => {
  return async (dispatch) => {
    dispatch(authActions.sendOtpRequest());

    const formData = new FormData();
    formData.append("mobile", mobile);
    // const formDataObject = {};
    // formData.forEach((value, key) => {
    // formDataObject[key] = value;
    // });

    try {
      const response = await nbbApi.post("/send_otp", formData);
      
      if (response.data.response === "1") {
        dispatch(authActions.sendOtpSuccess(response.data));
        // localStorage.setItem("formDataVendorPhone", response.data.mobile);
      } else {
        dispatch(authActions.sendOtpFailure(response.data));
        // localStorage.removeItem('formDataVendorPhone')
      }
    } catch (error) {
      dispatch(authActions.sendOtpFailure("Failed to send OTP"));
    }
  };
};

export const UserSendOtp = (mobile) => {
  // console.log("pramodini", mobile);
  return async (dispatch) => {
    dispatch(authActions.UsersendOtpRequest());

    const formData = new FormData();
    formData.append("mobile", mobile);
    try {
      const response = await nbbApi.post("/send_otp_user", formData);

      if (response.data.response === "1") {
        dispatch(authActions.UsersendOtpSuccess(response.data));
        console.log('kalyani333333', response.data)
        localStorage.setItem("user_id", response.data.customer_id);
      } else {
        dispatch(authActions.UserSendOtpFailure(response.data));
      }
    } catch (error) {
      dispatch(authActions.UserSendOtpFailure("Failed to send OTP"));
    }
  };
};

export const getVendorVerify = (payload) => {
  //console.log('mobile',payload);
  return async (dispatch) => {
    try {
      const vendorData = new FormData();
      vendorData.append("mobile", payload);

      await nbbApi.post("/vendor_verify", vendorData).then((response) => {
        if (response.data.status === "1") {
          dispatch(authActions.VendorVerifySuccess(response.data));
          // localStorage.setItem("formDataVendorPhone", response.data.mobile);
          console.log("kalyanicontact", response);
        } else if (response.data.status === "0") {
          dispatch(authActions.VendorVerifySuccess(response.data));
          //console.log("kalyanicontactElse", response.data.message);
        } else {
          //  dispatch(authActions.VendorVerifyFailure(response.data.message));
          dispatch(authActions.VendorVerifySuccess(response.data));
          //console.log("kalyanicontactElseIf", response.data.message);
        }
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const userLogin = () => {
  return (dispatch) => {
    dispatch(authActions.userLogin());
  };
};

export const userLogout = () => {
  return (dispatch) => {
    dispatch(authActions.userLogout());
  };
};
export const vendorLogin = () => {
  return (dispatch) => {
    dispatch(authActions.vendorLogin());
  };
};

export const vendorLogout = () => {
  return (dispatch) => {
    dispatch(authActions.vendorLogout());
  };
};

export default authSlice.reducer;
