import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loginimg from "../Components/assets/img/loginvender.png";
import { UserSendOtp } from "../store/auth";
import { userProfile } from "../store/home";
import { userLogin, userLogout } from "../store/auth";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

import { Modal, ModalBody } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const UserLogin = () => {
  const navigate = useNavigate();
  const location=useLocation();
  const dispatch = useDispatch();
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isMobileValid, setIsMobileValid] = useState(true);
const [isOtpFieldValid, setIsOtpFieldValid] = useState(true);

const [timer, setTimer] = useState(60); // Initial timer value in seconds
const isUserLoggedIn=useSelector((state) => state.auth.isUserLoggedIn);
const handleTimerTick = () => {
  setTimer((prevTimer) => prevTimer - 1);
};

const startTimer = () => {
  setTimer(60); // Reset timer to 60 seconds
  const timerInterval = setInterval(() => {
    handleTimerTick();
  }, 1000);

  // Clear the interval after 60 seconds
  setTimeout(() => {
    clearInterval(timerInterval);
  }, 60000);
};

useEffect(() => {
  // Check if the OTP field is not empty
  setIsOtpFieldValid(otp.length > 0);
}, [otp]);

// ... (your existing code)


  // Redux state selectors
  const send_otp = useSelector((state) => state.auth.otpSentUser.otp);
  const isLoading = useSelector((state) => state.auth.isLoadingUser);
  const isOtpSent = useSelector((state) => state.auth.isOtpSentUser);
  console.log();
  useEffect(() => {
    // Check if mobile number is valid (e.g., 10 digits)
    if (mobileNumber.length === 10) {
      setIsMobileValid(true);
    }
  }, [mobileNumber]);

  useEffect(() => {
    // Check if mobile number is valid (e.g., 10 digits)
    if (isUserLoggedIn) {
      navigate("/")
    }
  });
  
const handleInputChange = (e) => {
  const numericValue = e.target.value.replace(/\D/g, "");
  setMobileNumber(numericValue);
  setIsMobileValid(numericValue.length === 10);
};

  const handlesendotp = () => {
    // Get the mobile number from the input field
    const mobile = document.getElementById("mobile_number").value;

    // Check if the mobile number is empty
    if (!mobile.trim()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a valid mobile number.",
      });
      return; // Exit the function if the mobile number is empty
    }

    // Create a FormData object

    // Store the JSON object in local storage
    localStorage.setItem("formDataUserPhone", mobile);
    // Dispatch the sendOtp action (if needed)
        startTimer();

    dispatch(UserSendOtp(mobile));
  };


   const [resendLoading, setResendLoading] = useState(false);

   const [modalOpen, setModalOpen] = useState(false);
   const [modalContent, setModalContent] = useState({});

   const handleResendOtp = async () => {
     setResendLoading(true);

     // Get the mobile number from local storage
     const mobile = localStorage.getItem("formDataUserPhone");

     try {
       // Dispatch the sendOtp action
       await dispatch(UserSendOtp(mobile));

       // Set success message to be displayed in the modal
       setModalContent({
         title: "OTP Resent!",
         text: "OTP has been resent successfully.",
         icon: "success",
       });

       // Open the modal
       setModalOpen(true);
     } catch (error) {
       // Set error message to be displayed in the modal
       setModalContent({
         title: "Oops...",
         text: "Failed to resend OTP. Please try again.",
         icon: "error",
       });

       // Open the modal
       setModalOpen(true);
     } finally {
       setResendLoading(false);
     }
   };

   useEffect(() => {
     // Automatically close the modal after 1 second
     if (modalOpen) {
       const timer = setTimeout(() => {
         setModalOpen(false);
       }, 5000);

       // Cleanup the timer on component unmount or if modal is closed manually
       return () => clearTimeout(timer);
     }
   }, [modalOpen]);

   const toggleModal = () => {
     // Close the modal
     setModalOpen(!modalOpen);
   };

  const verifyOtp = () => {
    const enteredOtp = document.getElementById("otp_v").value;
    if (send_otp === enteredOtp) {
      console.log("enteredOtp", enteredOtp);
      console.log("Successfully logged in");
      dispatch(userLogin());
      Swal.fire({
        title: "Success!",
        html: `
      <div>${"LOGIN SUCCESSFULL"}</div>
         <div id="timer">Redirecting to Profile page in <span id="countdown">5</span> seconds</div>`,
        icon: "success",
        timer: 5000, // 5000 milliseconds (5 seconds)
        timerProgressBar: true,
        didOpen: () => {
          const countdownElement = document.getElementById("countdown");
          const timerInterval = setInterval(() => {
            const countdown = parseInt(countdownElement.textContent, 10);
            if (countdown > 1) {
              countdownElement.textContent = (countdown - 1).toString();
            } else {
              clearInterval(timerInterval);
            }
          }, 1000);
        },
        didClose: () => {
          // Redirect to the "user_login" page
          // navigate("/user_profile");
          const currentPath = location.pathname;
          switch (currentPath) {
            case "/user_registration":
            case "/user_login":
              navigate("/user_profile");
              break;
            default:
              // Handle unknown paths here
              window.history.back();
           }          // You can use your routing method here
        },
      });
    } else {
      // Handle failed login attempt (e.g., display an error message to the user)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "LOGIN FAILED!",
        html: `<div style={{color:"red" , fontSize:"10px"}}>Please Check Entered <b>OTP</b> is correct or not</div>`,
      });
    }
  };
  return (
    <div className="page-header kalyani">
    <div className="container-fluid p-0">
      <div className="top-left">
    <div className="page-wraper">
      <div className="page-content pb-5">
        <div className="container col-md-6 col-md-12 pb-5 register">
          <div className="container col-md-6 registerhead">
            <h1 className="registerhead"> 
             User Login
            </h1>
          </div>
          <div className="container">
            <div className="row row-cols-1 row-cols-md-3 g-4">
              <div className="col">
              <div class="card h-100">
                <div className="container-form">
                  <div className="form-item log-in">
                    <div className="table">
                      <div className="table-cell text-center" id="property">
                        <form>
                          <p />
                          {/* <h5 className="heading5 mb-2"> */}
                            <img src={Loginimg} width="200px" alt="Login" />
                          {/* </h5> */}
                          {isOtpSent ? (
                            <>
                              <input
                                name="otp"
                                placeholder="Enter OTP"
                                type="text"
                                className={`form-control ${
                                  isOtpFieldValid ? "" : "is-invalid"
                                }`}
                                maxLength={4}
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                required
                                id="otp_v"
                              />
                              <button
                                type="button"
                                id="verify_otp"
                                className="btn"
                                onClick={verifyOtp}
                                disabled={!isOtpFieldValid} // Disable the button if OTP field is not valid
                              >
                                Verify OTP
                              </button>
                              <br></br>
                              <Link
                                id="resend_otp"
                                className={`${
                                  resendLoading || timer > 0 ? "disabled" : ""
                                }`}
                                onClick={handleResendOtp}
                              >
                                Resend OTP {timer > 0 && `(${timer}s)`}
                              </Link>

                              <Modal
                                isOpen={modalOpen}
                                toggle={toggleModal}
                                centered
                              >
                                <ModalBody>
                                  <div className={`text-${modalContent.icon}`}>
                                    <h5>{modalContent.title}</h5>
                                    <p>{modalContent.text}</p>
                                  </div>
                                </ModalBody>
                              </Modal>
                              {!isOtpFieldValid && (
                                <div className="invalid-feedback">
                                  Please enter a valid OTP.
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <input
                                name="Username"
                                id="mobile_number"
                                placeholder="Enter Mobile Number"
                                type="tel"
                                className="form-control"
                                maxLength={10}
                                value={mobileNumber}
                                onChange={handleInputChange}
                                required
                              />
                              <button
                                type="button"
                                onClick={handlesendotp}
                                id="send_otp"
                                className="btn"
                                disabled={!isMobileValid || isLoading} // Disable the button if mobile number is not valid or during loading
                              >
                                Send OTP
                              </button>

                              {!isMobileValid && (
                                <div className="invalid-feedback">
                                  Please enter a valid mobile number.
                                </div>
                              )}
                            </>
                          )}
                        </form>
                        <div className="table-cell p-2">
                          New user?{" "}
                          <a
                            href="/user_registration"
                            style={{ color: "#00cc95" }}
                          >
                            Register now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default UserLogin;