import React, { useState, useEffect } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import img from "../Components/assets/img/MedicineStore.jpg";
import img1 from "../Components/assets/img/Service/Utkal.png";
import img2 from "../Components/assets/img/Service/Patra.png";
import img3 from "../Components/assets/img/Service/Ayush.png";
import img4 from "../Components/assets/img/Service/Furniture.png";
import img5 from "../Components/assets/img/Service/Sai.png";
import img6 from "../Components/assets/img/Service/Decorer.png";
import Slider from "react-slick";
import map from "../Components/assets/img/map1.png";
import mobileApp from "../Components/assets/img/googleapp.png";
import playstore from "../Components/assets/img/playstore.png";
import ios from "../Components/assets/img/ios.png";
import { useDispatch, useSelector } from 'react-redux';
import { getAbout, getTopServicesCategory } from '../store/home';
import { Helmet } from 'react-helmet';
import SiteLogo from "../Components/assets/img/logo.png";


const About = () => {

  const dispatch = useDispatch();
  const [loaderStatus, setLoaderStatus] = useState(true);



  useEffect(() => {
    dispatch(getAbout());
  },[]);
  
  const about = useSelector(state => state.home.About);
  console.log('aboutkalyani', about.title);


  useEffect(() => {
    dispatch(getTopServicesCategory());
  },[]);
  
  const TopServices = useSelector(state => state.home.TopServicesCategory);
  console.log('servicessimg', TopServices?.['0']?.image);
  

  const TopServicesBannerpath = useSelector(state => state.home.TopservicesBannrpath);
  // console.log('topservicesbannerpath', TopServicesBannerpath);




  useEffect(() => {
    setTimeout(() => {
      setLoaderStatus(false);
    }, 2000); // Set loaderStatus to false after 3 seconds (adjust as needed)
  }, []);



  const settings = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    autoplay: true,
    autoplaySpeed: 2000,
  };



  const siteUrl = window.location.href;
  
  return (
    <>
      {loaderStatus ? (
        <div className="loader-container">
          <PulseLoader loading={loaderStatus} size={50} color="#36d7b7" />
        </div>
      ) : (
        <div style={{overflowX: 'hidden'}}>
          <section className="android " style={{ overflow: "hidden" }}>
            <div className="content-banner">
              <div className="container">
                <h3
                  style={{
                    width: "100%",
                    textAlign: "left",
                    color: "white",
                    textTransform: "inherit",
                    fontSize: "20px",
                    paddingTop: "3%",
                  }}
                >
                  About
                </h3>
              </div>
            </div>
          </section>

          <div className="section-full p-t80 bg-gray " id="about">
            <div className="section-head1 text-center">
              <h3 data-title="About us">{about.title}</h3>
              <Helmet>
                          <meta charSet="utf-8" />
                          <title>"New Bharat Biz - Get Expert Professional Services near by you."</title>
                          <meta name="description" content="New Bharat Biz: Connecting Small Entrepreneurs, Get connected to business owners around you and build a Biz network, business listing, New Bharat Biz mobile app help you grow." />
                          <meta property="og:type" content="website" />
                          <meta property="og:url" content={siteUrl} />
                          <meta property="og:title" content="Connecting 40,000+ Small and Micro Service Providers." />
                          <meta property="og:description" content="New Bharat Biz: Connecting Small Entrepreneurs, Get connected to business owners around you and build a Biz network, business listing, New Bharat Biz mobile app help you grow." />
                          <meta property="og:image" content={SiteLogo} />
                        </Helmet>
              <div className="wt-separator bg-primarys" />
              <div className="wt-separator2 bg-primarys" />

              <div className="container overflow-hidden text-center">
                <div className="row gx-5">
                  <div className="col">
                    <div className="p-3" style={{ textAlign: "justify" }}>
                      <p dangerouslySetInnerHTML={{ __html: about.contents }}>
                      {/* {about.contents} */}
                      </p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="p-3">
                      <img src={img} alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <>
            <div className="container p-b50">
              <div className="section-full section-head ">
                <h1 style={{ textAlign: "center" }}>Our Team</h1>
                <div className="wt-separator bg-primary" />
              <div className="wt-separator2 bg-primary" />
                <p style={{ textAlign: "center" }}>
                  Praesent posuere dolor id sapien finibus ultricies. Aenean ac
                  magna vestibulum.
                  <br />
                  consequat nibh laoreet, lacinia sapien. Ut eu pellentesque
                  orci.
                </p>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                  <div className="col">
                    <div className="card" style={{border: 'none'}}>
                      <img
                        src="http://www.aivahthemes.com/wp/ultranet/wp-content/uploads/sites/16/2017/03/team1.jpg"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body" style={{textAlign:'center'}}>
                        <h4 className="card-title">Marry Copper</h4>
                        <p className="card-text">Founder & CEO</p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card"  style={{border: 'none'}}>
                      <img
                        src="http://www.aivahthemes.com/wp/ultranet/wp-content/uploads/sites/16/2017/03/team7.jpg"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body" style={{textAlign:'center'}}>
                        <h5 className="card-title">Alex Rodriges</h5>
                        <p className="card-text">On Site Manager</p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card"  style={{border: 'none'}}>
                      <img
                        src="http://www.aivahthemes.com/wp/ultranet/wp-content/uploads/sites/16/2017/03/team6.jpg"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body" style={{textAlign:'center'}}>
                        <h5 className="card-title">Alex Rodriges</h5>
                        <p className="card-text">On Site Manager</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </> */}
          
          <>
            <div
              className="row text-center section-full p-t80 p-b50 bg-gray"
              style={{
                backgroundColor: "rgb(143 143 143)",
                // paddingBottom: "50px",
                margin: "auto",
              }}
            >
              <div
                className="col-sm-6 col-md-8"
                style={{ fontSize: "25px", color: "white" }}
              >
                40,000+ People trusted NewBharatBiz! Be one of them today.
              </div>
              <div className="col-6 col-md-4" style={{margin: 'auto'}}>
                <a
                  href="/vendor_registration"
                  type="button"
                >
                   <button type="button" className="btn btn-get-started  scrollto" >Vender Register</button>
                </a>
               
              </div>
            </div>
          </>

         
          <>
            <div className="container aboutapp p-t80 p-b55">
              <div
                className="section-full col section-head"
                style={{ textAlign: "center" }}
              >
                <h1>New Bharat Biz goApp</h1>
                 <div className="wt-separator bg-primarys" />
               <div className="wt-separator2 bg-primarys" />
                <p>
                  New Bharat Biz understands the magic of the neighborhood
                  market place <br /> both for the suppliers of goods and
                  services and their customers.{" "}
                </p>
              </div>
              {/* <div className="container overflow-hidden text-center"> */}
              <div className="container text-center">
                <div className="row gx-5">
                  <div className="col" style={{margin: 'auto'}}>
                  {/* <div className="row row-cols-1 row-cols-md-2 g-4"> */}
                  <div className="row">
              <div className="col-sm-6" style={{display: "flex"}}>
                <div className="card col-md-6" style={{backgroundColor: 'transparent', border: 'none'}}>
                  <div className="card-body">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.newbharattech.newbharatbiz&hl=en&gl=US"
                      target="_blank"
                    >
                      <img src={playstore} className="card-img-top" alt="playstore" style={{height: '43px', width: 'auto'}}/>
                    </a>
                  </div>
                  {/* <div className="card-body">
                  <img src={ios} className="card-img-top" alt="ios" style={{height: '43px', width: 'auto'}}/>
                  </div> */}
                </div>
                <div className="card col-md-6" style={{backgroundColor: 'transparent', border: 'none'}}>
                  <div className="card-body">
                    <a
                      href="https://apps.apple.com/us/app/new-bharat-biz-app/id6480580383"
                      target="_blank"
                    >
                      <img src={ios} className="card-img-top" alt="ios" style={{height: '43px', width: 'auto'}}/>
                    </a>
                  </div>
                  {/* <div className="card-body">
                  <img src={ios} className="card-img-top" alt="ios" style={{height: '43px', width: 'auto'}}/>
                  </div> */}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card" style={{backgroundColor: 'transparent', border: 'none'}}>
                  <div className="card-body">
                  <img src={mobileApp} className="image-fluid" style={{width:'60%'}} />
                  </div>
                </div>
              </div>
            </div>
                  </div>
                  {/* <div className="col" style={{margin: 'auto'}}> 
                    <div className="p-3">
                      <img src={mobileApp} className="image-fluid"/>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </>
        
{/* TOP 10 SERVICE START */}
          <>
            <div className="site-section p-t80 p-b80 bg-light" style={{paddingBottom: "50px"}}>
              <div className="container">
                <div className="overlap-category mb-5">
                  <div className="row align-items-stretch no-gutters">
                    <Slider {...settings}>
                    {TopServices?.map((topservice) => (
                      <div className="col-sm-6 col-md-4 mb-4 mb-lg-0 col-lg-2 cardpadding" style={{'marginLeft': '8px'}}>
                        <a href="#" className="popular-category h-100">
                          <span className="icon"><span className="flaticon-house" />

                          <img src={TopServicesBannerpath + topservice.image} className="centered-image" alt="Utkal Air Voice TV"/>
                          </span>
                          <span className="caption mb-2 d-block">{topservice.service_name}</span>
                          {/* <span className="number">3,921</span> */}
                        </a>
                      </div>     
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </>
{/* TOP 10 SERVICE END */}

        </div>
      )}
    </>
  );
};

export default About;
