import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Pages/Home';
import RegistrationForm from './Pages/RegistrationForm';
import Login from './Pages/Login';
import PhotoUpload from './Pages/PhotoUpload';
import About from './Pages/About';
import TermsCondition from './Pages/TermsCondition';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Services from './Pages/Services';
import Contact from './Pages/Contact';
import Form from './Pages/Form';
import SearchService from './Pages/SearchService';
import VendorProfile from './Pages/VendorProfile';
import VendorRegistration from './Pages/VendorRegistration';
import UserRegistration from './Pages/UserRegistration';
import UserProfile from './Pages/UserProfile';
import UserLogin from './Pages/UserLogin';
import 'spiketip-tooltip/spiketip.min.css';
import ProfileEdit from './Pages/ProfileEdit';
import VendorGallery from './Pages/VendorGallery';

function App() {
  // const weburl = "https://newbharatbiz.in/staging/rnbb";
  // useEffect(() => {
  //   // Add event listener to block right-click
  //   const handleContextMenu = (e) => {
  //     e.preventDefault();
  //   };

  //   document.addEventListener('contextmenu', handleContextMenu);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     document.removeEventListener('contextmenu', handleContextMenu);
  //   };
  // }, []);
  return (
    //  <Router basename={'/staging/rnbb/'}>
  <Router basename={"/"}>   
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/register" element={<RegistrationForm />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register/uploadfile" element={<PhotoUpload />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/terms_condition" element={<TermsCondition />} />
        <Route exact path="/privacy" element={<PrivacyPolicy />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/form" element={<Form />} />
        <Route exact path="/search_service" element={<SearchService />} />
        <Route exact path="/search_service/user_login" element={<UserLogin />} />
        <Route exact path="/vendor_profile" element={<VendorProfile />} />
        <Route exact path="/vendor_registration" element={<VendorRegistration />} />
        <Route exact path="/user_registration" element={<UserRegistration />} />
        <Route exact path="/user_login" element={<UserLogin />} />
        <Route exact path="/user_profile" element={<UserProfile />} />
        <Route exact path="/profile" element={<ProfileEdit />} />
        <Route exact path="/search_service/vendor_gallery" element={<VendorGallery />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
