import React, { useState, useEffect } from 'react';
import darklogo from '../Components/assets/img/Darklogo.png';
import Facebook from '../Components/assets/img/Facebook.png';
import Twitter from '../Components/assets/img/Twitter.png';
import Linkdin from '../Components/assets/img/Linkdin.png';
import Instagram from '../Components/assets/img/Instagram.png';
import { Link } from 'react-router-dom';
import { FaArrowUp } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { getFooter } from '../store/home';


const Footer = () => {
  const dispatch = useDispatch();
  const [year, setYear] = useState(new Date().getFullYear());


  useEffect(() => {
    const interval = setInterval(() => {
      setYear(new Date().getFullYear());
    }, 1000 * 60 * 60 * 24); // Update every day

    return () => {
      clearInterval(interval);
    };
  }, []);



 // scroll to top button start


 const [isVisible, setIsVisible] = useState(false);

 useEffect(() => {
   window.addEventListener('scroll', handleScroll);
   return () => {
     window.removeEventListener('scroll', handleScroll);
   };
 }, []);

 const handleScroll = () => {
   const scrollY = window.scrollY;
   const isVisible = scrollY > 100;
   setIsVisible(isVisible);
 };

 const handleScrollToTop = () => {
   window.scrollTo({ top: 0, behavior: 'smooth' });
 };


 useEffect(() => {
  dispatch(getFooter());
}, []);

const footerdata = useSelector((state) => state.home.footer);
// console.log('footerdatakalyani', footerdata.description);



 // scroll to top button end
  return (
    <div>
      <footer
        className="site-footer footer-large footer-dark text-white"
        style={{ display: "block" }}
      >
        {/* FOOTER BLOCKES START */}
        <div className="footer-top">
          <div className="container">
          {/* {footerdata?.map((footerdesc) => ( */}
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="widget widget_about">
                  <div className="logo-footer clearfix p-b15">
                    <Link to="#">
                      <img
                        src={darklogo}
                        alt="darklogo"
                        style={{ height: "70px" }}
                      />
                    </Link>
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: footerdata.description }}></p>
                  {/* <p style={{ textAlign: "justify" }}>
                    New Bharat Biz is a local mobile search app which enables
                    you as a customer to search for anything at anytime in your
                    Neighbourhood. For the user finding any required services
                    locally available is a click away. So far we have identified
                    more than 200 categories in which you might seek for
                    services or goods. More categories are added continually.
                  </p> */}
                  <div className="widget widget_social_inks">
                    <ul className="list-unstyled socials">
                    
                      <li>
                        <a
                          classname="facebook"
                          to="https://www.facebook.com/newbharatbizapplication/"
                          target="_blank"
                          onClick={handleScrollToTop}
                        >
                          <img src={Facebook} style={{ height: 33 }} />{" "}
                        </a>
                      </li>
                      
                      <li>
                        <a
                          to="https://twitter.com/NewbharatB"
                          target="_blank"
                          onClick={handleScrollToTop}
                        >
                          <img src={Twitter} style={{ height: 33 }} />{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          to="https://www.linkedin.com/in/newbharat-biz/"
                          target="_blank"
                          onClick={handleScrollToTop}
                        >
                          <img src={Linkdin} style={{ height: 33 }} />{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          to="https://www.instagram.com/newbharat_biz/"
                          target="_blank"
                          onClick={handleScrollToTop}
                        >
                          <img src={Instagram} style={{ height: 33 }} />{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="recent-posts-entry">
                  <h4
                    className="widget-title  text-uppercase"
                    style={{ marginLeft: 49 }}
                  >
                    Quick Links
                  </h4>
                  <div className=" section-content" style={{paddingLeft: '2rem', color: 'white'}}>
                  <p dangerouslySetInnerHTML={{ __html: footerdata.description_second }}></p>
                    {/* <ul className="service-item">
                      <li>
                        <a className='footercolor' href="/" onClick={handleScrollToTop}>Home</a>
                      </li>
                      <li>
                        <a className='footercolor' href="/about" onClick={handleScrollToTop} target="_blank">About</a>
                      </li>
                      <li>
                        <a  className='footercolor' href="/terms_condition" onClick={handleScrollToTop} target="_blank">Terms & Condition</a>
                      </li>
                      <li>
                        <a className='footercolor' href="/privacy" onClick={handleScrollToTop} target="_blank">Privacy Policy</a>
                      </li>
                      
                      <li>
                        <a className='footercolor' href="/contact" onClick={handleScrollToTop} target="_blank">Contact</a>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className=" widget_services">
                  <h4 className="widget-title">Contact Detailes</h4>
                </div>
                <p dangerouslySetInnerHTML={{ __html: footerdata.description_third }}></p>
                {/* <ul className="widget_address" style={{listStyleType:"none"}}>
                  <li>
                  <i className="fa fa-phone"></i>
                  &nbsp;
                    <b className="p-lr5">Tel:</b> +91 674 2567899
                  </li>
                  <li>
                  <i className="fa fa-envelope"></i>
                  &nbsp;
                    <b className="p-lr5">Email:</b> info@newbharatbiz.in
                  </li>
                  <li>
                  <i className='fas fa-map-marker-alt'></i>
                  &nbsp;
                    <b className="p-lr5">Location:</b> OCAC Tower,Bhubaneswar
                  </li>
                </ul> */}
                {/* SOCIAL LINKS */}
              </div>
            </div>
            {/* ))} */}
          </div>
        </div>
        {/* FOOTER COPYRIGHT */}
        <div className="footer-bottom bg-dark">
          <div className="container">
            <div className="row">
              <div className="wt-footer-bot-center m-b10">
                <span className="copyrights-text">
                  &copy; {year} newbharatbiz. All Rights Reserved.Powered by{" "}
                  <Link className='footercolor' to="https://www.wayindia.com" target="_blank" >
                    Wayindia
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>

        <>
          {isVisible && (
            <div
              className="back-to-top d-flex align-items-center justify-content-center active"
              onClick={handleScrollToTop}
            >
              <FaArrowUp className="scroll-to-top-icon" />
            </div>
          )}
        </>
      </footer>
      {/* FOOTER END */}
    </div>
  );
}

export default Footer