import React, { useState, useEffect } from "react";

const PhotoUpload = () => {
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState("");

  const [video, setVideo] = useState(null);
  const [videoError, setVideoError] = useState("");



  useEffect(() => {
    // You can perform additional validations or actions here
    if (image) {
      // Perform API call or further validation if needed
    }
  }, [image]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    // const selectedImage = event.target.files[0];

    // Validate the image here

    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        setImage(null);
        setImageError("Only JPEG and PNG formats are allowed.");
      } else {
        setImage(file);
        setImageError(""); // Clear the error if it's valid
      }
    } else {
      setImage(null);
      setImageError("Please select an image.");
    }
  };


  const handleVideoChange = (event) => {
    const file = event.target.files[0];

    // Validate the video here
    if (file) {
      const allowedTypes = ["video/mp4", "video/webm", "video/ogg"];
      if (!allowedTypes.includes(file.type)) {
        setVideo(null);
        setVideoError("Only MP4, WebM, and Ogg video formats are allowed.");
      } else {
        setVideo(file);
        setVideoError(""); // Clear the error if it's valid
      }
    } else {
      setVideo(null);
      setVideoError("Please select a video.");
    }
  };



  return (
    <div className="page-wraper">
      <div className="page-content">
        <div className=" container col-md-12 col-md-12 pb-5 register">
          <div className=" col-md-6 registerhead">
            <h1 className="registerhead">
              <i>Register</i>
            </h1>
          </div>
          <div className="container col-md-7 registerhead1">
            <div class="row">
              <div class="col-sm-6 mb-3 mb-sm-0">
                <div class=" center1">
                  <div class="card-body">
                    <h5 class="card-title m-1 p-1">Drop image to upload</h5>
                    <hr />
                    <div className="col-md-12">

                    <div className="dropzone">
                      
                      {image ? (
                        <img controls width="100%" height="auto"
                          src={URL.createObjectURL(image)}
                          alt="Uploaded"
                          className="upload-icon"
                        />
                      ) : (
                        <img src="upload-icon.png" alt="Image" className="upload-icon" 
                        />
                      )}
                    </div>
                      <form className="myForm" noValidate autoComplete="off">
                      <div class="form-group">
                        <p>
                          <label className="reglabel">
                            <input
                              type="file"
                              accept="image/jpeg, image/png"
                              name="image"
                              onChange={handleImageChange}
                            />
                          </label>
                          <br/>
                          <label className="error" style={{fontSize: '12px', color: 'red'}} >{imageError}</label>
                        </p>
                      </div>
                        <p>
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => console.log("Submit", image)}
                          >
                            Upload
                          </button>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 mb-3 mb-sm-0">
                <div class="center1">
                  {/* <div class="card"> */}
                    <div class="card-body">
                      <h5 class="card-title  m-1 p-1">Drop Video to Upload</h5>
                      <hr />
                      <div className="col-md-12">
                      <div class="dropzonevideo">
                        {video ? (
                          // <video controls width="100%" height="auto">
                          <video controls width="100%" height="80px">
                            <source src={URL.createObjectURL(video)} type={video.type} />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img src="video-icon.png" alt="Video" className="upload-icon" />
                        )}
                      </div>

                        <form className="myForm" noValidate autoComplete="off">
                          <div class="form-group">
                            <p>
                              <label className="reglabel">
                                <input
                              type="file"
                              class="form-control-file"
                              id="videoInput"
                              accept="video/mp4, video/webm, video/ogg"
                              name="video"
                              onChange={handleVideoChange}
                            />
                              </label>
                              <br/>
                              <label className="error" style={{fontSize: '12px', color: 'red'}} >{videoError}</label>
                            </p>
                            {/* <label for="videoInput">Choose Video</label> */}
                          </div>
                          <p>
                          <button
                            type="button"
                            class="btn btn-info"
                            onClick={() => console.log("Submit", video)}
                          >
                            Upload
                          </button>
                          </p>
                        </form>
                        </div>
                    </div>
                  {/* </div> */}
                </div>
              </div>

              <br/>

              <div className='m-0 btndesgn' >
          <button type="submit" className=" btn-aque m-0"  style={{width: 136}}>
            <a href="/register">PREVIOUS</a></button>
          <button type="submit" className="btn btn-primary m-0" style={{width: 136}}>SUBMIT</button>
          </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoUpload;
