import React, { useState, useEffect } from "react";
// import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { userRegistration } from "../store/home";
import { useNavigate } from "react-router-dom";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export default function UserRegistration() {
  const [formData, setFormData] = useState({
    username: "",
    mobile: "",
    email: "",
    tandc: false, 
  });

  const [userLocation, setUserLocation] = useState("");

  const [errors, setErrors] = useState({
    username: "",
    mobile: "",
    email: "",
    tandc: "",
  });

  const dispatch = useDispatch();

  const [suggestions, setSuggestions] = useState([]);

  const handleLocationChange = (address) => {
    setUserLocation(address);

    geocodeByAddress(address)
      .then((results) => {
        // Extract and set latitude and longitude
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        // Do something with latLng if needed
      })
      .catch((error) => {
        console.error("Error in geocoding:", error);
      });
  };

  const handleLocationSelect = (address) => {
    // Handle the selection of the location from the dropdown
    handleLocationChange(address);
  };

  const renderSuggestions = () =>
    suggestions.map((suggestion) => (
      <div
        key={suggestion.id}
        onClick={() => handleLocationSelect(suggestion.description)}
      >
        {suggestion.description}
      </div>
    ));

  const getUserLocation = () => {
    // ... (previous code)

    if (navigator.geolocation) {
      // ... (previous code)

      const input = document.getElementById("location-input"); // Change to the actual ID of your location input field

      const autocomplete = new window.google.maps.places.Autocomplete(input);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        handleLocationChange(place.formatted_address);
      });
    }
  };
  useEffect(() => {
    getUserLocation();
  }, []);

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });

    // Reset the error message for the current field
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = () => {
    let isValid = true;

    if (formData.username.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        username: "Username is required",
      }));
      isValid = false;
    }

    if (formData.mobile.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "Mobile number is required",
      }));
      isValid = false;
    }

    if (!/^\d{10}$/.test(formData.mobile.trim())) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "Mobile number must be 10 digits",
      }));
      isValid = false;
    }

    if (formData.email.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required",
      }));
      isValid = false;
    }

    if (!/\S+@\S+\.\S+/.test(formData.email.trim())) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email format",
      }));
      isValid = false;
    }

    if (!formData.tandc) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        tandc: "Please accept the terms and conditions",
      }));
      isValid = false;
    }

    return isValid;
  };

  const navigate = useNavigate();

  const OnSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const formDataObject = new FormData();
      formDataObject.append("user_name", formData.username);
      formDataObject.append("mobile", formData.mobile);
      formDataObject.append("email", formData.email);
      formDataObject.append("address", userLocation);
      formDataObject.append("tandc", formData.tandc ? 1 : 0);

      dispatch(userRegistration(formDataObject,navigate));
    } else {
      // Form validation failed, display a simple alert.
      alert("User registration failed. Please try again.");
    }
  };

  return (
    <div className="page-header kalyani">
    <div className="container-fluid p-0">
      <div className="top-left">
      <div className="page-wraper ">
        <div className="page-content ">
          <div className="container col-md-6 registerhead">
            <h1 className="registerhead">
             User Register
            </h1>
          </div>
          <div className="container col-md-7 registerhead1">
            <div className="row">
              <div className="col-md-12">
                <div className="dashboard-profile-section clearfix">
                  <div className="dasboard-profile-form overflow-hide">
                    <form onSubmit={OnSubmit}>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Name
                            <span className="req">*</span>
                          </label>
                          <div className="ls-inputicon-box">
                            <input
                              type="text"
                              className="form-control"
                              name="username"
                              placeholder="Enter your name"
                              value={formData.username}
                              onChange={handleInputChange}
                              required
                            />
                            <i className="fs-input-icon sl-icon-user" />
                          </div>
                          {errors.username && (
                            <span className="error">{errors.username}</span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                           Mobile
                            <span className="req">*</span>
                          </label>
                          <div className="ls-inputicon-box">
                            <input
                              type="tel"
                              className="form-control"
                              name="mobile"
                              placeholder="Enter mobile"
                              value={formData.mobile}
                              onChange={handleInputChange}
                              required
                              maxLength={10}
                            />
                            <i className="fs-input-icon sl-icon-layers" />
                          </div>
                          {errors.mobile && (
                            <span className="error">{errors.mobile}</span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Email
                            <span className="req">*</span>
                          </label>
                          <div className="ls-inputicon-box">
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="Enter Email ID"
                              value={formData.email}
                              onChange={handleInputChange}
                              required
                            />
                            {errors.email && (
                              <span className="error">{errors.email}</span>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            <i>Location</i>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="location"
                            placeholder="Location"
                            value={userLocation}
                            onChange={(e) => setUserLocation(e.target.value)}
                            readOnly
                          />
                          {errors.location && (
                            <span className="error">{errors.location}</span>
                          )}
                        </div>
                      </div> */}

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Location
                            <span className="req">*</span>
                          </label>
                          <PlacesAutocomplete
                            value={userLocation}
                            onChange={handleLocationChange}
                            onSelect={handleLocationSelect}
                          >
                            {({ getInputProps, suggestionsRenderer }) => (
                              <div>
                                <input
                                  id="location-input"
                                  {...getInputProps({
                                    placeholder: "Type your location",
                                    className: "form-control",
                                  })}
                                  required
                                />
                                <div className="autocomplete-dropdown-container">
                                  {renderSuggestions()}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          {errors.location && (
                            <span className="error">{errors.location}</span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            
                            <span className="req">*</span>
                          </label>
                          <div className="ls-inputicon-box">
                            <label className="checkbox-label">
                              <input
                                type="checkbox"
                                name="tandc"
                                checked={formData.tandc}
                                onChange={handleInputChange}
                              />
                              <a
                                href="/terms_condition"
                                target="_blank"
                                spiketip-title="Read Terms and Conditions"
                                spiketip-pos="bottom"
                              >
                                &nbsp;
                                I agree with terms and conditions
                              </a>
                            </label>
                          </div>
                          {errors.tandc && (
                            <span className="error">{errors.tandc}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button type="submit" className="btn btn-primary">
                          Register
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
}
