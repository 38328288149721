import { useRef, useEffect } from "react";
const AutoComplete = (props) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: "in" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
  };
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      console.log({ place });
      console.log(place);
      props.onChange(place);
      console.log(autoCompleteRef.current.value);
      console.log("kalyaniAutoLocation",inputRef.current.value);
    });
  }, []);
  return (
    <div className="ls-inputicon-box">
      <input
        className="form-control"
        id="address"
        type="address"
        name="address"
        ref={inputRef}
      />
    </div>
  );
};
export default AutoComplete;