import React, { useState, useEffect, useMemo } from "react";
import Slider from "react-slick";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-autoplay.css";
import "lightgallery/css/lg-share.css";
import "lightgallery/css/lg-rotate.css";
import bannerimg from "../Components/assets/img/nbb-bannerimg15.jpg";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgAutoplay from "lightgallery/plugins/autoplay";
import lgVideo from "lightgallery/plugins/video";
import lgShare from "lightgallery/plugins/share";
import lgRotate from "lightgallery/plugins/rotate";
import LightGallery from "lightgallery/react/Lightgallery.es5";
import { nbbApi } from "../store/axios";
import { useDispatch, useSelector } from "react-redux";
import { getVendorGallery, getVendorBanner, FetchRating } from "../store/home";
import galleryimg from "../Components/assets/img/Gallery.jpg";
import vendorGalleryImg from "../Components/assets/img/VendorGalleryBanner3.jpg";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import vendorProfileimg from "../Components/assets/img/user-icon.png";
import PulseLoader from "react-spinners/PulseLoader";
import { useLocation } from "react-router-dom";
import usericon from '../Components/assets/img/usericon_r.png';
import {giveratingnreview} from "../store/home";
import ratingimg from '../Components/assets/img/rating.jpg';


const VendorGallery = () => {
  const dispatch = useDispatch();

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // const [galleryData, setGalleryData] = useState([]);
  // const [galleryPath, setGalleryPath] = useState([]);

  // const phone = localStorage.getItem("formDataVendorPhone");
  //   const id = 35071
  const isUserLoggedStatus = useSelector((state) => state.auth.isUserLoggedIn);
  const ratingmsg = useSelector((state) => state.home.ratingmsg);
  const [ProfileImagePathPro,setProfileImagePathPro]=useState([])
  const [ProfileImageContDataPro, setProfileImageContDataPro] = useState([])
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const location = useLocation();
  const phone = location.state.phone;
  const serviceId = location.state.srvcid;
  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isUserLoggedStatus) {
      // Assuming you have a way to get the service provider ID, replace 'YOUR_SERVICE_PROVIDER_ID' with the actual logic to retrieve it

      const ratingnreviewdata=new FormData()
      ratingnreviewdata.append("user_id", localStorage.getItem("user_id"));
      ratingnreviewdata.append("service_provider_id", serviceId);
      ratingnreviewdata.append("total_rating", rating);
      ratingnreviewdata.append("review", comment);


      // Dispatch the action with the rating and review data
      dispatch(giveratingnreview(ratingnreviewdata));

      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  };

  useEffect(() => {
    dispatch(getVendorBanner());
  }, []);
  const vendorbanner = useSelector((state) => state.home.VenBanner);
  const vendorbannerpath = useSelector((state) => state.home.VenBannerPath);
  console.log("VENDORBANNER", vendorbanner);
  console.log("VENDORBANNERPATH", vendorbannerpath);


  // useEffect(() => {
  //   dispatch(getVendorProfileImage(phone));
  // }, [dispatch, phone]);
  // const profileimg = useSelector((state) => state.home.ProfileImage);
  // const profileimgpath = useSelector((state) => state.home.ProfileImagePath);
  // console.log("VendorProfileImage", profileimg);
  // console.log("VendorProfileImagePATH", profileimgpath);
  //{vendorbannerpath + vendorbanner}

  useEffect(() => {
    dispatch(getVendorGallery(phone));
  }, [dispatch, phone]);

  const vendorGalleryPath = useSelector((state) => state.home.VeGalleryPath);
  const vendorGallery = useSelector((state) => state.home.VendorGallery);
  console.log("acvvv", vendorGallery.file_name);

  const [loaderStatus, setLoaderStatus] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoaderStatus(false);
    }, 2000); 
  }, []);


  const settingsss = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    autoplay: true,
    autoplaySpeed: 2000,
  };

const service_providerId = serviceId;

  useEffect(() => {
    const formDataRating=new FormData()
    formDataRating.append("service_provider_id",service_providerId)
    dispatch(FetchRating(formDataRating));
  },[dispatch, service_providerId]);
  
  const fetchRatingDataa  = useSelector((state) => state.home.fetchrating);
  console.log('fetch_ratinggg', fetchRatingDataa);
  


  const totalRating = parseFloat(fetchRatingDataa.total_rating) || 0; 


  const renderStars = (totalRating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      const starClassName = i <= totalRating ? "active gold-star" : "";
      stars.push(
        <span key={i} className={starClassName}>
          ★
        </span>
      );
    }
    return stars;
  };

  const fetchProfileImages = useMemo(
    () => async () => {
      try {
        const formData = new FormData();
        const phone = localStorage.getItem("formDataVendorPhone")
        formData.append('phone', phone);
        const response = await nbbApi.post('/vendor_profile_image_fetch', formData);
        const data = response.data;
        if (data.status === 1) {
          setProfileImagePathPro(data.image_path);

          setProfileImageContDataPro(data.result[0].image);
        }
      } catch (error) {
        console.error('API request failed:', error);
      }
    },
    []
  );
  console.log("lonk concat", `${ProfileImagePathPro}${ProfileImageContDataPro}` )
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchProfileImages();
      } catch (error) {
        console.error('API request failed:', error);
      }
    };
    fetchData();
  }, [fetchProfileImages]);

  return (
    <>
      {loaderStatus ? (
        <div className="loader-container">
          <PulseLoader loading={loaderStatus} size={50} color="#36d7b7" />
        </div>
      ) : (
        <>
          <div style={{ position: "relative", height: "400px" }}>
            <Slider {...carouselSettings}>
              <img
                src={vendorbannerpath + vendorbanner}
                alt="Image 1"
                style={{ height: "100%", width: "100%" }}
              />
            </Slider>
            {/* <div
          style={{
            position: "absolute",
            top: "50%",
            left: '6%',
            transform: "translateY(-50%)", 
            width: "35%", 
            padding: "20px", 
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            color: "#fff", 
            background: "rgb(157 157 157 / 50%)", 
            borderRadius: '35px',
          }}
        >
    <h4>Wayindia Software Solution</h4>
    <p><i class="fa fa-user" style={{"font-size":"16px", color: '#484848'}}></i> &nbsp;Pradeep Kumar Singh</p>
    <p><i class="fa fa-bolt" style={{"font-size":"16px", color: '#484848'}}></i> &nbsp;Barber</p>
    <p><i class="fa fa-map-marker" style={{"font-size":"16px", color: '#484848'}}></i> &nbsp;Jaydev Vihar</p>
  </div> */}
            <img
    src={`${ProfileImagePathPro}${ProfileImageContDataPro}`}
    alt="Profile Image"
    style={{
      position: "absolute",
      top: "50%",
      right: "6%",
      transform: "translateY(-50%)", 
      height: "200px", 
      width: "200px", 
      borderRadius: "50%",
      border: "2px solid #fff", 
    }}
  />
          </div>
          <div className="section-full p-t80 p-b80 bg-gray section6" id="services">
            {/* <div className="container"> */}
            {/* TITLE START*/}

            <div className="section-head1 text-center">
              <h2>Welcome to Vendor Gallery!</h2>
              <p>Explore our amazing vendors and their products.</p>
            </div>
            <div className="container">
              <div
                className="gallery-container"
                id="gallery-container"
                style={{ textAlign: "center" }}
              >
                {vendorGallery.length > 0 ? (
                  <LightGallery
                    speed={200}
                    plugins={[
                      lgThumbnail,
                      lgZoom,
                      lgShare,
                      lgRotate,
                      lgVideo,
                      lgAutoplay,
                    ]}
                  >
                    {vendorGallery.map((image) => (
                      <a
                        key={image.id}
                        data-lg-size="1400-1400"
                        className="gallery-item"
                        data-src={vendorGalleryPath + image.file_name}
                        data-sub-html="<h4>Heading Here</h4>"
                      >
                        <img
                          key={image.id}
                          // className="img-fluid"
                          src={vendorGalleryPath + image.file_name}
                          alt=""
                          style={{ width: "auto", height: "100%" }}
                        />
                      </a>
                    ))}
                  </LightGallery>
                ) : (
                  <div
                    className="col-md-12"
                    style={{ textAlign: "center", margin: "auto" }}
                  >
                    <img
                      src={galleryimg}
                      alt="galleryimg"
                      style={{ height: "200px" }}
                    />
                    <p>Images not found</p>
                  </div>
                )}
              </div>

       
            </div>
          </div>


          {/*-------------------------- REVIEW SECTION START-------------------------- */}
          <div className="section-full p-t80 p-b80 bg-gray section8">
            <div className="section-head1 text-center">
              <h2>Rate and Comment</h2>
              <p>Please provide your valuable reviews below!</p>
            </div>
              <div className="container">
                <div className="row col-md-12">
                  <div className="col-md-6">
                    <img src={ratingimg} alt="ratingimg" />
                  </div>
                  <div className="col-md-6">
                  <form onSubmit={handleSubmit}>
                <div className="rating-container">
                  <h2>Rate and Comment</h2>

                  <div className="rating">
                    <label>Rating:</label>
                    <div>
                      {[1, 2, 3, 4, 5].map((value) => (
                        <span
                          key={value}
                          className={value <= rating ? "active" : ""}
                          onClick={() => handleRatingChange(value)}
                        >
                          ★
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className="comment">
                    <label>Comment:</label>
                    <textarea
                      rows="4"
                      cols="50"
                      value={comment}
                      onChange={handleCommentChange}
                      placeholder="Enter your comment..."
                    ></textarea>
                  </div>

                  {isUserLoggedStatus ? (
                    <button className="ratingbutton" type="submit">Submit</button>
                  ) : (
                    <div>
                      <p style={{color: "red"}}>Please log in to submit a rating and comment.</p>
                    </div>
                  )}

                  {showMessage && <div style={{color: "green", marginTop: '4%', textAlign: "center", fontWeight: "600"}}>Thank You for Submitting your valuable rating.</div>}
                </div>
              </form>
                  </div>
                </div>
              </div>
            </div>
          {/*-------------------------- REVIEW SECTION END -------------------------- */}




          <div className="section-full p-t80 p-b80 bg-gray section6" id="services">
            <div className="section-head1 text-center">
              <h2>Reviews</h2>
              <h6>Our most valuable reviews</h6>
              <div className="site-section bg-light">
                <div className="container">
                  <div className=" mb-5 ">
                  <Slider {...settingsss} style={{ margin: "5px" }}>
                      {fetchRatingDataa.map((feedback) => (
                        <div className=" col-sm-6 mb-3 mb-sm-0" style={{ margin: "5px" }}>
                        <div key={feedback.id} className="card p-3" style={{ margin: "10px" }}>
                          <div className="" style={{ display: "flex" }}>
                            <img src={usericon} alt="usericon" style={{ height: "40px" }}/>
                            &nbsp;
                            <span>
                              <h5 className="card-title m-2">{feedback.cust_name}</h5>
                            </span>
                          </div>

                          <p className="card-text">{renderStars(parseFloat(feedback.total_rating) || 0)}</p>
                          <p className="card-text">{feedback.review}</p>
                        </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>

              {/* ))} */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VendorGallery;
