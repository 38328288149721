import React, { useState, useEffect, useRef } from "react";
// import ContactSVG from '../Components/assets/img/contact.svg';
import { Fade, Slide, Bounce, Zoom } from "react-awesome-reveal";
import BeatLoader from "react-spinners/BeatLoader";
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
// import phone from "../Components/assets/img/phone-icon.png";
// import email from "../Components/assets/img/email-icon.png";
import facebook from "../Components/assets/img/Facebook.png";
import twitter from "../Components/assets/img/Twitter.png";
import instagram from "../Components/assets/img/Instagram.png";
import PulseLoader from "react-spinners/PulseLoader";
import { saveContact } from "../store/home";
import { Helmet } from 'react-helmet';
import SiteLogo from "../Components/assets/img/logo.png";

const Contact = () => {
    const dispatch = useDispatch();

    // useEffect(() => {
    //   dispatch(getContact());
    // }, []);
     const st = useSelector(state => state.home);
    // const contact = useSelector(state => state.home.Contact);


    const [loaderStatus, setLoaderStatus] = useState(true);

    useEffect(() => {
      setTimeout(() => {
        setLoaderStatus(false);
      }, 2000); // Set loaderStatus to false after 3 seconds (adjust as needed)
    }, []);

  //state variable for success msg
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Add a ref to the form element
  const formRef = useRef();

  // Handle form submission start
  const { register, handleSubmit, reset, formState: { errors } } = useForm();


  const onSubmit = (data) => {
   dispatch(saveContact(data));

    // Reset the form after successful submission
    reset();

    // Show the success message
    setShowSuccessMessage(true);

    // Reset the success message after 5 seconds (10000 milliseconds)
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 20000);

  };

  // Handle form submission end
  const success_msg = st?.contact_submit;



  const contactMessage = () => {

    if (st?.contact_submit) {
      return <span className='register-msg-style'>{st?.contact_submit}</span>
    }
  }




  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };



  const siteUrl = window.location.href;

  return (
    <>
      {/* {st.loaderStatus ? ( */}
      {loaderStatus ? (
        <div className="loader-container">
          {/* <BeatLoader loading={st.loaderStatus} size={50} color="#36d7b7" /> */}
          <PulseLoader loading={loaderStatus} size={50} color="#36d7b7" />
        </div>
      ) : (
        <div>
        
          <section className="android ptb30" style={{ overflow: "hidden" }}>
            <div className="content-banner">
              <div className="container">
                <h3
                  style={{
                    width: "100%",
                    textAlign: "left",
                    color: "white",
                    textTransform: "inherit",
                    fontSize: "20px",
                    paddingTop: "3%",
                  }}
                >
                  Contact
                </h3>
              </div>
            </div>
          </section>
          {/* <!-- end --> */}
          {/* <!-- corporate --> */}


          <>
          <div className="section-wrapper">
              <div className="container">
                <div className="row mt-5 justify-content-center">
                  <div className="col-md-6 address">
                    <h3 className="head3">We would love to hear from you!</h3>
                    <p className="pb-3">
                      Please write or call us with your questions or comments.
                    </p>
                    <h5 className="mb-0">Contact</h5>
                    <Helmet>
                          <meta charSet="utf-8" />
                          <title>"New Bharat Biz - Get Expert Professional Services near by you."</title>
                          <meta name="description" content="New Bharat Biz: Connecting Small Entrepreneurs, Get connected to business owners around you and build a Biz network, business listing, New Bharat Biz mobile app help you grow." />
                          <meta property="og:type" content="website" />
                          <meta property="og:url" content={siteUrl} />
                          <meta property="og:title" content="Connecting 40,000+ Small and Micro Service Providers" />
                          <meta property="og:description" content="New Bharat Biz: Connecting Small Entrepreneurs, Get connected to business owners around you and build a Biz network, business listing, New Bharat Biz mobile app help you grow." />
                          <meta property="og:image" content={SiteLogo} />
                        </Helmet>
                    <div className="contact-row">
                      <div className="contact-list">
                        <div className="icon1">
                          <img
                            src="https://readindia.info/ric/public/document/phone-icon.png"
                            style={{ height: 38 }}
                          />
                        </div>
                        <a className="contactcolor" href="tel:+91 8688 120 864" >+91 674 2567899</a>
                      </div>
                      <div className="contact-list">
                        <div className="icon1">
                          <img
                            src="https://readindia.info/ric/public/document/email-icon.png"
                            style={{ height: 22, width: 28 }}
                          />
                        </div>
                        <a className="contactcolor" href="mailto:info@newbharatbiz.in">
                          info@newbharatbiz.in
                        </a>
                      </div>
                    </div>
                    <div className="social-group mt-4">
                      <h5>Keep In Touch</h5>
                      <ul className="list-unstyled socials">
                        <li style={{padding: '10px'}}>
                          <a
                            classname="facebook"
                            href="https://www.facebook.com/wayindia"
                            target="_blank"
                          >
                            <img
                              src={facebook}
                              style={{ height: 36 }}
                            />{" "}
                          </a>
                        </li>
                        <li style={{padding: '10px'}}>
                          <a
                            href="https://twitter.com/wayindia"
                            target="_blank"
                          >
                            <img
                              src={twitter}
                              style={{ height: 36 }}
                            />{" "}
                          </a>
                        </li>
                        <li style={{padding: '10px'}}>
                          <a
                            href="https://www.instagram.com/wayindiasoftware/"
                            target="_blank"
                          >
                            <img
                              src={instagram}
                              style={{ height: 36 }}
                            />{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-12">
                  <div className="contact_form">
                    {showSuccessMessage && (
                      <span class="register-msg-style" style={{ 'color': '#306013', 'fontSize': '20px', 'fontWeight': '500' }}>
                        {contactMessage()}
                      </span>
                    )}
                    <form ref={formRef} onSubmit={handleSubmit(onSubmit)} className="row pt-4">
                      <div className="form-group col-md-6">
                        <label>Your Name <span className="red-text">*</span></label>
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Enter Full Name"
                          {...register('name', { required: true })}
                        />
                        {errors.fullName && <span className="error text-danger" style={{ 'fontSize': '13px' }}>Please enter your full name</span>}
                      </div>
                      <div className="form-group col-md-6">
                        <label>Email <span className="red-text ">*</span></label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter your email"
                          {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                        />
                        {errors.email && <span className="error text-danger" style={{ 'fontSize': '13px' }}>Please enter a valid email address</span>}
                      </div>
                      <div className="form-group col-md-6">
                        <label>Phone <span className="red-text">*</span></label>
                        <input
                          type="tel"
                          name="phone"
                          className="form-control"
                          placeholder="Phone number"
                          {...register('phone', { required: true, pattern: /^[0-9]{10}$/i })}
                        />
                        {errors.phone && <span className="error text-danger" style={{ 'fontSize': '13px' }}>Please enter a 10-digit phone number</span>}
                      </div>
                      <div className="form-group col-md-6">
                        <label>Subject <span className="red-text">*</span></label>
                        <input
                          type="text"
                          name="subject"
                          className="form-control"
                          placeholder="Subject"
                          {...register('subject', { required: true })}
                        />
                        {errors.subject && <span className="error text-danger" style={{ 'fontSize': '13px' }}>Please enter the subject</span>}
                      </div>
                      <div className="form-group col-md-12">
                        <label>Write Query <span className="red-text">*</span></label>
                        <textarea
                          rows="3"
                          cols="3"
                          className="form-control"
                          placeholder="Write your query.."
                          {...register('query', { required: true })}
                        ></textarea>
                        {errors.query && <span className="error text-danger" style={{ 'fontSize': '13px' }}>Please write your query</span>}
                      </div>
                      <div className="form-group mt-2">
                        <button type="submit" onClick={handleScrollToTop} className="btn btn-success btn-rounded btn-lg">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
                </div>
              </div>
            </div>

          </>

        </div>
      )}
    </>
  );
}

export default Contact