import React, { useState, useEffect } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { findServicebydistance } from "../store/home"; // Make sure this path is correct
import Swal from "sweetalert2";
import { nbbApi } from "../store/axios";
import NOSERVICEimage from "../Components/assets/img/NoData.png";
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SiteLogo from "../Components/assets/img/logo.png";



const SearchService = () => {
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const isUserLoggedStatus = useSelector((state) => state.auth.isUserLoggedIn);
  const servicesPerPage = 9;
  const servicename = localStorage.getItem("selected_service_name");
  const dispatch = useDispatch();

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchDataFromSessionStorage = async () => {
      const formDataString = sessionStorage.getItem("formData");
      if (formDataString) {
        const formDataObject = JSON.parse(formDataString);
        const formData = new FormData();
        for (const key in formDataObject) {
          formData.append(key, formDataObject[key]);
        }

        try {
          // Make the API call
          await dispatch(findServicebydistance(formData));
          // Set loaderStatus after the API call
          setLoaderStatus(false);
        } catch (error) {
          console.error("API call failed:", error);
          setLoaderStatus(false); // Handle the error and update the loaderStatus accordingly
        }
      }
    };

    // Call the function
    fetchDataFromSessionStorage();
  }, [dispatch]);

  const findservicebylocation = useSelector(
    (state) => state.home.findServiceByDistance
  );
  const findservicebydist = findservicebylocation.service || [];

  const indexOfLastService = currentPage * servicesPerPage;
  const indexOfFirstService = indexOfLastService - servicesPerPage;
  const currentServices = findservicebydist.slice(
    indexOfFirstService,
    indexOfLastService
  );
  const renderRatingStars = (rating) => {
    const totalStars = 5;
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = totalStars - fullStars - (halfStar ? 1 : 0);

    const stars = [];

    for (let i = 0; i < fullStars; i++) {
      stars.push(<i key={i} className="fa fa-star" style={{ color: "#FFD700" }} />);
    }

    if (halfStar) {
      stars.push(<i key="half" className="fa fa-star-half" style={{ color: "#FFD700" }} />);
    }

    for (let i = 0; i < emptyStars; i++) {
      stars.push(<i key={`empty-${i}`} className="fa fa-star-o" style={{ color: "#FFD700" }} />);
    }

    return stars;
  };
  const handlePoke = async (serviceId, serviceName) => {

  try {
      if (isUserLoggedStatus) {
        const user_id = localStorage.getItem("user_id");
        const srvc_provider_id = serviceId;
        const address = " ";
        const phone = localStorage.getItem("formDataUserPhone");

        const formData = new FormData();
        formData.append("user_id", user_id);
        formData.append("srvc_provider_id", srvc_provider_id);
        formData.append("address", address);
        formData.append("phone", phone);

        const response = await nbbApi.post("/user_add_poke", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data.status === 0) {
          // Display a toast with the message from the response
          // The poke was successful, show a success message
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: `${response.data.message}${" "}${serviceName}`,
          });
        } else {
          // The poke was successful, show a success message
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: `${response.data.message}${" "} ${serviceName}`,
          });
        }
      } else {
        // Display a toast indicating that the user is not logged in
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "You need to be logged in to poke",
        });
      }
    } catch (error) {
      console.error("Poke add request failed:", error);
    }
  };


  const handleAddFavorite = async (serviceId, servicename) => {
    try{
      if (isUserLoggedStatus) {
        const user_id = localStorage.getItem("user_id");
        const srvc_provider_id = serviceId;

        const formData = new FormData();
        formData.append("user_id", user_id);
        formData.append("service_provider_id", srvc_provider_id);

        await nbbApi.post("/add_to_favourite", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        // Add a success notification
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: `You added ${servicename} to favorite`,
        });
      } else {
        // Display a toast indicating that the user is not logged in
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "You need to be logged in to add to favorites",
        });
      }
    } catch (error) {
    console.error("Favourite add request failed:", error);
  }
};
const navigate=useNavigate();

  const renderServices = () => {
    if (currentServices.length > 0) {
      return currentServices.map((service) => (
        <div className="card-group col-md-4 mb-3 mb-sm-0" key={service.id}>
          <div className="card panel panel-info" >
           <div className="card-body">
            <div className="card-title panel-heading">
              <i className="fa fa-user" style={{margin: '5px'}}/> {service.businessName}
            </div>
            <div className="card-text">

              {isUserLoggedStatus ? (
                <>
                  <p style={{ marginBottom: 8 }}>
                    <i className="fa fa-map-marker" /> {service.address}
                  </p>
                  <p>Phone: {service.phone}</p>
                </>
              ) : (
                <p>
                  Please{" "}
                  <span
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => (navigate("/search_service/user_login"))}
                  >
                    log in
                  </span>{" "}
                  to view Contact and Address
                </p>
              )}
              <p style={{ marginBottom: 0 }}>
                <i className="fa fa-bolt" /> {service.sname}
              </p>
              <p style={{ marginBottom: 0 }}>
                <i className="fa fa-road" />{" "}
                {parseFloat(service.distance).toFixed(2)} K.M.
              </p>
              <p>Total Ratings: {renderRatingStars(service.total_rating)}</p>

              {/* <div className="container"> */}
                <div className="row">
                  <div className="col-sm-12 mb-3 mb-sm-0" style={{ display: "flex" }}>
                  <div class="">
                    <button
                      className="btn  btn-info btn-xs"
                      style={{ marginBottom: "5px " }}
                      onClick={() => handlePoke(service.id, service.businessName)}
                    >
                      POKE
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-info btn-xs"
                      style={{ marginBottom: "5px " }}
                      onClick={() => navigate("/search_service/vendor_gallery", { state: { phone: service.phone, srvcid: service.id } })}
                    >
                      View More
                    </button>

                    &nbsp;

                    <button
                      className="btn  btn-info btn-xs"
                      style={{ marginBottom: "5px " }}
                      onClick={() => handleAddFavorite(service.id, service.businessName )}
                    >
                      Favorite
                    </button>
                  </div>
                  </div>
                </div>
              {/* </div> */}
            </div>
          </div>
          </div>
        </div>
      ));
    } else {
      return (
        <div className="col-md-12">
          <div className="no-services-message" style={{ textAlign: 'center' }}>
            <p>Sorry, no services found for this category.</p>

            <img src={NOSERVICEimage} alt="No Services Icon" />
          </div>
        </div>
      );

    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const siteUrl = window.location.href;

  
  return (
    <>
      {loaderStatus ? (
        <div className="loader-container">
          <PulseLoader loading={loaderStatus} size={50} color="#36d7b7" />
        </div>
      ) : (
        <div className="section-full p-t80 p-b80 bg-gray">
          <div className="container">
            <div
              className="section-head"
              style={{ marginTop: "3rem", marginBottom: 35 }}
            >
              <h4>
                Search result for:{" "}
                <span style={{ color: "#c71d1d" }}>{servicename}</span>
              </h4>
              <Helmet>
                          <meta charSet="utf-8" />
                          <title>"New Bharat Biz - Get Expert Professional Services near by you."</title>
                          <meta name="description" content="New Bharat Biz: Connecting Small Entrepreneurs, Get connected to business owners around you and build a Biz network, business listing, New Bharat Biz mobile app help you grow." />
                          <meta property="og:type" content="website" />
                          <meta property="og:url" content={siteUrl} />
                          <meta property="og:title" content="Connecting 40,000+ Small and Micro Service Providers" />
                          <meta property="og:description" content="New Bharat Biz: Connecting Small Entrepreneurs, Get connected to business owners around you and build a Biz network, business listing, New Bharat Biz mobile app help you grow." />
                          <meta property="og:image" content={SiteLogo} />
                        </Helmet>
            </div>
            <div
              className="section-head"
              style={{ marginTop: "3rem", marginBottom: 35 }}
            >
              <Link to="/">
                <p
                  style={{
                    textDecoration: "underline",
                    color: "blue",
                    cursor: "pointer",
                  }}
                >
                  Back to home
                </p>
              </Link>
            </div>
              <div className="row" style={{rowGap: '10px'}}>{renderServices()}</div>
            {/* {findservicebydist.length === 0 && (
              <div className="col-md-12">
                <p>Sorry, no services found for this category.</p>
              </div>
            )} */}
            <ul className="pagination mt-4">
              {Array(Math.ceil(findservicebydist.length / servicesPerPage))
                .fill()
                .map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      index + 1 === currentPage ? "active" : ""
                    }`}
                  >
                    <button
                      onClick={() => {
                        paginate(index + 1);
                        handleScrollToTop();
                      }}
                      className="page-link"
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchService;