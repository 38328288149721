import { configureStore } from "@reduxjs/toolkit";
import homeReducer from './home';
import authReducer from './auth';

const store = configureStore({
    reducer: {
        home: homeReducer,
        auth: authReducer,
    }
})



export default store;